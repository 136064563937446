export class Visit {
    id: string;
    summary: string;
    date: Date;

    constructor(id: string, summary: string, date: Date) {
        this.id = id;
        this.summary = summary;
        this.date = new Date(date);
    }
}

export const SortVisitsByDate = (data: Visit[]): Visit[] => {
    const notes = data.map(cd => (
        new Visit(cd.id, cd.summary, cd.date)
    ))

    return notes.sort((c1, c2) => {
        return c1.date.getTime() - c2.date.getTime();
    })

}
