import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { first, take } from 'rxjs/operators';
import { Report } from 'src/app/api/generated/graphql';
import { ReportService } from 'src/app/services/report/report.service';


@Component({
  selector: 'app-case-get-started',
  templateUrl: './case-get-started.component.html',
  styleUrls: ['./case-get-started.component.css']
})
export class CaseGetStartedComponent implements OnInit {
  beginCaseForm: FormGroup;
  formIsValid: boolean = false;
  error: any;
  casereport$: Observable<Report> | null = null;
  reportID: string | null = '';
  selectedIndex: number = 0;

  constructor(
    private reportService: ReportService,
    private router: Router,
    private translate: TranslateService,
  ) {
    this.beginCaseForm = new FormGroup({});
   }

  ngOnInit() {
    this.beginCaseForm = new FormGroup({
      title: new FormControl("", [Validators.required, Validators.minLength(2)]),
      keywords: new FormControl("", [Validators.required, Validators.minLength(2)]),
    }, { updateOn: 'submit' })
    
    const obs = this.translate.get('MISC.CASE REPORT KEYWORD');
    obs.pipe(take(1)).subscribe((res: string) => {
      this.beginCaseForm.get('keywords').setValue(res+',');
    })
    
  }

  save(title: string, keywords: string): any {
    const obs = this.reportService.createNewCase(title, keywords);
    obs.pipe(first()).subscribe({
      next: (data) => {
        if (data && data.id) {
          this.reportID = data.id;
        }
      },
      error: (err: any) => {
        console.error(err);
        this.error = err;
      }
    })
  }

  onSubmit(form: any, stepper: any) {
    form.markAllAsTouched();

    const valid = form.status;

    if (valid === "VALID") {
      const title = form.value.title;
      const keywords = form.value.keywords;
      this.save(title, keywords);
      stepper.next();
    }
  }

  moveOn() {
    this.router.navigate(['/reports', this.reportID])
  }

  get title() { return this.beginCaseForm.get('title'); }

  get keywords() { return this.beginCaseForm.get('keywords'); }

}