<ng-container *ngIf="loading; else formView">
  <mat-progress-spinner mode="indeterminate" diameter="20"></mat-progress-spinner>
</ng-container>

<ng-template #formView>
  <div [formGroup]="form">
    <div class="input-container">
      <label class="mat-body">{{'MISC.UNIT' | translate}}</label>
      <input type="text" (blur)="validateCurrentUnit(form.controls.unitValue.value)" class="diagnostic" aria-label="Unit" formControlName="unitValue" [matAutocomplete]="autoU">
      <mat-autocomplete #autoU="matAutocomplete" (optionSelected)="checkFields($event)">
        <mat-option *ngFor="let u of filteredUnits | async" [value]="u.name">
          {{u.name}}
        </mat-option>
      </mat-autocomplete>
      <ng-container *ngIf="form.controls.unitValue?.invalid && (form.controls.unitValue?.dirty || form.controls.unitValue?.touched)">
        <div class="input-error" *ngIf="form.controls.unitValue.errors.required">{{'FORMS.SELECT UNIT' | translate}}</div>
      </ng-container>
    </div>
  </div>
</ng-template>