import { Injectable } from '@angular/core';
import { ProfileService } from './profile/profile.service';
import { Subject } from 'rxjs';
import { first } from 'rxjs/operators';
import { UpdateUserProfileGQL, UserProfileDocument } from '../api/generated/graphql';
import { AuthService } from '@auth0/auth0-angular';
import { ModalService } from './modal.service';

@Injectable({
  providedIn: 'root'
})
export class AutoSetLanguageService {

  error: any;
  unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private updateUserGQL: UpdateUserProfileGQL,
    private profileService: ProfileService,
    private auth: AuthService,
    private modalService: ModalService,
  ) { }

  setLanguagePreference(lang: string, returnTo: string) {
    let preference = lang || "en";
    const obs = this.profileService.getProfile();
    this.modalService.openUpdateLanguageDialog();
    obs.pipe(first()).subscribe(u => {
      const first = u.givenName
      const last = u.familyName
      const drip = u.usermeta.addedToDrip
      const profileComplete = u.usermeta.profileCompleted

      this.updateUserGQL
        .mutate({
          userID: u.id,
          givenName: first,
          familyName: last,
          name: first + " " + last,
          lang: preference,
          completedProfile: profileComplete,
          addedToDrip: drip,
        }, {
          refetchQueries: [{ query: UserProfileDocument }]
        })
        .subscribe({
          error: (err: any) => {
            this.error = err;
            console.error(err);
          },
          complete: () => this.auth.loginWithRedirect({ appState: { target: returnTo } })
        }
        );

    })

  }

}