<button appDefaultGrey mat-icon-button aria-hidden="false" aria-label="Delete visit" [matMenuTriggerFor]="menuVisit" matTooltip="{{'ACTIONS.DELETE' | translate}}">
  <mat-icon class="icon" appDefaultGrey>delete</mat-icon>
</button>

<mat-menu #menuVisit="matMenu">
  <button mat-menu-item (click)="delete()">
    <mat-icon appDefaultGrey aria-hidden="false" aria-label="Delete visit">
      delete
    </mat-icon>
    <span>{{'ACTIONS.DELETE' | translate}}</span>
  </button>
  <button mat-menu-item>
    <mat-icon appDefaultGrey aria-hidden="false" aria-label="Cancel">cancel</mat-icon>
    <span>{{'ACTIONS.CANCEL' | translate}}</span>
  </button>
</mat-menu>