import { Component, OnInit, Input } from '@angular/core';
import { AirtableService } from '../../airtable.service';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-menu-subsections',
  templateUrl: './menu-subsections.component.html',
  styleUrls: ['./menu-subsections.component.css'],
  animations: [
    trigger('simpleFadeAnimation', [

      state('in', style({ opacity: 1 })),

      transition(':enter', [
        style({ opacity: 0 }),
        animate(500)
      ]),

      transition(':leave',
        animate(300, style({ opacity: 0, height: 0 })))
    ])
  ]
})
export class MenuSubsectionsComponent implements OnInit {

  @Input() ids: string[];

  elementData = [{title: "content1", summary: "some summary 1"}, {title: "content2", summary: "some summary 2"}, {title: "content3", summary: "some summary 3"}]
  data = [];
  elements: HTMLElement
  root: HTMLElement
  error: any;
  
  constructor(
    private airtable: AirtableService
  ) { }

  ngOnInit(): void {
    if (this.ids) {
      for (let i = 0; i < this.ids.length; i++) {
        const subID = this.ids[i];
        this.airtable.getSubsectionByID(subID).pipe(first()).subscribe({
          next: (data) => {
            this.data.push(data)
            return data
          },
          error: err => {
            this.error = err;
            console.error(err);
          },
        })
      }
    }
    
  }


}
