import { Component, OnInit, Input } from '@angular/core';
import { AllAttachmentsGQL, UpdateAttachmentOrderGQL } from 'src/app/api/generated/graphql';
import { tap, filter, map, catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-list-attachments',
  templateUrl: './list-attachments.component.html',
  styleUrls: ['./list-attachments.component.css']
})
export class ListAttachmentsComponent implements OnInit {

  @Input() reportID: string = '';
  @Input() isMobile: boolean = false;
  
  attachments$: Observable<any> = new Observable<any>();
  loading: boolean = false;
  error: any;

  constructor(
    private allAttachmentsGQL: AllAttachmentsGQL,
    private updateAttachmentOrderGQL: UpdateAttachmentOrderGQL,
  ) {}

  ngOnInit() {
    if (this.reportID) {
      this.fetchAttachments(this.reportID);
    }
  }

  fetchAttachments(id: string) {
    this.attachments$ = this.allAttachmentsGQL.watch({ id: id })
      .valueChanges
      .pipe(
        tap(result => this.loading = result.loading),
        filter(result => !result.loading),
        map(result => (<any>result.data).report.attachments),
        catchError((err: any) => {
          this.loading = false;
          this.error = err;
          console.error(err);
          return of(null);
        }),
      );
  }

  trackByFn(index: any, item: { id: any; }) {
    return item.id;
  }

  drop(event: CdkDragDrop<string[]>) {
    const attachmentID = event.item.data.id
    let data = [...event.container.data];
    moveItemInArray(data, event.previousIndex, event.currentIndex);
    this.updateAttachmentOrder(attachmentID, event.currentIndex, event.previousIndex);
  }

  updateAttachmentOrder(id: any, newOrder: number, previousOrder: number) {
    this.updateAttachmentOrderGQL
      .mutate({
        attachmentId: id,
        reportId: this.reportID,
        order: newOrder,
        previousOrder: previousOrder
      })
      .subscribe({
        error: err => {
          this.error = err;
          console.error(err);
        }
      });
  }

}