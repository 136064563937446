import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class SnackService {

  constructor(
    private translate: TranslateService,
    private _snackBar: MatSnackBar,
  ) { }

  generateSnack(name: string): void {
    this.translate.get([name, 'SNACKS.DISMISS']).subscribe((res: string) => {
      this._snackBar.open(res[name], res['SNACKS.DISMISS'], {
        duration: 2000,
      });

    })
  }
}
