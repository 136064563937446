import { Injectable, Input } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import {MyStarredReportsGQL, DeleteStarGQL, CreateStarGQL, IsStarredGQL, StarredReportOwnerGQL, MyStarredReportsDocument} from 'src/app/api/generated/graphql';

@Injectable({
  providedIn: 'root'
})
export class MysavedreportsService {
  error: any;
  @Input() owner: any;
  @Input()
  reportID: string = '';
  private lastUpdate$: BehaviorSubject<string>;


  constructor(
    private mystarred: MyStarredReportsGQL,
    private deleteStar: DeleteStarGQL,
    private createStar: CreateStarGQL,
    private isStarred: IsStarredGQL,
    private starOwner: StarredReportOwnerGQL
  ) {
    this.lastUpdate$ = new BehaviorSubject('');
  }

  /**
   * getMyStarredReports
   */
  public getMyStarredReports(): Observable<any> {
    return this.mystarred.watch()
      .valueChanges
  }

  /**
   * createNewStar
   */
  public createNewStar(reportID: string): Observable<any> {
    return this.createStar.mutate({
      reportID: reportID
    })
  }

  /**
   * removeStar deletes an existing star
   */
  public removeStar(reportID: string): Observable<any> {
    return this.deleteStar.mutate({ reportID: reportID }, {
      refetchQueries: [{ query: MyStarredReportsDocument }]
    })
  }

  /**
   * isStarred checks for an existing star and returns boolean
   */
  public isReportStarred(reportID: string): Observable<any> {
    return this.isStarred.fetch({
      reportID: reportID
    })
  }

  public getStarOwner(reportID: string): Observable<any> {
    return this.starOwner.fetch({
      reportID: reportID
    })
  }
}
