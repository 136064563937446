import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from 'src/environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatBadgeModule } from '@angular/material/badge';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSortModule } from '@angular/material/sort';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatChipsModule } from '@angular/material/chips';
import { MatRadioModule } from '@angular/material/radio';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { UserToolbarComponent } from './components/user-toolbar/user-toolbar.component';
import { MainNavListComponent } from './navigation/main-nav-list/main-nav-list.component';
import { LangPreferenceComponent } from './components/lang-preference/lang-preference.component';
import { AutoSetLangComponent } from './components/lang-preference/auto-set-lang/auto-set-lang.component';
import { LoginButtonComponent } from './auth/login-button/login-button.component';
import { LoginComponent } from './auth/login/login.component';
import { AutoLogoutComponent } from './auth/auto-logout/auto-logout.component';
import { UserProfileComponent } from './views/user-profile/user-profile.component';
import { PageNotFoundComponent } from './views/page-not-found/page-not-found.component';
import { CaseItemComponent } from './components/case-item/case-item.component';
import { CaseComponent } from './views/case/case.component';
import { CasePreviewComponent } from './views/case-preview/case-preview.component';
import { LayoutSidenavContainerComponent } from './components/layout/layout-sidenav-container/layout-sidenav-container.component';
import { QuillEditorComponent } from './components/quill-editor/quill-editor.component';
import { GraphQLModule } from './graphql.module';
import { DeleteDialogComponent } from './dialogs/delete-dialog/delete-dialog.component';
import { CaseGetStartedComponent } from './views/case-get-started/case-get-started.component';
import { EditTitleComponent } from './components/edit-title/edit-title.component';
import { EditKeywordsComponent } from './components/edit-keywords/edit-keywords.component';
import { PublishedCasesComponent } from './components/published-cases/published-cases.component';
import { ErrorComponent } from './components/error/error.component';
import { QuillReadOnlyComponent } from './components/quill-read-only/quill-read-only.component';
import { DeleteCaseReportComponent } from './components/delete-case-report/delete-case-report.component';
import { ContentPreviewComponent } from './components/content-preview/content-preview.component';
import { CallbackComponent } from './auth/callback/callback.component';
import { ProfileCardComponent } from './user/profile-card/profile-card.component';
import { UpdateprofileComponent } from './user/updateprofile/updateprofile.component';
import { VisitsViewComponent } from './views/visits/visits.component';
import { DiagnosticsViewComponent } from './views/diagnostics/diagnostics.component';
import { DiagnosticsComponent } from './components/diagnostics/diagnostics.component';
import { NewDiagnosticComponent } from './components/diagnostics/new/new-diagnostic.component';
import { NewVisitComponent } from './components/visits/new/new-visit.component';
import { DeleteVisitComponent } from './components/visits/delete/delete-visit.component';
import { EditVisitComponent } from './components/visits/edit/edit-visit.component';
import { SingleValueComponent } from './components/diagnostics/single-value/single-value.component';
import { DoubleValueComponent } from './components/diagnostics/double-value/double-value.component';
import { UnitInputComponent } from './components/diagnostics/unit-input/unit-input.component';
import { DeleteDiagnosticComponent } from './components/diagnostics/delete/delete-diagnostic.component';
import { ViewDiagnosticsComponent } from './components/diagnostics/view-diagnostics/view-diagnostics.component';
import { DefaultValueComponent } from './components/diagnostics/default-value/default-value.component';
import { CaseNavigationComponent } from './components/case-navigation/case-navigation.component';
import { PDFDialogComponent } from './dialogs/pdfgen-dialog/pdfgen-dialog.component';
import { ProfileDialogComponent } from './dialogs/profile-dialog/profile-dialog.component';
import { UpdateLanguageDialog } from './dialogs/update-language-dialog/update-language-dialog.component';
import { ShareToggleComponent } from './components/share-toggle/share-toggle.component';
import { ListAttachmentsComponent } from './components/attachments/list/list-attachments.component';
import { PreviewAttachmentComponent } from './components/attachments/preview/preview-attachment.component';
import { NewAttachmentComponent } from './components/attachments/new/new-attachment.component';
import { GridListAttachmentsComponent } from './components/attachments/grid-list-attachments/grid-list-attachments.component';
import { DeleteAttachmentComponent } from './components/attachments/delete/delete-attachment.component';
import { EditAttachmentComponent } from './components/attachments/edit-attachment/edit-attachment.component';
import { VisitsPreviewComponent } from './components/visits/visits-preview/visits-preview.component';
import { TimelineComponent } from './components/visits/timeline/timeline.component';
import { MysavedreportsComponent } from './components/mysavedreports/mysavedreports.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { ShareComponent } from './views/share/share.component';
import { DeleteAuthorComponent } from './components/authors/delete/delete-author.component';
import { CorrespondingAuthorComponent } from './components/authors/corresponding-author/corresponding-author.component';
import { PreviewAffiliationsComponent } from './components/affiliations/preview-affiliations/preview-affiliations.component';
import { EditAffiliationComponent } from './components/affiliations/edit/edit-affiliation.component';
import { NewAffiliationComponent } from './components/affiliations/new/new-affiliation.component';
import { DeleteAffiliationComponent } from './components/affiliations/delete/delete-affiliation.component';
import { AddUserToGroupComponent } from './components/add-user-to-group/add-user-to-group.component';
import { InvitationComponent } from './views/invitation/invitation.component';
import { SavedCasesComponent } from './views/saved-cases/saved-cases.component';
import { NewCaseComponent } from './components/new-case/new-case.component';
import { LayoutSidenavCaseComponent } from './components/layout-sidenav-case/layout-sidenav-case.component';
import { CaseHeaderComponent } from './components/case-header/case-header.component';
import { QuillWrapperComponent } from './components/quill-wrapper/quill-wrapper.component';
import { FullPreviewComponent } from './components/full-preview/full-preview.component';
import { GroupMembersComponent } from './components/group-members/group-members.component';
import { ContentPreviewLightComponent } from './components/content-preview-light/content-preview-light.component';
import { HelpMenuComponent } from './components/help-menu/help-menu.component';
import { MenuExampleComponent } from './components/menu-example/menu-example.component';
import { MenuSubsectionsComponent } from './components/menu-subsections/menu-subsections.component';
import { GroupMembersReadOnlyComponent } from './components/group-members-read-only/group-members-read-only.component';
import { DocumentGroupSettingsComponent } from './components/document-group-settings/document-group-settings.component';
import { MessageComponent } from './components/message/message.component';
import { AttachmentsWrapperComponent } from './components/attachments/attachments-wrapper/attachments-wrapper.component';
import { AttachmentsColumnLayoutComponent } from './components/attachments/attachments-column-layout/attachments-column-layout.component';
import { HeaderLessTabsDirective } from './directives/header-less-tabs.directive';
import { RedHoverDirective } from './directives/red-hover.directive';
import { UserAffiliationsComponent } from './components/user-affiliations/user-affiliations.component';
import { ReportAuthorAffiliationsComponent } from './components/report-author-affiliations/report-author-affiliations.component';
import { UpdateMyAffiliationsComponent } from './dialogs/update-my-affiliations/update-my-affiliations.component';
import { MessageInfoComponent } from './components/message-info/message-info.component';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { BtnWhiteDirective } from './directives/btn-white.directive';
import { AccentHoverDirective } from './directives/accent-hover.directive';
import { CaseBottomNavComponent } from './navigation/case-bottom-nav/case-bottom-nav.component';
import { ShareBottomSheetComponent } from './sheets/share-bottom-sheet/share-bottom-sheet.component';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { PdfBottomSheetComponent } from './sheets/pdf-bottom-sheet/pdf-bottom-sheet.component';
import { DeleteBottomSheetComponent } from './sheets/delete-bottom-sheet/delete-bottom-sheet.component';
import { DefaultGreyDirective } from './directives/default-grey.directive';
import { UpdateVisitComponent } from './dialogs/update-visit/update-visit.component';
import { TrackUpdatedPipe } from './pipes/track-updated.pipe';
import { DataOverwriteWarningComponent } from './dialogs/data-overwrite-warning/data-overwrite-warning.component';
import { AffiliationsExpansionComponent } from './components/affiliations-expansion/affiliations-expansion.component'
import { NotificationsComponent } from './components/notifications/notifications.component';
import { TranslatorComponent } from './components/translator/translator.component';
import { RequestTranslationComponent } from './dialogs/request-translation/request-translation.component';
import { SignUpComponent } from './auth/sign-up/sign-up.component';
import { QuillShareComponent } from './components/quill-share/quill-share.component';
import { ErrorDialogComponent } from './dialogs/error-dialog/error-dialog.component';
import { QuillDiffComponent } from './components/quill-diff/quill-diff.component';
import { QuillRevisionsComponent } from './components/quill-revisions/quill-revisions.component';
import { VersionHistoryComponent } from './components/version-history/version-history.component';
import { MessageWideComponent } from './components/message-wide/message-wide.component';
import { AddTimelineEntryComponent } from './dialogs/add-timeline-entry/add-timeline-entry.component';
import { AddDiagnosticsEntryComponent } from './dialogs/add-diagnostics-entry/add-diagnostics-entry.component';
import { TimelinePlaceholderComponent } from './components/visits/timeline-placeholder/timeline-placeholder.component';
import { DiagnosticsPlaceholderComponent } from './components/diagnostics/diagnostics-placeholder/diagnostics-placeholder.component';
import { SaveRevisionDialogComponent } from './dialogs/save-revision-dialog/save-revision-dialog.component';
import { SettingsComponent } from './components/settings/settings.component';
import { QuillTranslateComponent } from './components/quill-translate/quill-translate.component';
import { MessageDialogComponent } from './dialogs/message-dialog/message-dialog.component';

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    UserToolbarComponent,
    MainNavListComponent,
    LangPreferenceComponent,
    AutoSetLangComponent,
    LoginButtonComponent,
    LoginComponent,
    AutoLogoutComponent,
    UserProfileComponent,
    PageNotFoundComponent,
    CaseItemComponent,
    CaseComponent,
    CasePreviewComponent,
    LayoutSidenavContainerComponent,
    QuillEditorComponent,
    DeleteDialogComponent,
    PDFDialogComponent,
    UpdateLanguageDialog,
    CaseGetStartedComponent,
    EditTitleComponent,
    EditKeywordsComponent,
    PublishedCasesComponent,
    ErrorComponent,
    QuillReadOnlyComponent,
    DeleteCaseReportComponent,
    ContentPreviewComponent,
    CallbackComponent,
    ProfileCardComponent,
    UpdateprofileComponent,
    VisitsViewComponent,
    DiagnosticsViewComponent,
    DiagnosticsComponent,
    NewVisitComponent,
    DeleteVisitComponent,
    EditVisitComponent,
    NewDiagnosticComponent,
    SingleValueComponent,
    DoubleValueComponent,
    UnitInputComponent,
    DeleteDiagnosticComponent,
    ViewDiagnosticsComponent,
    DefaultValueComponent,
    CaseNavigationComponent,
    AffiliationsExpansionComponent,
    ShareToggleComponent,
    ListAttachmentsComponent,
    PreviewAttachmentComponent,
    NewAttachmentComponent,
    GridListAttachmentsComponent,
    DeleteAttachmentComponent,
    EditAttachmentComponent,
    VisitsPreviewComponent,
    TimelineComponent,
    MysavedreportsComponent,
    ShareComponent,
    DeleteAuthorComponent,
    CorrespondingAuthorComponent,
    PreviewAffiliationsComponent,
    EditAffiliationComponent,
    NewAffiliationComponent,
    DeleteAffiliationComponent,
    AddUserToGroupComponent,
    InvitationComponent,
    SavedCasesComponent,
    NewCaseComponent,
    LayoutSidenavCaseComponent,
    CaseHeaderComponent,
    QuillWrapperComponent,
    FullPreviewComponent,
    GroupMembersComponent,
    ContentPreviewLightComponent,
    HelpMenuComponent,
    MenuExampleComponent,
    MenuSubsectionsComponent,
    GroupMembersReadOnlyComponent,
    DocumentGroupSettingsComponent,
    MessageComponent,
    AttachmentsWrapperComponent,
    AttachmentsColumnLayoutComponent,
    HeaderLessTabsDirective,
    RedHoverDirective,
    DefaultGreyDirective,
    ClickOutsideDirective,
    UserAffiliationsComponent,
    ReportAuthorAffiliationsComponent,
    UpdateMyAffiliationsComponent,
    MessageInfoComponent,
    BtnWhiteDirective,
    AccentHoverDirective,
    CaseBottomNavComponent,
    ShareBottomSheetComponent,
    PdfBottomSheetComponent,
    DeleteBottomSheetComponent,
    UpdateVisitComponent,
    ProfileDialogComponent,
    TrackUpdatedPipe,
    DataOverwriteWarningComponent,
    NotificationsComponent,
    TranslatorComponent,
    RequestTranslationComponent,
    SignUpComponent,
    QuillShareComponent,
    ErrorDialogComponent,
    VersionHistoryComponent,
    QuillDiffComponent,
    QuillRevisionsComponent,
    MessageWideComponent,
    AddTimelineEntryComponent,
    AddDiagnosticsEntryComponent,
    TimelinePlaceholderComponent,
    DiagnosticsPlaceholderComponent,
    SaveRevisionDialogComponent,
    SettingsComponent,
    QuillTranslateComponent,
    MessageDialogComponent,
  ],
  imports: [
    GraphQLModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatStepperModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatExpansionModule,
    MatTabsModule,
    MatBadgeModule,
    MatTableModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSortModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatDialogModule,
    MatSnackBarModule,
    MatSlideToggleModule,
    DragDropModule,
    MatGridListModule,
    MatChipsModule,
    MatRadioModule,
    MatAutocompleteModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (HttpLoaderFactory),
        deps: [HttpClient]
      },
    }),
    AuthModule.forRoot({
      domain: 'auth.care-writer.com',
      clientId: '9A0kbgYOT2ii7Fm4cYuPfGmpjMWpuHTZ',
      redirectUri: window.location.origin + "/callback",
      useRefreshTokens: false,
      cacheLocation: 'localstorage',
      scope: 'openid profile email',
      audience: 'https://api.carewriter.com',
      responseType: 'token id_token',
      httpInterceptor: {
        allowedList: [
          environment.graphQLuri
        ]
      }
    }),
    MatButtonModule,
    MatCardModule,
    MatBottomSheetModule,
    MatProgressBarModule,
    MatToolbarModule,
    MatFormFieldModule,
    MatInputModule,
    MatExpansionModule,
    MatTabsModule,
    MatTableModule,
    MatTooltipModule,
    MatIconModule,
    MatSidenavModule,
    MatListModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSortModule,
    MatCheckboxModule,
    MatDialogModule,
    MatSnackBarModule,
    MatSlideToggleModule,
    DragDropModule,
    MatGridListModule,
    MatChipsModule,
    MatRadioModule,
    MatAutocompleteModule,
    MatSidenavModule,
    MatProgressSpinnerModule,
    ClipboardModule,

  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },],
  bootstrap: [AppComponent]
})
export class AppModule { }
