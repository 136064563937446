import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { first, take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ModalService } from 'src/app/services/modal.service';
declare let gtag: Function;

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent implements OnInit {
  editMode: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private modalService: ModalService,
    public dialog: MatDialog,
    private location: Location,
    private translate: TranslateService,
    private _snackBar: MatSnackBar,
  ) {
  }

  ngOnInit(): void {
    this.route.queryParams.pipe(take(1)).subscribe(params => {
      const isSignup = params["isSignup"]
      if (isSignup) {
        this.modalService.openConfirmProfile('/dashboard');
      }
    })
  }

  goBack() {
    this.location.back();
  }

  onSuccess() {
    this.editMode = false;

    this.translate.get(['SNACKS.PROFILE UPDATED', 'SNACKS.DISMISS']).pipe(first()).subscribe((res: any) => {

      const message = res['SNACKS.PROFILE UPDATED'];
      const dismiss = res['SNACKS.DISMISS'];

      this._snackBar.open(message, dismiss, {
        duration: 3000,
      });

    })
  }

  sendEvent() {
    gtag('event', 'password-change', {
      'event_category': 'auth',
      'event_label': 'click'
    });
  }


}
