import { Component, Inject, OnInit } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';
import { Report } from 'src/app/api/generated/graphql';
import { ReportService } from 'src/app/services/report/report.service';

@Component({
  selector: 'app-delete-bottom-sheet',
  templateUrl: './delete-bottom-sheet.component.html',
  styleUrls: ['./delete-bottom-sheet.component.css']
})
export class DeleteBottomSheetComponent implements OnInit {
  error: any;
  report: Report | null = null;

  constructor(
    private translate: TranslateService,
    private reportService: ReportService,
    private _snackBar: MatSnackBar,
    private router: Router,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: { report: Report },
    private _bottomSheet: MatBottomSheetRef,
  ) {
    this.report = data.report;
  }

  ngOnInit(): void {
  }

  confirmDelete() {
    const obs = this.reportService.deleteCaseReport(this.report.id);

    obs.pipe(first()).subscribe({
      error: (err: any) => {
        console.error(err);
        this.error = err;
      },
      complete: () => this.onSuccess()
    })
  }

  onSuccess() {
    this.translate.get(['SNACKS.REPORT DELETED', 'SNACKS.DISMISS']).pipe(first()).subscribe((res: any) => {
      this._snackBar.open(`${this.report.title} ${res['SNACKS.REPORT DELETED']}`, res['SNACKS.DISMISS'], {
        duration: 3000,
      });
    });
    
    this.router.navigate(['/dashboard'])
    this.close();
  }

  close() {
    this._bottomSheet.dismiss();
  }

}
