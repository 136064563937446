<ng-container *ngIf="reports; else loading">
  <div class="grid col-4">
    <ng-container *ngIf="reports.length">
      <div class="column" hover-class="mat-elevation-z8" *ngFor="let r of reports">
        <mat-card class="element mat-elevation-z1" [routerLink]="['/reports', r.id]">
          <div class="card-inner">
            <div class="card-inner-scale">
              <h1 class="mat-title title">{{r.title}}</h1>
              <app-group-members-read-only [reportID]="r.id"></app-group-members-read-only>
              <app-content-preview-light [report]="r"></app-content-preview-light>
            </div>
          </div>
          <div class="btn-container">
            <button mat-icon-button>
              <mat-icon fontSet="material-icons-outlined">folder_shared</mat-icon>
            </button>
            <div class="meta">
              <span *ngIf="r.title" class="mat-small">{{(r.title.length>30)? (r.title | slice:0:25)+'...':(r.title) }}</span>
              <span *ngIf="!r.title" class="mat-small">Id: {{r.id | slice:0:7}}</span>
              <span class="mat-small">{{'MESSAGES.UPDATED' | translate}}: {{r.updatedAt | date}}</span>
            </div>
          </div>
        </mat-card>
      </div>
    </ng-container>
  </div>
</ng-container>
<ng-template #loading>
  <div class="centered-spinner">
    <mat-spinner diameter="20"></mat-spinner>
  </div>
</ng-template>