import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-diagnostics',
  templateUrl: './diagnostics.component.html',
  styleUrls: ['./diagnostics.component.css']
})

export class DiagnosticsComponent implements OnInit {
  @Input() reportID: string = '';
  
  constructor() {}

  ngOnInit() {
  }
  
}