export class Type {
  id: string;
  name: string;
  subtypes: [];
  defaultValues: [];

  constructor(id: string, name: string, subtypes: [], defaultValues: []) {
    this.id = id;
    this.name = name;
    this.subtypes = subtypes;
    this.defaultValues = defaultValues;
  }

}

export const sortTypesAlphabetically = (data: Type[]): Type[] => {

  let sortable = [...data];

  return sortable.sort((a, b) => {
    return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
  })
}
