import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { Report } from 'src/app/api/generated/graphql';
import { ReportService } from 'src/app/services/report/report.service';

@Component({
  selector: 'app-visits-view',
  templateUrl: './visits.component.html',
  styleUrls: ['./visits.component.css']
})
export class VisitsViewComponent implements OnInit {
  casereport$: Observable<Report> | null = null;

  constructor(
    private route: ActivatedRoute,
    private caseReportService: ReportService,
  ) { }

  ngOnInit(): void {
    if (this.route.parent) {
      this.route.parent.paramMap
        .pipe(first())
        .subscribe(m => {
          const id = m.get("id")
          if (id) {
            this.casereport$ = this.caseReportService.getReport(id);
          }
        })
    }
  }

}
