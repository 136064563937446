import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DiagnosticsService } from 'src/app/services/diagnostics.service';
import { ModalService } from 'src/app/services/modal.service';
import { GroupForTableDisplay } from '../../../classes/diagnostic';

@Component({
  selector: 'app-diagnostics-placeholder',
  templateUrl: './diagnostics-placeholder.component.html',
  styleUrls: ['./diagnostics-placeholder.component.css']
})
export class DiagnosticsPlaceholderComponent implements OnInit {

  displayedColumns: string[] = ['type', 'value', 'unit'];
  dataSource$ = new BehaviorSubject(null);
  currentIndex: number = 0;
  @Input() reportID: string = '';
  @Input() isPreview: boolean = true;
  loading: boolean = true;
  unsubscribe$: Subject<void>;
  hasEntries: boolean = false;

  constructor(
    private diagnosticsService: DiagnosticsService,
    private modalService: ModalService,
  ) {
    this.unsubscribe$ = new Subject<void>();
  }

  ngOnInit() {
    if (this.reportID) {
      this.diagnosticsService.getDiagnostics(this.reportID).pipe(takeUntil(this.unsubscribe$)).subscribe({
        next: (data) => {
          const sortedItems = GroupForTableDisplay(data);
          if (data.length) {
            this.hasEntries = true;
            this.dataSource$.next(sortedItems)
          } else {
            this.dataSource$.next(null)
          }
        }
      })
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  isDateHeader(index, item): boolean {
    return item.isDateHeader;
  }

  openEntryModal(name: string): void {
    if (!name) return;

    if (name === "timeline") {
      this.modalService.openNewTimeline(this.reportID);
      return;
    } else if (name === "diagnostic") {
      this.modalService.openNewDiagnostic(this.reportID);
      return;
    }
  }

}
