<ng-container *ngIf="report">
  <h2 class="mat-title title">{{report.title}}</h2>
  <ng-container *ngIf="translation && !translation?.deletedAt">
    <h3 class="mat-subheading-2 title translated-text"><i>{{translation.title}}</i></h3>
  </ng-container>
  <app-group-members-read-only [reportID]="report.id"></app-group-members-read-only>
  <app-affiliations-expansion [reportID]="report.id"></app-affiliations-expansion>

  <app-content-preview-light [report]="report"></app-content-preview-light>
  <div class="corr-author">
    <strong class="mat-body">{{'CASE SECTION.CORRESPONDING AUTHOR' | translate}}: </strong>
    <span *ngIf="report.corrAuthor.user.id; else userTmp" class="mat-body">{{report.corrAuthor.user.givenName}} {{report.corrAuthor.user.familyName}}, {{report.corrAuthor.email}}</span>
    <ng-template #userTmp>
      <span class="mat-body">{{report.corrAuthor.firstName}} {{report.corrAuthor.lastName}}, {{report.corrAuthor.email}}</span>
    </ng-template>
  </div>
</ng-container>