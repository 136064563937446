import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';
import { GetTypesAndUnitsGQL } from 'src/app/api/generated/graphql';

declare let gtag: Function;

@Injectable({
  providedIn: 'root'
})
export class UnitsAndTypesService {
  
  constructor(
    private getUnitsAndTypesGQL: GetTypesAndUnitsGQL
  ) { }

  getUnitsAndTypes():any {
    return this.getUnitsAndTypesGQL.fetch()
      .pipe(
        filter((result: any) => !result.loading),
        map( (result: any) => (<any>result.data)),
        catchError((err: any) => {
          console.error(err);
          this.sendEvent(err);
          return of(null)
        })
      )
  }

  sendEvent(err: string): void {
    gtag('event', 'exception', {
      'exDescription': 'GetUnitsAndTypesService::' + err,
      'exFatal': false  // set to true if the error is fatal
    });
  }
}
