<ng-container *ngIf="authors$ | async as authors">

  <div class="row">
    <span class="mat-title no-select">{{'CASE SECTION.AUTHORS' | translate}}</span>
    <button mat-button (click)="showAddInput = !showAddInput"><mat-icon>add</mat-icon>{{'ACTIONS.ADD' | translate}}</button>
  </div>
  <mat-divider *ngIf="showAddInput"></mat-divider>
  <ng-container *ngIf="showAddInput">
    <app-add-user-to-group [reportID]="reportID"></app-add-user-to-group>
  </ng-container>


  <mat-list cdkDropList (cdkDropListDropped)="drop($event)" [cdkDropListData]="authors">
    <mat-list-item class="item" *ngFor="let c of authors" [cdkDragData]="c" cdkDrag>
      <div class="placeholder" *cdkDragPlaceholder></div>
      <span class="no-select">
        <mat-icon *ngIf="authors?.length > 1" cdkDragHandle class="drag-indicator">drag_indicator</mat-icon>
        <span *ngIf="c.user.id; else userTmp" class="mat-body">&nbsp;{{c.user.givenName}} {{c.user.familyName}}, {{c.user.email}}</span>
        <ng-template #userTmp>
          <span class="mat-body">&nbsp;{{c.firstName}} {{c.lastName}}, {{c.email}}</span>
        </ng-template>
      </span>

      <div class="delete" *ngIf="ownerEmail | async">
        <app-delete-author *ngIf="c.email != (ownerEmail | async)" [author]="c" [reportID]="reportID"></app-delete-author>
      </div>
    </mat-list-item>
  </mat-list>


</ng-container>