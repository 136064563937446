<app-message-info *ngIf="showMessage" content="{{'INFO.DIAGNOSTICS' | translate}}"></app-message-info>

<div class="checkbox">
  <mat-checkbox class="mat-small" matTooltip="{{'MISC.REPEAT DATE TOOLTIP' | translate}}" [(ngModel)]="batchCreate">
    {{'MISC.REPEAT DATE' | translate}}
  </mat-checkbox>
  <div class="notice">
    <a class="care-link mat-small" href='https://docs.google.com/forms/d/e/1FAIpQLScH4OKpZSaJiGEM9KBe90an2V3pQA_180aZGeLDg1vKp9oeEw/viewform?usp=sf_link' target='_blank'>{{'MESSAGES.SUBMIT DIAGNOSTIC' | translate}}</a>
  </div>
</div>

<form [formGroup]="dForm" (ngSubmit)="onSubmit(dForm, $event)" novalidate>
  <div class="row">
    <div class="input-container">
      <label class="mat-body">{{'MISC.DATE' | translate}}&nbsp;(mm/dd/yyyy)</label>
      <input id="date-input" class="diagnostic" #dateInput name="dateValue" formControlName="dateValue"
        [matDatepicker]="picker">
      <div class="datepick">
        <mat-datepicker-toggle disableRipple matSuffix [for]="picker">
          <mat-icon class="picker-icon" matDatepickerToggleIcon>
            calendar_month
          </mat-icon>
        </mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </div>
      <ng-container *ngIf="dateValue?.invalid && (dateValue?.dirty || dateValue?.touched)">
        <div class="input-error" *ngIf="dateValue?.errors.required">{{'FORMS.VALID DATE' | translate}}</div>
      </ng-container>
    </div>
    <div class="input-container">
      <label class="mat-body">{{'MISC.TYPE' | translate}} ({{'FORMS.SELECT TYPE 2' | translate}})</label>
      <input id="type-input" (blur)="validateCurrentInput(typeValue?.value)" name="typeValue" class="diagnostic"
        type="text" aria-label="Type" formControlName="typeValue" [matAutocomplete]="auto">
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="checkFields($event)">
        <mat-option class="diagnostic-option" *ngFor="let t of filteredTypes | async" [value]="t.name">
          {{t.name}}
        </mat-option>
      </mat-autocomplete>
      <ng-container *ngIf="typeValue?.invalid && (typeValue?.dirty || typeValue?.touched)">
        <div class="input-error" *ngIf="typeValue.errors.required">{{'FORMS.SELECT TYPE' | translate}}</div>
      </ng-container>
      <div
        *ngIf="(typeValue?.dirty || typeValue?.touched) && (isTypeValid$ | async) === false && !typeValue?.errors"
        class="input-error">{{'FORMS.SELECT OPTION' | translate}}
      </div>
    </div>
    <div class="input-container">
      <ng-container formArrayName="formArray" *ngIf="currentFormConfig === formConfiguration[0]">
        <app-single-value [form]="dForm.controls.formArray.controls[0]"></app-single-value>
      </ng-container>
    </div>
    
    <div formArrayName="formArray" *ngIf="currentFormConfig === formConfiguration[1]">
      <app-double-value [form]="dForm.controls.formArray.controls[0]"
        [labels]="[singleValPlaceholder, alternateValPlaceholder]"></app-double-value>
    </div>
    
    <div formArrayName="formArray" *ngIf="currentFormConfig === formConfiguration[2]">
      <app-default-value [form]="dForm.controls.formArray.controls[0]" [labels]="[defaultVal1, defaultVal2]">
      </app-default-value>
    </div>
  </div>

  <button [disabled]="!dForm.valid" class="btn-submit" type="submit" mat-stroked-button [class.mat-flat-button]="dForm.valid" [color]="dForm.valid ? 'accent' : null">
    <mat-icon>check_circle_outline</mat-icon>&nbsp;<span
      class="mat-body">{{'ACTIONS.SAVE' | translate}}</span>
  </button>

</form>