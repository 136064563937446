import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appDefaultGrey]'
})
export class DefaultGreyDirective {

  constructor(private el: ElementRef) {
    this.el.nativeElement.style.color = 'rgba(0, 0, 0, 0.54)';
    this.el.nativeElement.style.fontWeight = '400';
  }

}
