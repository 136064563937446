import { Component, Inject } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-add-timeline-entry',
  templateUrl: './add-timeline-entry.component.html',
  styleUrls: ['./add-timeline-entry.component.css']
})
export class AddTimelineEntryComponent {

  reportId: string = '';

  constructor(
    public dialogRef: MatDialogRef<AddTimelineEntryComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.reportId = data.reportId;
  }

  closeDialog(): void {
    this.dialog.closeAll()
  }

}
