import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { AllAttachmentsGQL, AllPublicAttachmentsGQL } from '../api/generated/graphql';

@Injectable({
  providedIn: 'root'
})
export class AttachmentsService {

  constructor(
    private attachmentsGQL: AllAttachmentsGQL,
    private attachmentsPublicGQL: AllPublicAttachmentsGQL,
  ) { }

  public getAttachments(reportID: string): Observable<any[]> {
    return this.attachmentsGQL.fetch({ id: reportID })
      .pipe(
        map(result => result.data.report.attachments),
        catchError((err: any) => {
          console.error(err);
          return of(null);
        }),
      );
  }

  public getPublicAttachments(reportID: string): Observable<any[]> {
    return this.attachmentsPublicGQL.fetch({ id: reportID })
      .pipe(
        map(result => result.data.publicReport.attachments),
        catchError((err: any) => {
          console.error(err);
          return of(null);
        }),
      );
  }
}
