<div class="container" *ngIf="(isAuthenticated$ | async)">
  <ng-container *ngIf="showWarning; else invite">
    <div class="container">
      <app-message-info class="warning" icon="warning" content="Sorry, this invitation is meant for another email address than the one you are using. Please confirm the invitation was sent to the same email address you use to access CARE-writer."></app-message-info>
      <button mat-stroked-button [routerLink]="['/dashboard']">Dashboard</button>
    </div>
  </ng-container>
  <ng-template #invite>
    <ng-container *ngIf="invite$ | async as invite">
      <ng-container *ngIf="invite.status === 'pending'; else notPending">
        <ng-container *ngIf="user$ | async as user">
          <p class="mat-subheading-2">Hello {{user.name}}! You've been invited to co-author a case report on CARE-writer!</p>
          <p>Click "Accept Invitation" below to start working on: <br /><br />{{invite.report.title}}</p>
          
          <div class="btn-row">
            <button mat-flat-button color="primary" (click)="respondToInvite(invite.report.id, user.email, 'accept')">Accept Invitation</button>&nbsp;
            <button mat-flat-button (click)="respondToInvite(invite.report.id, user.email, 'decline')">Decline</button>
          </div>
        </ng-container>
      </ng-container>
      <ng-template #notPending>
        <ng-container *ngIf="invite.status === 'accepted'; else declined">
          <app-message-info icon="check_circle" iconColor="green" content="This invitation was previously accepted."></app-message-info>
          <span class="mat-title">{{invite.report.title}}</span>
          <button mat-stroked-button [routerLink]="['/reports', invite.report.id]">Take me to the case&nbsp;<mat-icon>forward</mat-icon></button>
        </ng-container>
        <ng-template #declined>
          <p class="mat-body">This invitation was declined.</p>
          <button mat-stroked-button routerLink="/dashboard">My Dashboard</button>
        </ng-template>
      </ng-template>
    </ng-container>
  </ng-template>
</div>

<div *ngIf="showMessage">
  <div class="container">
    <app-message-info content="You must be logged-in to view this case report"></app-message-info>
    <br />
    <p>Redirecting in... {{timeToRedirect}}</p>
  </div>
</div>

<ng-container *ngIf="loading">
  <div class="centered-spinner">
    <mat-spinner diameter="20"></mat-spinner>
  </div>
</ng-container>

<ng-container *ngIf="error">
  <div class="centered-spinner">
    <app-error [error]="error"></app-error>
  </div>
</ng-container>