import { Component, Input, ViewChild, ElementRef, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-preview-attachment',
  templateUrl: './preview-attachment.component.html',
  styleUrls: ['./preview-attachment.component.css']
})
export class PreviewAttachmentComponent implements AfterViewInit {

  @Input()
  file!: File;
  @ViewChild('previewImg')
  previewImg!: ElementRef;

  showPdfPreview: boolean = false;
  showImagePreview: boolean = false;

  pdfSrc!: string | ArrayBuffer | null;
  imageToUpload!: File;

  constructor() { }

  ngAfterViewInit() {
    if (this.file) {
      if (this.file.type === "application/pdf") {
        this.renderPdfPreview(this.file);
      } else {
        this.renderImagePreview(this.file);
      }
    }
  }

  renderPdfPreview(file: File) {
    const fileReader = new FileReader();

    fileReader.onload = (e) => {
      this.showPdfPreview = true;
      if (e && e.target) {
        this.pdfSrc = e.target['result'];
      }
    }, false;

    if (file) {
      fileReader.readAsDataURL(file);
    }
  }

  renderImagePreview(file: File) {
    this.showImagePreview = true;
    const fileReader = new FileReader();
    const preview = this.previewImg;

    fileReader.onload = (e) => {
      // convert image file to base64 string
      preview.nativeElement.src = fileReader.result;
    }, false;

    if (file) {
      fileReader.readAsDataURL(file);
      this.imageToUpload = file;
    }
  }

}
