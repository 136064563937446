import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { first, take } from 'rxjs/operators';
import { ReportService } from 'src/app/services/report/report.service';

interface DialogData {
  title: string
  message: string
  reportID: string
}

@Component({
  selector: 'delete-dialog',
  templateUrl: 'delete-dialog.component.html',
})
export class DeleteDialogComponent {
  error: any;
  constructor(
    private translate: TranslateService,
    private _snackBar: MatSnackBar,
    private router: Router,
    private reportService: ReportService,
    public dialogRef: MatDialogRef<DeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  confirmDelete() {

    const obs = this.reportService.deleteCaseReport(this.data.reportID);

    obs.pipe(take(1)).subscribe({
      error: (err: any) => {
        console.error(err);
        this.error = err;
      },
      complete: () => this.onSuccess()
    })
    this.dialogRef.close();
  }

  onSuccess() {
    this.translate.get(['SNACKS.REPORT DELETED', 'SNACKS.DISMISS']).pipe(first()).subscribe((res: any) => {
      this._snackBar.open(`${this.data.title} ${res['SNACKS.REPORT DELETED']}`, res['SNACKS.DISMISS'], {
        duration: 3000,
      });
    });
    
    this.router.navigate(['/dashboard'])
  }

}