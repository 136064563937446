import { Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { Report } from 'src/app/api/generated/graphql';
import { takeUntil } from 'rxjs/operators';
import { TranslatorService } from 'src/app/services/translator.service';

@Component({
  selector: 'app-full-preview',
  templateUrl: './full-preview.component.html',
  styleUrls: ['./full-preview.component.css']
})
export class FullPreviewComponent implements OnInit {
  @Input() report: Report | null = null;
  translation: any;
  unsubscribe$: Subject<void> = new Subject();

  constructor(
    private translatorService: TranslatorService
  ) { }

  ngOnInit(): void {
    if (this.report) {
      this.translatorService.getTranslation(this.report.id).pipe(takeUntil(this.unsubscribe$)).subscribe({
        next: (data) => {
          if (data.id) {
            this.translation = data;
          }
        }
      })
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
