<ng-container *ngIf="(authors$ | async) as authors">
  <div class="flex-row" [ngClass]="{'pad': nonEditable}">
    <span *ngFor="let author of authors; last as isLast">
      <span *ngIf="author.user.id; else userTmp" class="mat-small">
        {{ author.user.givenName }} {{author.user.familyName}}<span *ngIf="!isLast">,&nbsp;</span>
      </span>
      <ng-template #userTmp>
        <span class="mat-small">
          {{ author.firstName }} {{author.lastName}}<span *ngIf="!isLast">,&nbsp;</span>
        </span>
      </ng-template>
    </span>
  </div>
</ng-container>