<div class="focusing-container" [class.mobile]="isMobile">
  <span class="focusing-span">{{'MISC.'+(status$ | async) | translate}}</span>
</div>
<textarea
  (blur)="onBlur()"
  (focus)="onFocus()"
  [formControl]="descriptionControl"
  type="text"
  placeholder="{{'FORMS.ENTER DESCRIPTION' | translate}}"
  class="mat-body description">{{ content }}</textarea>
  
  
  <ng-container
    *ngIf="descriptionControl?.invalid && (descriptionControl?.dirty || descriptionControl?.touched); else valid">
    <div class="mat-small" style="color: red;">{{descriptionControl.value.length}}/500<span class="input-error" *ngIf="descriptionControl?.hasError('maxlength')">&nbsp;{{'FORMS.MAX LENGTH' | translate}}</span></div>
   
  </ng-container>
  <ng-template #valid>
    <ng-container *ngIf="descriptionControl?.value">
      <div class="mat-small">{{descriptionControl.value.length}}/500</div>
    </ng-container>
  </ng-template>
  