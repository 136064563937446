import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-single-value',
  templateUrl: './single-value.component.html',
  styleUrls: ['./single-value.component.css']
})
export class SingleValueComponent implements OnInit {
  @Input() form!: FormGroup;
  
  loading: boolean = true;
  error: any;
  
  constructor() { }

  ngOnInit(): void {}

}
