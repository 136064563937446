<ng-container *ngIf="(isAuthenticated$ | async)">
  <app-user-toolbar [isAuthenticated$]="isAuthenticated$" [isHandset$]="isHandset$">
    <button class="snav-toggle" appBtnWhite mat-icon-button (click)="snav.toggle()">
      <mat-icon>menu</mat-icon>
    </button>
  </app-user-toolbar>
  
  <mat-sidenav-container>
    <mat-sidenav #snav [mode]="!(isHandset$ | async) ? 'side' : 'over'" [opened]="!(isHandset$ | async)">
      <app-main-nav-list></app-main-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
      <div class="app-width">
        <mat-toolbar *ngIf="showToolbar">
          <mat-toolbar-row>
            <h1>{{viewTitle$ | async}}</h1>
          </mat-toolbar-row>
        </mat-toolbar>
        <ng-container *ngIf="backToLocation === 'goBack'">
          <div class="btn-nav-container">
            <a class="no-decoration" (click)="goBack()">
              <mat-icon inline="true">arrow_back</mat-icon>&nbsp;Back
            </a>
          </div>
        </ng-container>
        <div class="container">
          <router-outlet></router-outlet>
        </div>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</ng-container>

<div *ngIf="showMessage">
  <div class="container-msg">
    <app-message-info content="You must be logged-in to view this page."></app-message-info>
    <br />
    <p>Redirecting in... {{timeToRedirect}}</p>
  </div>
</div>