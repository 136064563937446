<form class="form-group" [formGroup]="graphicForm" (ngSubmit)="onSubmit(graphicForm, $event)" autocomplete="off">
  <div class="input-container">
    <label class="mat-body">{{'SUBHEADINGS.DESCRIPTION' | translate}}</label>
    <textarea #descriptionText type="text" formControlName="description" placeholder="{{'FORMS.ENTER DESCRIPTION' | translate}}"></textarea>
    <ng-container *ngIf="description?.invalid && (description?.dirty || description?.touched); else valid">
      <div class="mat-small" style="color: red;">{{descriptionText?.value.length}}/500<span *ngIf="description.hasError('maxlength')"class="mat-small" style="color: red;">&nbsp;{{'FORMS.MAX LENGTH' | translate}}</span></div>
     
    </ng-container>
    <ng-template #valid>
      <div class="mat-small">{{descriptionText?.value.length}}/500</div>
    </ng-template>
  </div>
  
  <div class="btn-row">
    <button type="submit" mat-flat-button color="accent">{{'ACTIONS.UPLOAD' | translate}}</button>&nbsp;
    <button type="button" mat-button color="warn" (click)="cancelUpload()">{{'ACTIONS.CANCEL' | translate}}</button>
  </div>

</form>