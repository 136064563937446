<ng-container *ngIf="!(activateMediumLayout$ | async); else altLayout">
  <div class="secondary-nav">
    <mat-nav-list>
      <a mat-list-item disableRipple routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" [routerLink]="['/reports', reportID]"><mat-icon>assignment</mat-icon>&nbsp;{{'MISC.WRITE' | translate}}</a>
  
      <a mat-list-item disableRipple routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" [routerLink]="['/reports', reportID, 'diagnostics']"><mat-icon class="material-icons-outlined">science</mat-icon>&nbsp;{{'CASE SECTION.DIAGNOSTICS' | translate}}</a>
  
      <a mat-list-item disableRipple routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" [routerLink]="['/reports', reportID, 'visits']"><mat-icon>timeline</mat-icon>&nbsp;{{'CASE SECTION.TIMELINE' | translate}}</a>
      
      <a mat-list-item disableRipple routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
      [routerLink]="['/reports', reportID, 'preview']">
      <mat-icon>preview</mat-icon>&nbsp;{{'ACTIONS.PREVIEW' | translate}}
      </a>
    </mat-nav-list>
  </div>
</ng-container>
<ng-template #altLayout>
  <div class="alt-layout">
    <mat-nav-list class="list-horizontal">
      <a mat-list-item disableRipple routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
        [routerLink]="['/reports', reportID]">
        <mat-icon>assignment</mat-icon>&nbsp;{{'MISC.WRITE' | translate}}
      </a>
  
      <a mat-list-item disableRipple routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
        [routerLink]="['/reports', reportID, 'diagnostics']">
        <mat-icon class="material-icons-outlined">science</mat-icon>&nbsp;{{'CASE SECTION.DIAGNOSTICS' | translate}}
      </a>
  
      <a mat-list-item disableRipple routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
        [routerLink]="['/reports', reportID, 'visits']">
        <mat-icon>timeline</mat-icon>&nbsp;{{'CASE SECTION.TIMELINE' | translate}}
      </a>
  
      <a mat-list-item disableRipple routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
        [routerLink]="['/reports', reportID, 'preview']">
        <mat-icon>preview</mat-icon>&nbsp;{{'ACTIONS.PREVIEW' | translate}}
      </a>
    </mat-nav-list>
  </div>
</ng-template>