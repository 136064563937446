import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { Report } from 'src/app/api/generated/graphql';
import { ReportService } from 'src/app/services/report/report.service';

@Component({
  selector: 'app-diagnostics-view',
  templateUrl: './diagnostics.component.html',
  styleUrls: ['./diagnostics.component.css']
})
export class DiagnosticsViewComponent implements OnInit {

  casereport$: Observable<Report> | null = null;

  constructor(
    private route: ActivatedRoute,
    private caseReportService: ReportService,
  ) { }

  ngOnInit(): void {
    if (this.route.parent) {
      const id: string = this.route.parent.snapshot.params.id;
      if (id) {
        this.casereport$ = this.caseReportService.getReport(id);
      }
    }
  }

}
