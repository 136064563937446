<button class="btn-menu" mat-icon-button [matMenuTriggerFor]="menuAuthors" aria-hidden="false" aria-label="Delete author">
  <mat-icon>delete</mat-icon>
</button>

<mat-menu #menuAuthors="matMenu">
  <button class="btn-delete" mat-menu-item (click)="delete()">
    <mat-icon class="default-icon" aria-hidden="false" aria-label="Delete attachment">
      delete
    </mat-icon>
    <span>{{'ACTIONS.REMOVE COAUTHOR' | translate}}</span>
  </button>
  <button mat-menu-item class="btn-cancel">
    <mat-icon class="default-icon" aria-hidden="false" aria-label="Cancel">cancel</mat-icon>
    <span>{{'ACTIONS.CANCEL' | translate}}</span>
  </button>

</mat-menu>
