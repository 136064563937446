import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-case-bottom-nav',
  templateUrl: './case-bottom-nav.component.html',
  styleUrls: ['./case-bottom-nav.component.css']
})
export class CaseBottomNavComponent implements OnInit {
  @Input() reportID: string | null = null;

  constructor() { }

  ngOnInit(): void {
  }

}
