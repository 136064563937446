<ng-container *ngIf="note">
  <div class="timeline-item">
    <span class="date">{{note.date | date:'yyyy-MM-dd': 'UTC'}}&nbsp;
      <span *ngIf="allowEdit">
        <button appDefaultGrey mat-icon-button (click)="editItem()"><mat-icon appDefaultGrey>edit</mat-icon></button>
        <app-delete-visit [noteId]="note.id" [reportId]="reportId"></app-delete-visit>
      </span>
    </span>
    <p class="mat-body">{{note.summary}}</p>
  </div>
</ng-container>