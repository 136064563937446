import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Attachment } from 'src/app/api/generated/graphql';
import { AttachmentsService } from 'src/app/services/attachments.service';

@Component({
  selector: 'app-attachments-column-layout',
  templateUrl: './attachments-column-layout.component.html',
  styleUrls: ['./attachments-column-layout.component.css']
})
export class AttachmentsColumnLayoutComponent implements OnInit {
  @Input() reportID: string = '';
  @Input() attachments: Attachment[] = [];
  attachments$: Observable<Attachment[]>;

  constructor(
    private attachmentsService: AttachmentsService,
    private router: Router,
  ) { }

  ngOnInit() {
    if (this.reportID) {
      const url = this.router.url;
      let segments: string[] = [];
      segments = url.split('/');
      segments = segments.filter(s => s != '');
      const isShareView = segments[0] === 'share' ? true : false;

      if (!isShareView) {
        this.attachments$ = this.attachmentsService.getAttachments(this.reportID);
      } else {
        this.attachments$ = this.attachmentsService.getPublicAttachments(this.reportID);
      }
    }
  }

}
