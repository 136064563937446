import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Visit } from 'src/app/api/generated/graphql';

@Component({
  selector: 'app-update-visit',
  templateUrl: './update-visit.component.html',
  styleUrls: ['./update-visit.component.css']
})
export class UpdateVisitComponent {

  visit: Visit | null = null;
  reportId: string = '';

  constructor(
    public dialogRef: MatDialogRef<UpdateVisitComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.visit = data.visit;
    this.reportId = data.reportId;
  }

  closeDialog(): void {
    this.dialog.closeAll()
  }

}
