<ng-container *ngIf="report">
  <div class="container">
    <div class="flex-row row-style" (click)="$event.stopPropagation()">
      <div class="column">
        <span class="mat-body">{{'MISC.SHARE OTHERS' | translate}}</span>
        <span class="mat-small text-gray">{{'MISC.USERS CAN VIEW' | translate}}</span>
      </div>
      <div class="toggle">
        <mat-slide-toggle [(ngModel)]="checked" (change)="onCheckPublic();"></mat-slide-toggle>
      </div>
    </div>
    <div class="flex-row second-row" *ngIf="checked">
      <input [value]="rootUrl + report.id" (click)="$event.stopPropagation()" class="mat-small"/>
      <button mat-stroked-button (click)="$event.stopPropagation(); openSnackBar();" cdkCopyToClipboard="{{rootUrl}}{{report.id}}">
        {{'ACTIONS.COPY' | translate}}
      </button>
    </div>
  </div>
</ng-container>
