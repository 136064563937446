import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { GetTypesAndUnitsGQL } from 'src/app/api/generated/graphql';
import { Unit, sortUnitsAlphabetically } from '../unit';

@Component({
  selector: 'app-unit-input',
  templateUrl: './unit-input.component.html',
  styleUrls: ['./unit-input.component.css']
})
export class UnitInputComponent implements OnInit {
  @Input()
  form!: FormGroup;
  filteredUnits!: Observable<Unit[]>;
  units!: Unit[];
  loading: boolean = true;
  error: any;

  isUnitValid$ = new BehaviorSubject(false);
  
  constructor(
    private getTypesAndUnits: GetTypesAndUnitsGQL,
  ) { }

  ngOnInit(): void {
    this.fetchTypesAndUnits()
  }

  listenAndFilterUnits() {
    this.filteredUnits = this.form.controls.unitValue.valueChanges
      .pipe(
        startWith(''),
        map(value => {
          return this._filterUnits(value)
        })
      );
  }

  private _filterUnits(value: string): Unit[] {
    if (!value) return this.units;

    const filterValue = value.toLowerCase();

    return this.units.filter(t => {
      return t.name.toLowerCase().includes(filterValue)
    });
  }

  fetchTypesAndUnits() {
    this.getTypesAndUnits.fetch()
      .subscribe({
        next: ({ loading, data }) => {
          this.units = sortUnitsAlphabetically((<any>data).diagnosticUnits);
          this.loading = loading;
        },
        error: err => {
          this.loading = false;
          this.error = err
          console.error(err)
        },
        complete: () => {
          this.listenAndFilterUnits();
        }
      })
  }

  validateCurrentUnit(val: string) {
    const isValid = this._findUnitObject(val)
    this.isUnitValid$.next(isValid)
  }

  private _findUnitObject(value: string): boolean {
    if (!value) return false;

    value = value.toLowerCase();

    const found = this.units.find(element => element.name.toLowerCase() == value);

    if (found && found.id) {
      return true
    }

    return false
  }

  checkFields(event: any) {

    let found;
    found = this.units.find(element => element.name == event.option.value)

    found ? this.isUnitValid$.next(true) : this.isUnitValid$.next(false);

  }


}
