<h1 mat-dialog-title>Version History</h1>
<div mat-dialog-content>
    <mat-form-field appearance="outline">
        <mat-label>Revision name</mat-label>
        <input matInput name="name" [formControl]="name" />
    </mat-form-field>
</div>
<div mat-dialog-actions>
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-button type="submit" (click)="saveRevision()" cdkFocusInitial>Save</button>
</div>