<div class="column">
  <div class="input-container">
    <textarea
      placeholder="{{'CASE SECTION.TITLE' | translate}}"
      type="text"
      [formControl]="titleControl"
      cdkTextareaAutosize
      cdkAutosizeMinRows="1"
      cdkAutosizeMaxRows="3">
    </textarea>
    <div *ngIf="titleControl?.invalid && (titleControl?.dirty || titleControl?.touched)" class="mat-small input-error">
      <span *ngIf="titleControl?.hasError('required')" class="mat-small">&nbsp; {{'FORMS.FIELD REQUIRED' | translate}}</span>
      <span *ngIf="titleControl?.hasError('maxlength')" class="mat-small">{{titleControl.value.length}}/255 &nbsp; {{'FORMS.MAX LENGTH' | translate}}</span>
    </div>
  </div>

</div>
<div class="btn-row">
  <button type="button" (click)="onSubmit()" mat-stroked-button>{{'ACTIONS.SAVE' | translate}}</button>&nbsp;
  <button mat-button (click)="emitClose();">{{'ACTIONS.CANCEL' | translate}}</button>
</div>
