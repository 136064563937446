import { Component, Input } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';
import { Author } from 'src/app/api/generated/graphql';
import { AuthorsService } from 'src/app/services/authors.service';

declare let gtag: Function;

@Component({
    selector: 'app-delete-author',
    templateUrl: './delete-author.component.html',
    styleUrls: ['./delete-author.component.css']
})
export class DeleteAuthorComponent {

  @Input() author: Author | null = null;
  @Input() reportID: string;
  error: any;

  constructor(
    private _snackBar: MatSnackBar,
    private translate: TranslateService,
    private authorService: AuthorsService,
  ) { }

  delete() {
    const obs = this.authorService.deleteAuthor(this.author.id, this.reportID, this.author.order);

    obs.pipe(first()).subscribe({
      error: err => {
        this.error = err;
        console.error(err);
      },
      complete: () => this.onSuccess()
    })
  }

  onSuccess() {
    this.sendEvent();

    this.translate.get(['SNACKS.AUTHOR DELETED', 'SNACKS.DISMISS']).pipe(first()).subscribe((res: any) => {
      this._snackBar.open(res['SNACKS.AUTHOR DELETED'], res['SNACKS.DISMISS'], {
        duration: 3000,
      });
    })
  }

  sendEvent(): void {
    gtag('event', 'delete_author', {
      'event_category': 'db-delete',
      'event_label': 'method'
    });
  }
}
