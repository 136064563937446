import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css']
})
export class SignUpComponent implements OnInit {

  inviteId: string | null = null;

  constructor(
    private auth: AuthService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.route.queryParams.pipe(first()).subscribe(m => {
      const invite = m["invite_id"];
      if (invite && invite.length) {
        this.inviteId = invite;
      }
    })
  }

  signup() {
    if (this.inviteId) {
      this.auth.loginWithRedirect({
        appState: {
          target: `/invitation/${this.inviteId}?isSignup=true`
        },
        screen_hint: "signup",
      })
    } else {
      this.auth.loginWithRedirect({
        screen_hint: "signup",
        appState: {
          target: `/profile?isSignup=true`
        },
      })
    }
  }

  login() {
    this.auth.loginWithRedirect();
  }

}
