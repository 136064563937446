<mat-horizontal-stepper linear #stepper [disableRipple]="true">
  <mat-step [stepControl]="beginCaseForm" [optional]="false" [editable]="false">
    <ng-template matStepLabel>{{'MISC.GATHER INFO' | translate}}</ng-template>
    <app-message-info content="{{'MISC.UPDATE ANY TIME' | translate}}"></app-message-info>
    <form [formGroup]="beginCaseForm" (ngSubmit)="onSubmit(beginCaseForm, stepper)">
      <div class="column">
        <label class="mat-body">{{'MISC.WORKING TITLE' | translate}}</label>
        <input id="title-input" name="title" formControlName="title">
    
        <div *ngIf="title?.invalid && (title?.dirty || title?.touched)">
          <div class="input-error" *ngIf="title?.errors.required">{{'FORMS.FIELD REQUIRED' | translate}}</div>
          <div class="input-error" *ngIf="title?.hasError('minlength')">{{'FORMS.MIN LENGTH 2' | translate}}</div>
        </div>

        <label class="mat-body second-label">{{'CASE SECTION.KEYWORDS' | translate}} <i>({{'MISC.COMMAS' | translate}})</i></label>
    
        <input required id="keywords-input" name="keywords" formControlName="keywords">
        <div *ngIf="keywords?.invalid && (keywords?.dirty || keywords?.touched)">
          <div class="input-error" *ngIf="keywords?.errors.required">{{'FORMS.FIELD REQUIRED' | translate}}</div>
          <div class="input-error" *ngIf="keywords?.hasError('minlength')">{{'FORMS.MIN LENGTH 2' | translate}}</div>
        </div>

        <button color="accent" mat-flat-button type="submit">{{'ACTIONS.NEXT' | translate}}</button>
      </div>
    </form>

  </mat-step>
  <mat-step [editable]="false">
    <ng-template matStepLabel>{{'MISC.INVITE AUTHORS' | translate}}</ng-template>
    <app-add-user-to-group [reportID]="reportID"></app-add-user-to-group>
    <div class="btn-row">
      <button class="mat-body" mat-flat-button color="accent" matStepperNext>{{'ACTIONS.DONE' | translate}}</button>&nbsp;
      <button class="mat-body" mat-button matStepperNext>{{'ACTIONS.SKIP' | translate}}</button>
    </div>
  </mat-step>

  <mat-step [editable]="false">
    <ng-template matStepLabel>{{'MISC.DEIDENT DATA' | translate}}</ng-template>
    <p class="mat-body">{{'INFO.CONSENT2' | translate}}</p>
    <div class="btn-row">
      <button class="mat-body" mat-flat-button color="accent" (click)="moveOn()">{{'MISC.I UNDERSTAND' | translate}}</button>&nbsp;
    </div>
  </mat-step>
</mat-horizontal-stepper>