import { Component, Input, OnInit } from '@angular/core';
import { ModalService } from 'src/app/services/modal.service';

@Component({
  selector: 'app-delete-case-report',
  templateUrl: './delete-case-report.component.html',
  styleUrls: ['./delete-case-report.component.css']
})
export class DeleteCaseReportComponent implements OnInit {
  @Input() reportID: string = "";
  @Input() title: string = "";
  error: any;

  constructor(
    private modalService: ModalService,
  ) { }

  ngOnInit(): void {
  }

  deleteDocument(): void {
    const title = this.title;
    const val: string = title.length < 30 ? title : title.substring(0, 30) + '...'; 
    this.modalService.deleteDialog(this.reportID, val)
  }
  
}
