import { Injectable } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, first, map } from 'rxjs/operators';
import { Report as ApiReport, ReportGQL, ReportSectionCommentsGQL, ReportsGQL, SectionComment, CreateReportGQL, ReportsDocument, DeleteReportGQL, ReportContentGQL, PublicReportGQL, NewAuthor, SendInvitationGQL, GetInviteGQL, MyCoauthorReportsGQL, ReportOwnerGQL, GetReportPdfGQL, RespondToInviteGQL, ReportUpdatesGQL, RequiredSection, MyInvitesDocument, MyCoauthorReportsDocument } from 'src/app/api/generated/graphql';
import LogRocketErrorHandler from 'src/app/classes/logrocket-error';
import { Report } from 'src/app/classes/report';
import { AuthorsService } from '../authors.service';




export interface LastUpdated {
  date: Date
  lastEditor: string
}

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  private report$: BehaviorSubject<ApiReport>;
  private sections$: BehaviorSubject<RequiredSection[]>;
  private publicSections$: BehaviorSubject<RequiredSection[]>;
  private lastUpdate$: BehaviorSubject<Date>;
  
  constructor(
    private auth: AuthService,
    private reportGQL: ReportGQL,
    private reportPDFGQL: GetReportPdfGQL,
    private reportsGQL: ReportsGQL,
    private sectionCommentsGQL: ReportSectionCommentsGQL,
    private createReportGQL: CreateReportGQL,
    private deleteCaseGQL: DeleteReportGQL,
    private reportContentGQL: ReportContentGQL,
    private publicReportGQL: PublicReportGQL,
    private sendInviteGQL: SendInvitationGQL,
    private respondToInviteGQL: RespondToInviteGQL,
    private getInviteGQL: GetInviteGQL,
    private coAuthoredReportsGQL: MyCoauthorReportsGQL,
    private reportOwnerGQL: ReportOwnerGQL,
    private reportUpdatesGQL: ReportUpdatesGQL,
    private authorService: AuthorsService,
  ) {
    this.report$ = new BehaviorSubject(null);
    this.sections$ = new BehaviorSubject([]);
    this.publicSections$ = new BehaviorSubject([]);
    this.lastUpdate$ = new BehaviorSubject(null);
  }

  /**
  * Gets a report from ApolloGQL and returns the id, title, keywords,
  * and corresponding author as class ApiReport
  */
  public getReport(reportID: string): Observable<ApiReport> {
    return this.reportGQL.watch({
      id: reportID
    })
      .valueChanges
      .pipe(
        map((result) => {
          return (<any>result.data).report;
        }),
        catchError(err => {
          console.error(err);
          let handler = new LogRocketErrorHandler;
          handler.handleError(err);
          return of(null)
        })
      )
  }

  /**
   * Gets all the reports for a user. The server uses context to determine user id.
   * Returns the id, title, and case report sections as class ApiReport.
   */
  public getReports(): Observable<Report[]> {

    const obs = this.reportsGQL.watch()
      .valueChanges
      .pipe(map((result) => {
        let reports: ApiReport[] = (<ApiReport[]>result.data.reports);
        let newReps: Report[] = reports.map(rep => new Report(rep))
        return newReps;
      }))
    return obs;
  }

  public myCoAuthorReports(): Observable<Report[]> {

    const obs = this.coAuthoredReportsGQL.watch()
      .valueChanges
      .pipe(map((result) => {
        let reports: ApiReport[] = (<ApiReport[]>result.data.myCoauthorReports);
        let newReps: Report[] = reports.map(rep => new Report(rep))
        return newReps;
      }))
    return obs;
  }

  public deleteCaseReport(reportID: string): Observable<ApiReport> {
    const obs = this.deleteCaseGQL.mutate({ id: reportID }, {
      refetchQueries: [{ query: ReportsDocument }]
    })
      .pipe(map((result: any) => {
        return result.data.deleteReport;
      }))

    return obs;
    
  }

  public getSectionComments(reportID: string, sectionID: string): Observable<SectionComment[] | null> {
    const obs = this.sectionCommentsGQL.watch({
      id: reportID
    })
      .valueChanges
      .pipe(map((result: any) => {
        const sections = result.data.report?.sections;
        const filteredSection = sections?.find((section: { id: string; }) => section.id === sectionID)
        const comments = filteredSection.comments;

        const createDataTree = (dataset: any[]) => {
          let hashTable = Object.create(null)
          dataset.forEach(aData => hashTable[aData.id] = { ...aData, children: [] })
          let dataTree: any[] = []
          dataset.forEach(aData => {
            if (aData.parentID) hashTable[aData.parentID].children.push(hashTable[aData.id])
            else dataTree.push(hashTable[aData.id])
          })
          return dataTree
        };
        
        return createDataTree(comments)
      }))
    return obs;
  }

  public createNewCase(title: string, keywords: string): Observable<ApiReport> {
    const obs = this.createReportGQL.mutate({
      title: title,
      keywords: keywords
    }, {
      refetchQueries: [{ query: ReportsDocument }]
    })
     .pipe(map((result: any) => {
       const report = result.data.createReport

       if (report) {
         let author: NewAuthor = {
           firstName: report.owner.givenName,
           lastName: report.owner.familyName,
           email: report.owner.email,
           reportID: report.id,
           careUserID: report.owner.id,
         }

        const obs = this.authorService.createAuthor(author, report.id);
        obs.pipe(first()).subscribe({
          next: (data) => {
            const authorID = data.id;
            const reportID = data.report.id;

            const obs = this.authorService.setCorrAuthor(authorID, reportID);
            obs.pipe(first()).subscribe();
          }
        });
       }
       return result.data.createReport;
     }))

     return obs;
  }

  /**
  * Gets references section
  */
  public getReportReferences(reportID: string): Observable<any> {
    return this.reportContentGQL.watch({id: reportID})
      .valueChanges
      .pipe(
        map((result) => {
          return (<any>result.data).report.sections[6];
        })
      )
  }

  public getPublicReport(reportID: string): Observable<Report> {
    return this.publicReportGQL.fetch({
      id: reportID
    })
      .pipe(
        map((result: any) => {
          const report: ApiReport = (<ApiReport>result.data.publicReport);
          this.publicSections$.next(report.sections)
          const newReport = new Report(report);
          this.lastUpdate$.next(newReport.updatedAt);
          return newReport;
        })
      )
  }

  public sendInvitation(reportID: string, inviteeEmail: string): Observable<String> {
    const email = inviteeEmail.toLowerCase()
    const obs = this.sendInviteGQL.mutate({
      reportID: reportID,
      inviteeEmail: email
    })
      .pipe(map((result: any) => {
        return result.data.sendInvitation
      }))

    return obs;
  }

  public respondToInvite(reportID: string, email: string, response: string): Observable<boolean> {
    email = email.toLowerCase();
    const obs = this.respondToInviteGQL.mutate({
      reportID: reportID,
      email: email,
      response: response,
    }, {
      refetchQueries: [{ query: ReportsDocument }, {query: MyCoauthorReportsDocument}, {query: MyInvitesDocument}]
    })
      .pipe(map((result: any) => {
        return result.data.respondToInvite
      }))

    return obs;

  }

  public getInvite(inviteID: string): Observable<any> {
    const obs = this.getInviteGQL.fetch({
      inviteID: inviteID,
    })
      .pipe(map((result: any) => {
        return result.data.getInvite
      }))

    return obs;
  }

  public getReportOwnerID(reportID: string): Observable<string> {
    const obs = this.reportOwnerGQL.fetch({
      reportID: reportID,
    })
      .pipe(map((result: any) => {
        return result.data.report.owner.id
      }))

    return obs;
  }

  public getReportOwnerEmail(reportID: string): Observable<string> {
    const obs = this.reportOwnerGQL.fetch({
      reportID: reportID,
    })
      .pipe(map((result: any) => {
        return result.data.report.owner.email
      }))

    return obs;
  }
  
  public getReportPdf(reportID: string): Observable<ApiReport> {
    return this.reportPDFGQL.fetch({
      id: reportID
    })
      .pipe(
        map((result) => {
          return (<any>result.data).report;
        })
      )
  }

  // pollReport catches two specific errors and redirects to Login
  public pollReport(id: string): Observable<ApiReport> {
    const obs = this.reportUpdatesGQL.watch({
      id: id
    }, {
      pollInterval: 6000,
    })
      .valueChanges
      .pipe(
        map((result: any) => {
          const rep: ApiReport = result.data.report;
          this.report$.next(rep);
          this.sections$.next(rep.sections);
          return rep
        })
      )
    return obs;
  }

  public getCaseReportAsObservable(): Observable<ApiReport> {
    return this.report$.asObservable();
  }

  public getSectionsAsObservable(): Observable<RequiredSection[]> {
    return this.sections$.asObservable();
  }

  public getPublicSectionsAsObservable(): Observable<RequiredSection[]> {
    return this.publicSections$.asObservable();
  }

  public getLastUpdateAsObservable(): Observable<Date> {
    return this.lastUpdate$.asObservable();
  }

}