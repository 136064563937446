import { Injectable } from '@angular/core';
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';
import htmlToPdfmake from "html-to-pdfmake";

const superscripts = {
  0: '\u2070',
  1: '\u00B9',
  2: '\u00B2',
  3: '\u00B3',
  4: '\u2074',
  5: '\u2075',
  6: '\u2076',
  7: '\u2077',
  8: '\u2078',
  9: '\u2079',
};

const subscripts = {
  0: '\u2080',
  1: '\u2081',
  2: '\u2082',
  3: '\u2083',
  4: '\u2084',
  5: '\u2085',
  6: '\u2086',
  7: '\u2087',
  8: '\u2088',
  9: '\u2089',
};

const cfg = {}

@Injectable({
  providedIn: 'root'
})
export class DeltaConvertService {

  constructor() { }

  genPDF(data: any) {
    const opsConverter = new QuillDeltaToHtmlConverter(
      JSON.parse(data).ops,
      cfg
    )

    const that = this;

    opsConverter.beforeRender(function (groupType, data) {

      if (data.hasOwnProperty('ops')) {
        data['ops'].forEach(entry => {
          if (entry.attributes.hasOwnProperty('script')) {
            if (entry.attributes.script == 'super') {
              const val = that.unicodeSwap(entry.insert.value, 'super');
              entry.insert.value = val;
            } else if (entry.attributes.script == 'sub') {
              const val = that.unicodeSwap(entry.insert.value, 'sub');
              entry.insert.value = val;
            }
          }
        })
      }

      return '';

    });

    const conv = opsConverter.convert()
    return htmlToPdfmake(conv)

  }

  unicodeSwap(val: string, script: string) {
    const len = val.length;
    let newVal = '';

    for (var i = 0; i < len; i++) {
      if (val.charAt(i) == ' ') {
        continue;
      }
      if (script === 'super') {
        let sup = superscripts[val.charAt(i)]

        if (!sup) {
          continue;
        }

        newVal = newVal + sup

      } else if (script === 'sub') {
        let sub = superscripts[val.charAt(i)]

        if (!sub) {
          continue;
        }

        newVal = newVal + subscripts[val.charAt(i)];
      }
    }

    return newVal;
  }


}