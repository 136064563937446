import { Component, Inject } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-data-overwrite-warning',
  templateUrl: './data-overwrite-warning.component.html',
  styleUrls: ['./data-overwrite-warning.component.css']
})
export class DataOverwriteWarningComponent {

  reportId: string | undefined;

  constructor(
    public dialogRef: MatDialogRef<DataOverwriteWarningComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: string
  ) {
    this.reportId = data;
  }

  confirmEditing(): void {
    this.dialogRef.close("accept")
  }

}
