import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { RequiredSection, User } from 'src/app/api/generated/graphql';
import { RequiredSectionsService } from 'src/app/services/required-sections/required-sections.service';
import { ModalService } from 'src/app/services/modal.service';
import { AuthorsService } from 'src/app/services/authors.service';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { ReportService } from 'src/app/services/report/report.service';

@Component({
  selector: 'app-quill-wrapper',
  templateUrl: './quill-wrapper.component.html',
  styleUrls: ['./quill-wrapper.component.css'],
})
export class QuillWrapperComponent implements OnInit, OnDestroy {
  @Input() reportID: string = '';
  @Input() updatedAt: any = '';
  @Input() sectionId: string | undefined;
  @Input() subtitle: string = "";
  section$: BehaviorSubject<RequiredSection>;
  hasCoauthors: boolean | null = null;
  lastUpdated$: BehaviorSubject<any>;
  lastEditor$: BehaviorSubject<any>;
  userId: string | undefined;
  readOnly: boolean = true;

  unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private sectionService: RequiredSectionsService,
    private reportService: ReportService,
    private modalService: ModalService,
    private authorsService: AuthorsService,
    private profileService: ProfileService,
  ) {
    this.lastUpdated$ = new BehaviorSubject<any>(null);
    this.lastEditor$ = new BehaviorSubject<any>(null);
    this.section$ = new BehaviorSubject<RequiredSection>(null);
  }

  ngOnInit(): void {
    if (this.sectionId && this.reportID) {

      const obs = this.reportService.getSectionsAsObservable();
      obs.pipe(takeUntil(this.unsubscribe$)).subscribe({
        next: (sections) => {
          if (!sections.length) return;
          let section: any;
          section = sections.filter(s => s.id === this.sectionId)[0];
          this.section$.next(section);
        }
      })

      this.profileService.getProfile().pipe(first()).subscribe((u: User) => {
        this.userId = u.id;
      })

      this.authorsService.hasCoAuthors(this.reportID).pipe(takeUntil(this.unsubscribe$)).subscribe((hasCoauthors: boolean) => {
        if (hasCoauthors) {
          this.hasCoauthors = true;
        }
      })

      this.monitorSectionUpdates();
      
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  monitorSectionUpdates() {
    const obs = this.reportService.getSectionsAsObservable();
    obs.pipe(takeUntil(this.unsubscribe$)).subscribe({
      next: (sections) => {
        if (!sections.length) return;
        const section: RequiredSection = sections.filter(s => s.id === this.sectionId)[0];
        if (section.updatedAt != section.createdAt) {
          const date = new Date(section.updatedAt);
          this.lastUpdated$.next(date);
        }
        this.lastEditor$.next(section.lastEditedBy);  
      }
    })
  }

  confirmRecentlyEdited(): void {
    if (!this.hasCoauthors) {
      this.readOnly = false;
      return;
    }
    const HOUR = 1000 * 60 * 60;
    const anHourAgo = Date.now() - HOUR;
    
    let lastUpdate = this.lastUpdated$.getValue();
    let lastEditor = this.lastEditor$.getValue();
    
    if (!lastEditor) {
      this.readOnly = false;
      return;
    } 

    if (lastEditor === this.userId) {
      this.readOnly = false;
      return;
    }

    if (lastUpdate.getTime() < anHourAgo) {
      this.readOnly = false;
      return;
    }
    
    this.modalService.openConfirmToEdit(this.reportID).pipe(first()).subscribe((accepted: string) => {
      if (accepted === "accept") {
        const obs = this.sectionService.updateLastUpdated(this.sectionId, this.reportID)
        obs.pipe(first()).subscribe({
          next: (data) => {
            this.lastEditor$.next(data.lastEditedBy)
          },
          complete: () => this.readOnly = false
        })
      } else {
        this.readOnly = true;
        return;
      }
    })

  }
}
