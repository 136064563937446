import { Component, Input, OnInit } from '@angular/core';
import { Report, Translation } from 'src/app/api/generated/graphql';
import { ModalService } from 'src/app/services/modal.service';
import { takeUntil, first } from 'rxjs/operators';
import { TranslatorService } from 'src/app/services/translator.service';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

export interface TranslatableContent {
  reportID: string;
  title: string;
  keywords: string;
  sectionID: string;
}

@Component({
  selector: 'app-translator',
  templateUrl: './translator.component.html',
  styleUrls: ['./translator.component.css']
})
export class TranslatorComponent implements OnInit {

  @Input() report: Report | null = null;
  unsubscribe$: Subject<void> = new Subject();
  translation: Translation;
  checked: boolean = false;
  editTranslation: boolean = false;

  constructor(
    private modalService: ModalService,
    private translatorService: TranslatorService,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    if (this.report) {
      this.translatorService.getTranslation(this.report.id).pipe(takeUntil(this.unsubscribe$)).subscribe({
        next: (data) => {
          if (data.id) {
            this.translation = data;
            if (!data.deletedAt) {
              this.checked = true;
            }
          }
        }
      })
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  openTranslatorDialog() {
    const obj: TranslatableContent = {
      reportID: this.report.id,
      title: this.report.title,
      keywords: this.report.keywords,
      sectionID: this.report.sections[0].id,
    }

    const valid = this.validateTranslatableContent(obj, this.report.sections[0].content);

    if (valid) {
      this.modalService.openTranslator(obj)
    } else {
      this.translate.get("TRANSLATION.INSUFFICIENT CONTENT").pipe(first()).subscribe({
        next: (data) => {
          this.modalService.openMessageDialog(data);
        }
      });
    }

  }

  showOrHide(): void {
    const val = this.checked ? 'show' : 'hide';

    if (val === 'show') {
      this.translatorService.restoreTranslation(this.report.id, this.translation.id).pipe(first()).subscribe()
    } else {
      this.translatorService.deleteTranslation(this.report.id, this.translation.id).pipe(first()).subscribe()
    }
  }

  validateTranslatableContent(obj: TranslatableContent, section: any): boolean {

    if (obj.title.length < 10) {
      return false;
    }

    if (obj.keywords.length < 10) {
      return false;
    }

    if (section.length < 500) {
      return false;
    }

    return true;
  }

}
