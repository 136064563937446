<div class="column">
  <div class="header">
    <div class="left">
      <span class="mat-title">{{'CASE SECTION.ATTACHMENTS' | translate}}</span>
      <span class="mat-small">&nbsp;&nbsp;(such as images, customized timelines, special tables, etc.)</span>
    </div>
    <button [ngClass]="{'hide': showAttachment}" (click)="imageInput.click()" mat-button><mat-icon>add</mat-icon>&nbsp;{{'ACTIONS.ADD' | translate}}</button>
  </div>
  <mat-divider></mat-divider>
  <app-preview-attachment *ngIf="fileToPreview" [file]="fileToPreview"></app-preview-attachment>
  <app-new-attachment *ngIf="showAttachment" (closePreview)="toggleNewAttachment()" [fileToUpload]="fileToPreview"
    [reportId]="reportId"></app-new-attachment>

  <div class="grid-image" *ngIf="reportId">
    <app-list-attachments [reportID]="reportId"></app-list-attachments>
  </div>
</div>
<input (change)="previewFile($event.target.files)" #imageInput type="file" id="image-input"
  accept="image/jpeg, image/png">