<ng-container *ngIf="report">

  <app-quill-wrapper
    [reportID]="report.id"
    [sectionId]="report.sections[0].id">
  </app-quill-wrapper>

  <app-quill-wrapper
    [reportID]="report.id"
    [sectionId]="report.sections[1].id">
  </app-quill-wrapper>

  <app-timeline-placeholder [reportID]="report.id"></app-timeline-placeholder>
  
  <app-quill-wrapper
    [reportID]="report.id"
    [sectionId]="report.sections[2].id">
  </app-quill-wrapper>

  <app-diagnostics-placeholder [reportID]="report.id"></app-diagnostics-placeholder>

  <app-quill-wrapper
    [reportID]="report.id"
    [sectionId]="report.sections[7].id">
  </app-quill-wrapper>

  <app-quill-wrapper
    [reportID]="report.id"
    [sectionId]="report.sections[3].id">
  </app-quill-wrapper>


  <app-quill-wrapper
    [reportID]="report.id"
    [sectionId]="report.sections[4].id">
  </app-quill-wrapper>

  <app-quill-wrapper
    [reportID]="report.id"
    [sectionId]="report.sections[5].id">
  </app-quill-wrapper>

  <app-quill-wrapper
    [reportID]="report.id"
    [sectionId]="report.sections[6].id"
    subtitle="any format accepted">
  </app-quill-wrapper>
  
  <app-corresponding-author [reportId]="report.id" [author]="report.corrAuthor"></app-corresponding-author>

  <h3 class="mat-title pad">{{'SUBHEADINGS.AUTHOR AFFILIATIONS' | translate}}</h3>
  <app-report-author-affiliations [reportID]="report.id"></app-report-author-affiliations>

  <app-attachments-wrapper></app-attachments-wrapper>

</ng-container>