<button class="btn-delete" mat-icon-button aria-hidden="false" aria-label="Delete attachment" [matMenuTriggerFor]="menu">
  <mat-icon>delete</mat-icon>
</button>

<mat-menu #menu="matMenu">
  <button class="btn-delete" mat-menu-item (click)="delete()">
    <mat-icon class="default-icon" aria-hidden="false" aria-label="Delete attachment">
      delete
    </mat-icon>
    <span>{{'ACTIONS.DELETE' | translate}}</span>
  </button>
  <button mat-menu-item>
    <mat-icon class="default-icon" aria-hidden="false" aria-label="Cancel">cancel</mat-icon>
    <span>{{'ACTIONS.CANCEL' | translate}}</span>
  </button>

</mat-menu>