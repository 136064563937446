import { Component, Input, OnInit, Renderer2} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AllDiagnosticsDocument, CreateDiagnosticGQL } from 'src/app/api/generated/graphql';
import { Unit } from '../unit';
import { Type, sortTypesAlphabetically } from '../type';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject, Observable } from 'rxjs';
import { first, map, startWith } from 'rxjs/operators';
import { Diagnostic } from '../../../classes/diagnostic';
import { UnitsAndTypesService } from '../units-and-types.service';
import { TranslateService } from '@ngx-translate/core';
import { CustomDateAdapterService } from 'src/app/custom-date-adapter.service';

declare let gtag: Function;

@Component({
  selector: 'app-new-diagnostic',
  templateUrl: './new-diagnostic.component.html',
  styleUrls: ['./new-diagnostic.component.css']
})
export class NewDiagnosticComponent implements OnInit {

  dForm!: FormGroup;
  lastDate: Date = new Date();
  formConfiguration: string[] = ['single', 'double', 'default-values']
  currentFormConfig: string = '';

  isTypeValid$ = new BehaviorSubject(false);
  isUnitValid$ = new BehaviorSubject(false);

  loading = true;

  error: any;
  types: Type[] = [];
  units: Unit[] = [];

  @Input() reportID: string = '';
  @Input() showMessage: boolean = true;

  filteredTypes!: Observable<Type[]>;

  batchCreate: boolean = false;

  singleValPlaceholder!: string;
  alternateValPlaceholder!: string;
  defaultVal1!: string;
  defaultVal2!: string;

  constructor(
    private dateAdapter: CustomDateAdapterService,
    private renderer: Renderer2,
    private createDiagnosticGQL: CreateDiagnosticGQL,
    private unitsAndTypesService: UnitsAndTypesService,
    private _snackBar: MatSnackBar,
    private translate: TranslateService,
    private fb: FormBuilder) { }

  ngOnInit(): void {
    
    const dataList = this.unitsAndTypesService.getUnitsAndTypes()
    dataList.pipe(first()).subscribe( (data: any) => {
      this.types = sortTypesAlphabetically(data.diagnosticTypes);
      this.units = data.diagnosticUnits;

      this.listenAndFilterTypes();

      this.loading = false;
    })
    
    let newForm = this.fb.group({
      dateValue: [this.lastDate, [Validators.required]],
      typeValue: ['', [Validators.required]],
      formArray: this.fb.array([])
    });

    this.dForm = newForm;

    this.renderer.selectRootElement('#date-input').focus();

  }

  listenAndFilterTypes() {
    if (this.typeValue) {
      this.filteredTypes = this.typeValue.valueChanges
        .pipe(
          startWith(''),
          map(value => {
            return this._filter(value)
          })
        )
    }
  }

  validateCurrentInput(val: string) {
    const isValid = this._findTypeObject(val)
    this.isTypeValid$.next(isValid)
  }

  updateForm(config: string) {
    if (this.currentFormConfig === config) {
      // don't update
      return;
    }

    const arrayControl = <FormArray>this.dForm.controls['formArray'];
    
    if (this.currentFormConfig = this.formConfiguration[0]) {
      arrayControl.removeAt(0)
    } else if (this.currentFormConfig = this.formConfiguration[1]) {
      arrayControl.removeAt(1)
      arrayControl.removeAt(0)
    } else if (this.currentFormConfig = this.formConfiguration[2]) {
      arrayControl.removeAt(0)
    }

    this.currentFormConfig = config;

    if (config === this.formConfiguration[0]) {
      // make a single group
      let group1 = this.fb.group({
        singleValue: ['', [Validators.required]],
        unitValue: ['', [Validators.required]],
      });
      arrayControl.push(group1);

    } else if (config === this.formConfiguration[1]) {
      // make a double group
      let group2 = this.fb.group({
        singleValue: ['', [Validators.required]],
        alternateValue: ['', [Validators.required]],
        unitValue: ['', [Validators.required]],
      });
      arrayControl.push(group2);

    } else if (config === this.formConfiguration[2]) {
      // make a default group
      let group3 = this.fb.group({
        defaultValue: ['', [Validators.required]]
      });
      arrayControl.push(group3);

    }

    // return form;
  }

  onSubmit(form: FormGroup, event: any) {
    // first check the whole form's validity
    let valid = this.validator(form)

    if (valid) {

      this.lastDate = form.controls.dateValue.value;

      let typeObj: Type | undefined;
      let unitObj: Unit | undefined;

      //  run some additional validation for types and units
      //  now check the validity of the incoming type
      typeObj = this.types.find(val => val.name.toLowerCase() == form.controls.typeValue.value.toLowerCase())
      if (!typeObj) {
        return
      }

      // Unit is optional on formConfiguration[2]
      if (this.currentFormConfig !== this.formConfiguration[2]) {
        unitObj = this.units.find(val => val.name.toLowerCase() == form.controls.formArray.value[0].unitValue.toLowerCase())
        if (!unitObj) {
          return
        }
      }
      
      let diag: Diagnostic = 
        {
          id: this.reportID,
          date: form.controls.dateValue.value,
          type: typeObj,
          unit: unitObj || null,
          value: form.controls.formArray.value[0].singleValue || null,
          defaultValue: form.controls.formArray.value[0].defaultValue || null,
          alternateValue: form.controls.formArray.value[0].alternateValue || null,
        };
      
      this.create(diag)

      this.updateForm(this.formConfiguration[0]);
      event.currentTarget.reset()

      this.singleValPlaceholder = '';
      this.alternateValPlaceholder = '';
      if (this.dateValue) {
        this.dateValue.setValue(this.lastDate);
      }

      if (this.batchCreate) {
        this.renderer.selectRootElement('#type-input').focus();
      } else {
        this.renderer.selectRootElement('#date-input').focus();
      }

    }
  }

  validator(form: FormGroup) {
    
    if (form.invalid) {
      return false;
    }

    return true;
  }

  create(entry: Diagnostic) {
    let d = this.dateAdapter.format((<Date>entry.date), "input");
    this.createDiagnosticGQL
      .mutate({
          date: d,
          unitID: entry.unit ? entry.unit.id : null,
          typeID: entry.type.id,
          reportID: entry.id,
          value: entry.value || null,
          alternateValue: entry.alternateValue || null,
          defaultValue: entry.defaultValue || null,
        },
        {refetchQueries: [{ query: AllDiagnosticsDocument, variables: { id: this.reportID } }],
      })
      .subscribe({
        error: err => {
          this.error = err;
          console.error(err);
        },
        complete: () => {
          this.onSuccess();
          this.sendEvent();
        }
      });
  }


  checkFields(event: any) {

    let found: any;
    found = this.types.find( element => element.name == event.option.value)
    
    found ? this.isTypeValid$.next(true) : this.isTypeValid$.next(false);

    if (found && found.subtypes.length !== 0) {
      this.updateForm(this.formConfiguration[1])
      this.singleValPlaceholder = found.subtypes[0].name || '';
      this.alternateValPlaceholder = found.subtypes[1].name || '';

    } else if (found && found.defaultValues.length) {
      this.updateForm(this.formConfiguration[2])

      this.defaultVal1 = found.defaultValues[0].name || '';
      this.defaultVal2 = found.defaultValues[1].name || '';

    } else {
      this.updateForm(this.formConfiguration[0])
    }
  }

  get dateValue() { return this.dForm.get('dateValue') }

  get diagnosticValue() { return this.dForm.get('diagnosticValue') }

  get unitValue() { return this.dForm.get('unitValue') }

  get typeValue() { return this.dForm.get('typeValue') }

  get alternateValue() { return this.dForm.get('alternateValue') }

  get defaultValue() { return this.dForm.get('defaultValue') }

  onSuccess() {

    this.translate.get(['SNACKS.DIAGNOSTIC CREATED', 'SNACKS.DISMISS']).pipe(first()).subscribe((res: any) => {
      this._snackBar.open(res['SNACKS.DIAGNOSTIC CREATED'], res['SNACKS.DISMISS'], {
        duration: 3000,
      });
    })

  }

  private _findTypeObject(value: string): boolean {
    if (!value) return false;
    value = value.toLowerCase();

    const found = this.types.find(element => element.name.toLowerCase() == value);

    if (found && found.id) {
      return true
    }
    return false
  }

  private _filter(value: string): Type[] {
    if (!value) return this.types;

    const filterValue = value.toLowerCase();

    return this.types.filter(t => {
      return t.name.toLowerCase().includes(filterValue)
    });
  }

  sendEvent(): void {
    gtag('event', 'create diagnostic', {
      'event_category': 'db-create',
      'event_label': 'method'
    });
  }

}
