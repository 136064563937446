import { Component, OnInit, Input, Renderer2, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import Quill from 'quill';
import { BehaviorSubject, Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { RequiredSection } from 'src/app/api/generated/graphql';
import { ReportService } from 'src/app/services/report/report.service';

@Component({
  selector: 'app-quill-read-only',
  templateUrl: './quill-read-only.component.html',
  styleUrls: ['./quill-read-only.component.css'],
  providers: [ReportService],
})
export class QuillReadOnlyComponent implements OnInit, OnDestroy {
  @Input() sectionID: string = '';
  @Input() reportID: string = '';
  @Input() altID: boolean = false;
  @Input() isPreviewView: boolean = false;
  heading: string = '';
  hasContent: boolean = false;
  
  @ViewChild('readonly', { read: ElementRef, static: true })
  container!: ElementRef
  quill: any;
  content: string = '';
  unsubscribe$: Subject<void> = new Subject<void>();
  content$: BehaviorSubject<any>;

  constructor(
    private renderer: Renderer2,
    private reportService: ReportService,
  ) {
    this.content$ = new BehaviorSubject(null);
  }

  ngOnInit() {
    if (this.sectionID && this.reportID) {
      const obs = this.reportService.getSectionsAsObservable();
      obs.pipe(first()).subscribe({
        next: (sections) => {
          let section: RequiredSection;

          if (!sections.length) {
            this.reportService.getReport(this.reportID).pipe(takeUntil(this.unsubscribe$)).subscribe({
              next: (report) => {
                section = report.sections.filter(s => s.id === this.sectionID)[0];
                if (section.content) {
                  this.content$.next(section.content)
                  this.heading = section.heading
                }
                let divID = "r" + this.sectionID.substring(0, 7);
                if (this.altID) {
                  divID = divID + "alt"
                }
                this.createEditor(divID);
                this.initEditor(divID);
              }
            });
          } else {
            section = sections.filter(s => s.id === this.sectionID)[0];
            if (section.content) {
              this.content$.next(section.content)
              this.heading = section.heading
            }
            let divID = "r" + this.sectionID.substring(0, 7);
            if (this.altID) {
              divID = divID + "alt"
            }
            this.createEditor(divID);
            this.initEditor(divID);
          }
        }
      })
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  createEditor(divID: string) {
    const div = this.renderer.createElement('div');
    this.renderer.setProperty(div, "id", divID);
    this.renderer.appendChild(this.container.nativeElement, div);
  }

  initEditor(divID: string) {
    var options = {
      placeholder: '',
      readOnly: true,
      theme: 'bubble',
      modules: {
        toolbar: false,
      }
    };

    const name = "#" + divID;
    this.quill = new Quill(name, options);
    this.content$.pipe(first()).subscribe({
      next: (data) => {
        this.quill.setContents(JSON.parse(data));
      },
      complete: () => {
        if (this.quill.getText().trim().length != 0) {
          this.hasContent = true;
        }
      }
    })
    
  }

  validate(str: string): string {

    if (str === "patient perspective") {
      return "perspective"
    } else {
      return str
    }
  }

}