import { RequiredSection, SectionHistory, Report as ApiReport } from "../api/generated/graphql";

export class Report {
  id?: string;
  title?: string;
  keywords?: string;
  createdAt?: Date;
  updatedAt?: Date;
  sections?: RequiredSection[];
  owner?: {}

  constructor(rep: ApiReport) {
    Object.assign(this, rep);
    this.updatedAt = this.getLastUpdated(this);
  }

  createSectionHistories(sections: RequiredSection[]): SectionHistory[] {
    let arr: SectionHistory[] = [];
    if (!sections.length) {
      for (let i = 0; i < 8; i++) {
        let sh: SectionHistory = <SectionHistory>{
          id: "",
          heading: "",
          content: '{}',
          updatedAt: null,
          owner: {},
          createdAt: null,
        }

        arr.push(sh);
      }
    } else {
      arr = sections.map(s => {
        let sh: SectionHistory = <SectionHistory>{
          id: s.id,
          heading: s.heading,
          content: s.content,
          order: s.order,
          updatedAt: new Date(s.updatedAt),
          owner: {},
          createdAt: new Date(s.createdAt)
        }

        return sh;
      })
    }

    return arr;
  }

  getLastUpdated(report: Report): Date {
    let updates = [];
    updates.push(new Date(report.updatedAt).getTime());
    report.sections.map(sect => {
      updates.push(new Date(sect.updatedAt).getTime());
      updates = updates.sort((a, b) => a - b);
    })
    const lastUpdate: Date = updates.reverse()[0];
    return lastUpdate;
  }
}