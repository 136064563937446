<div *ngIf="error">
  <mat-card class="mat-elevation-z0 border">
    <h3 class="mat-title">
      {{'ERRORS.STANDARD' | translate}}
    </h3>
    <p *ngIf="error" class="mat-body">
      {{error}}
    </p>
    <button mat-raised-button (click)="reload()">
      Reload
    </button>
  </mat-card>
</div>




