<div class="container">
  <app-profile-card mat-list-item class="inner-container"></app-profile-card>
  <app-user-affiliations></app-user-affiliations>
  <br />
  <div class="settings">
    <h3>{{'SUBHEADINGS.USER SETTINGS' | translate}}</h3>
    <mat-expansion-panel class="mat-elevation-z0">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{'MISC.PASSWORD' | translate}}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div>
        <div>{{'SUBHEADINGS.UPDATE PASSWORD' | translate}}</div>
        <p>{{'MESSAGES.UPDATE PASSWORD' | translate}}</p>
        <mat-action-row><button mat-button routerLink="/logout" (click)="sendEvent()">{{'ACTIONS.LOG OUT' |
            translate}}</button></mat-action-row>
      </div>
    </mat-expansion-panel>
  </div>
</div>