<div class="header-container" *ngIf="report">
  <div class="title-container" [class.editing]="openTitleEditor" [ngClass]="{'case-padding': isCaseView}" (dblclick)="openTitleEditor = true">
    <ng-container *ngIf="!openTitleEditor; else editTitle">
      <button mat-icon-button class="btn-edit" [matMenuTriggerFor]="menu"><mat-icon>more_vert</mat-icon></button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="openTitleEditor = true; $event.stopPropagation();">{{'ACTIONS.EDIT TITLE' | translate}}</button>
      </mat-menu>
      <h1 class="mat-title title">{{report.title}}</h1>
      <div *ngIf="!report.title">
        <button (click)="openTitleEditor = true; $event.stopPropagation();" mat-button>{{'ADD A TITLE' | translate}}</button>
      </div>
    </ng-container>

    <ng-template #editTitle>
      <div (clickOutside)="openTitleEditor = false">
        <app-edit-title (closeTitleEditor)="openTitleEditor = false" [reportId]="report.id" [currentTitle]="report.title">
        </app-edit-title>
      </div>
    </ng-template>
  </div>
  <div class="title-container" [class.editing]="openKeywordsEditor" [ngClass]="{'case-padding': isCaseView}" (dblclick)="openKeywordsEditor = true">
    <ng-container *ngIf="!openKeywordsEditor; else editKeywords">
      <button mat-icon-button class="btn-edit" [matMenuTriggerFor]="menuKeywords">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menuKeywords="matMenu">
        <button mat-menu-item (click)="openKeywordsEditor = true; $event.stopPropagation();">{{'ACTIONS.EDIT KEYWORDS' | translate}}</button>
      </mat-menu>
      <span *ngIf="report.keywords" class="mat-body">{{'CASE SECTION.KEYWORDS' | translate}}: <span>{{report.keywords}}</span></span>
      <div *ngIf="!report.keywords">
        <button mat-button (click)="openKeywordsEditor = true; $event.stopPropagation();">{{'ACTIONS.ADD KEYWORDS' | translate}}</button>
      </div>
    </ng-container>

    <ng-template #editKeywords>
      <div (clickOutside)="openKeywordsEditor = false">
        <app-edit-keywords (closeKeywordsEditor)="openKeywordsEditor = false" [reportId]="report.id"
          [currentKeywords]="report.keywords"></app-edit-keywords>
      </div>
    </ng-template>
  </div>
</div>