import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ModalService } from 'src/app/services/modal.service';
import { AuthService } from '@auth0/auth0-angular';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { Report } from 'src/app/api/generated/graphql';
import { ReportService } from 'src/app/services/report/report.service';
import { SheetService } from 'src/app/services/sheet.service';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, takeUntil } from 'rxjs/operators';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';


@Component({
  selector: 'app-layout-sidenav-case',
  templateUrl: './layout-sidenav-case.component.html',
  styleUrls: ['./layout-sidenav-case.component.css'],
  providers: [ReportService],
})
export class LayoutSidenavCaseComponent implements OnInit, OnDestroy {

  casereport$: BehaviorSubject<Report> | null = null;
  reportID: string | null = null;
  user$: Observable<any> | null = null;
  isAuthenticated$: Observable<boolean>;
  isXSmall$: BehaviorSubject<boolean>;
  activateMediumLayout$: BehaviorSubject<boolean>;
  closeMenu$: BehaviorSubject<boolean>;
  navEndEvents: any;
  unsubscribe$: Subject<void> = new Subject<void>();
  userActivity: any;
  userInactive$: Subject<any> = new Subject();
  isWideView: boolean = false;
  error: any;

  constructor(
    private modalService: ModalService,
    private auth: AuthService,
    private reportService: ReportService,
    private activatedRoute: ActivatedRoute,
    private sheetService: SheetService,
    private router: Router,
    breakpointObserver: BreakpointObserver,
  ) {
    this.user$ = this.auth.user$;
    this.isAuthenticated$ = this.auth.isAuthenticated$;
    this.casereport$ = new BehaviorSubject(null);
    this.isXSmall$ = new BehaviorSubject(false);
    this.activateMediumLayout$ = new BehaviorSubject(false);
    this.closeMenu$ = new BehaviorSubject(false);
    this.setTimeout();
    this.userInactive$.subscribe(() => {
      this.unsubscribe$.next();
      this.unsubscribe$.complete();
      this.unsubscribe$.unsubscribe();
    })


    breakpointObserver.observe([
      Breakpoints.XSmall,
      Breakpoints.Small,
      Breakpoints.Medium,
    ]).pipe(takeUntil(this.unsubscribe$)).subscribe(result => {
      if (result.breakpoints[Breakpoints.XSmall] === true) {
        this.isXSmall$.next(true);
      } else {
        this.isXSmall$.next(false);
      }

      if (result.breakpoints[Breakpoints.Small] === true || result.breakpoints[Breakpoints.Medium] === true) {
        this.activateMediumLayout$.next(true);
      } else {
        this.activateMediumLayout$.next(false);
      }

      if (result.breakpoints[Breakpoints.XSmall] == true || result.breakpoints[Breakpoints.Small] === true) {
        this.closeMenu$.next(true);
      } else {
        this.closeMenu$.next(false);
      }


    });

  }

  ngOnInit(): void {
    this.activatedRoute.paramMap
      .subscribe(m => {
        const id = m.get("id");
        this.reportID = id;
        if (id) {
          this.startPolling(id);
        }
      })
    this.activatedRoute.url.subscribe(() => {
      this.isWideView = this.activatedRoute.snapshot.firstChild?.data['isWideView'];
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  downloadPDF(): void {
    this.modalService.openPDFDialog(this.reportID);
  }

  openRevisionModal(): void {
    this.modalService.saveRevisionDialog(this.reportID);
  }

  openSheet(name: string, report: Report): void {
    switch (name) {
      case 'share':
        this.sheetService.openShareSheet(report);
        break;
      case 'pdf':
        this.sheetService.openPDFSheet(report);
        break;
      case 'delete':
        this.sheetService.openDeleteSheet(report);
        break;
      default:
        break;
    }
  }

  startPolling(id: string): void {
    const obs = this.reportService.pollReport(id).pipe(
      catchError((err: any) => {
        let gqlError = err.graphQLErrors[0]?.message;
        let networkError = err.networkError;

        if (gqlError === "not a co-author") {
          this.router.navigate(['/pagenotfound'])
        } else if (gqlError === "record not found") {
          this.router.navigate(['/pagenotfound'])
        } else if (networkError?.statusText === "Unknown Error") {
          this.error = err;
        } else if (networkError?.statusText === "Login required") {
          this.auth.loginWithRedirect();
        } else {
          this.error = err;
        }
        return of(null);
      }));

    obs.pipe(takeUntil(this.unsubscribe$)).subscribe(this.casereport$);
  }


  setTimeout(): void {
    this.userActivity = setTimeout(() => this.userInactive$.next(undefined), 300000);
  }

  @HostListener('window:mousemove')
  @HostListener('window:keydown')
  @HostListener('window:mousewheel')
  @HostListener('window:click')
  refreshUserState() {
    if (this.unsubscribe$.closed) {
      this.unsubscribe$ = new Subject<any>();
      this.startPolling(this.reportID);
    }
    clearTimeout(this.userActivity);
    this.setTimeout();
  }

}
