<div class="flex-column help-menu">
  <ng-container *ngIf="(sections$ | async) as sections">
    <h1 class="mat-title">{{'SUBHEADINGS.CASE REPORT SECTIONS HELP' | translate}}</h1>
    <div class="row">
      <span class="mat-body s">{{'SUBHEADINGS.FIND INFO EXAMPLES' | translate}}</span>
      <select #selectedGroup (change)="selectedIndex = selectedGroup.value">
        <option *ngFor="let s of sections; let i = index" [value]="i"
          [selected]="selectedIndex === i">
          {{'CASE SECTION.'+s.fields.title | uppercase | translate}}
        </option>
      </select>
    </div>
    <mat-tab-group appHeaderLessTabs class="help-tab-group main" [selectedIndex]="selectedIndex">
      <mat-tab class="help-tab-labels" *ngFor="let s of sections; let i = index" label="{{'CASE SECTION.'+s.fields.title | uppercase| translate}}">
        <ng-template matTabContent>
          <div [@simpleFadeAnimation]="'in'">
            <span class="mat-title">
              {{'CASE SECTION.'+s.fields.title | uppercase| translate}}
            </span>
            <div class="section">
              <p class="mat-body"><span markdown>{{s.fields.summary}}</span></p>
            
              <ng-container *ngIf="s.fields.subsections.length != 0">
                <app-menu-subsections [ids]="s.fields.subsections"></app-menu-subsections>
              </ng-container>
            
              <ng-container *ngIf="s.fields.examples">
                <ng-container *ngFor="let h of s.fields.examples">
                  <app-menu-example [id]="h"></app-menu-example>
                </ng-container>
              </ng-container>
            </div>
          </div>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
   
  </ng-container>
</div>

<ng-template #loading>
  <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
</ng-template>