import { Injectable } from '@angular/core';
import { DeltaConvertService } from './delta-convert.service';
import { formatDate } from '@angular/common';
import { Affiliation, RequiredSection } from 'src/app/api/generated/graphql';
import { logo } from './logo';
import { GroupForTableDisplay } from 'src/app/classes/diagnostic';
import { ReportService } from '../report/report.service';
import { take, first } from 'rxjs/operators';
import { SortVisitsByDate } from 'src/app/components/visits/visit';
import { TranslateService } from '@ngx-translate/core';
import { Platform } from '@angular/cdk/platform';
import { TranslatorService } from '../translator.service';

declare let pdfMake: any;

const emptyOps: string = "\n";

@Injectable()
export class PdfgenService {

  deltaConvert: any;
  translate: any;

  strings: string[] = [
    "CASE SECTION.ABSTRACT",
    "CASE SECTION.INTRODUCTION",
    "CASE SECTION.CORRESPONDING AUTHOR",
    "CASE SECTION.KEYWORDS",
    "CASE SECTION.TIMELINE",
    "CASE SECTION.NARRATIVE",
    "CASE SECTION.DIAGNOSTICS",
    "CASE SECTION.PATIENT PERSPECTIVE",
    "CASE SECTION.DISCUSSION",
    "CASE SECTION.CONCLUSION",
    "CASE SECTION.ACKNOWLEDGEMENTS",
    "CASE SECTION.REFERENCES",
    "CASE SECTION.ATTACHMENTS",
    "CASE SECTION.AFFILIATIONS",
    "MISC.OF",
    "SUBHEADINGS.AUTHOR AFFILIATIONS",
    "MISC.TYPE",
    "MISC.DATE",
    "MISC.UNIT",
    "MISC.VALUE",
  ]

  constructor(
    private reportService: ReportService,
    private translatorService: TranslatorService,
    public platform: Platform,
    translate: TranslateService,
    deltaConvert: DeltaConvertService
  ) {
    this.deltaConvert = deltaConvert;
    this.translate = translate;
  }

  initGen(reportID: string) {
    const obs = this.translate.get(this.strings);
    obs.pipe(take(1)).subscribe({
      next: data => {
        this.strings = data;
      },
      complete: () => this.generatePDF(reportID)
    })
  }

  generatePDF(reportID: string) {

    const translation = this.translatorService.getTranslation(reportID);
    const obs = this.reportService.getReportPdf(reportID);

    translation.pipe(first()).subscribe(t => {
      obs.pipe(first()).subscribe((data: any) => {
        const report = data;
  
        let content: any[] = [];
        if (t.id && !t.deletedAt) {
          content.push(
            {
              text: t.title,
              fontSize: 18,
              margin: [0, 25, 0, 15],
              alignment: 'left',
              bold: true,
            },
          )
        }

        if (report.title) {
          content.push(
            {
              text: report.title,
              fontSize: 14,
              margin: [0, 0, 0, 15],
              alignment: 'left',
              bold: true,
            },
          )
        }
  
        // @TODO authors
        length = report.authors.length;
    
        let authorList = report.authors.map((a, i) => {
      
          if (i === length - 1) {
            return `${a.firstName} ${a.lastName}`
          } else {
            return `${a.firstName} ${a.lastName}, `
          }
      
        })
  
        if (authorList.length) {
          content.push(
            {
              text: authorList,
              fontSize: 10
            },
          )
        }
  
        const corrAuthor = `${report.corrAuthor.firstName} ${report.corrAuthor.lastName}, ${report.corrAuthor.email}`
  
        if (report.corrAuthor) {
          content.push(
            {
              text: [{ text: this.strings['CASE SECTION.CORRESPONDING AUTHOR']+': ', bold: true }, corrAuthor],
              margin: [0, 10, 0, 15],
              fontSize: 10
            },
          )
        }
  
        let abstract;

        if (report.sections[0].content != "{}") {
          if (JSON.parse(report.sections[0].content).ops[0].insert != emptyOps) {
            abstract = this.deltaConvert.genPDF(report.sections[0].content);
            let translatedContent;

            if (!t.deletedAt && t.abstract) {
              translatedContent = this.deltaConvert.genPDF(t.abstract);
              if (typeof abstract[0].text === 'string') {
                abstract[0].text = abstract[0].text + '\n\n' + translatedContent[0].text;
              } else if (typeof abstract[0].text === 'object') {
                abstract[0].text.push({ text: '\n\n' }, { text: translatedContent[0].text })
              }
            }

            
            content.push(
              {
                table: {
                  canvas: [
                    {
                      type: 'rect',
                      x: 0,
                      y: 0,
                      w: 285,
                      h: 130,
                      r: 5,
                      lineColor: 'black',
                    },
                  ],
                  headerRows: 0,
                  widths: ['*'],
                  body: [
                    [{ text: this.strings['CASE SECTION.ABSTRACT'], style: 'abstractSubheader', border: [true, true, true, false] }],
                    [{ stack: abstract, style: 'abstract', border: [true, false, true, false] }],
  
                    [{ text: [{ text: this.strings['CASE SECTION.KEYWORDS'] + ': ', bold: true, fontSize: 10 }, report.keywords || 'case report', (!t.deletedAt && t.keywords) ? '\n\n'+t.keywords : ''], margin: [10, 5, 10, 5], fontSize: 10, border: [true, false, true, true] }],
                  ]
                },
                layout: {
                  hLineColor: function (i, node) {
                    return '#9e1c1f';
                  },
                  vLineColor: function (i, node) {
                    return '#9e1c1f';
                  },
                },
                margin: [10, 5, 10, 15]
              },
            )
          }
        }
  
        this.pushQuillContent(content, report.sections[1])
  
        const visits = SortVisitsByDate(report.visits);
    
        let bodyV = <any>[];
      
        visits.map(v => {
          let date = new Date(v.date).toISOString();
          const format = 'yyyy-MM-dd';
          const locale = 'en-US';
          let drt = formatDate(date, format, locale);
          bodyV.push([{ text: drt, fontSize: 10, border: [true, true, false, true] }, { text: v.summary, fontSize: 10, border: [false, true, true, true] }]);
      
          return
        })
  
        if (bodyV.length > 0) {
          content.push(
            {
              text: this.strings['CASE SECTION.TIMELINE'],
              style: 'subheader'
            },
            {
              layout: {
                paddingTop: function (i, node, columnIndex) {
                  return 5
                },
                paddingBottom: function (i, node, columnIndex) {
                  return 5
                },
                paddingLeft: function (i) {
                  return 8;
                },
                paddingRight: function (i) {
                  return 8;
                },
                fillColor: function (i) {
                  return (i % 2 === 0) ? '#eeeeee' : '#fafafa';
                }
              },
              table: {
                headerRows: 0,
                widths: [60, 'auto'],
                body: bodyV,
              },
              margin: [10, 5, 10, 15]
            },
          )
        }
    
        this.pushQuillContent(content, report.sections[2])
  
        let body = [<any>[{
          text: this.strings['MISC.TYPE'],
          style: 'tableHead' },
          { text: this.strings['MISC.VALUE'], style: 'tableHead' },
          { text: this.strings['MISC.UNIT'], style: 'tableHead' }]];
    
        const diagnostics = GroupForTableDisplay(report.diagnostics);
        if (report.diagnostics.length > 0) {
          diagnostics.map((v, i) => {
            //check if header row
  
            if (!v.date) {
              console.log(v);
              let date = new Date(JSON.parse(v['dateHeader']));
              const format = 'yyyy-MM-dd';
              const locale = 'en-US';
              let drt = formatDate(date, format, locale, 'utc');
    
              body.push([{ text: drt, bold: true, fillColor: '#CCCCCC' }, { text: null, fillColor: '#CCCCCC' }, { text: null, fillColor: '#CCCCCC' }]);
              return
            }
    
            if (v.value && !v.alternateValue) {
              body.push([
                { text: v.type.name, style: 'tableRow' },
                { text: v.value, style: 'tableRow', alignment: 'center' },
                { text: v.unit.name || null, style: 'tableRow' },
              ])
            } else if (v.value && v.alternateValue) {
              body.push([
                { text: v.type.name, style: 'tableRow' },
                { text: v.value + "/" + v.alternateValue, style: 'tableRow', alignment: 'center' },
                { text: v.unit.name || null, style: 'tableRow' },
              ])
            } else if (v.defaultValue) {
              body.push([
                { text: v.type.name, style: 'tableRow' },
                { text: v.defaultValue, style: 'tableRow', alignment: 'center' },
                { text: v.unit.name || null, style: 'tableRow' },
              ])
            }
        
            return
          })
  
          if (body.length > 0) {
            content.push(
              {
                text: this.strings['CASE SECTION.DIAGNOSTICS'],
                style: 'subheader'
              },
              {
                table: {
                  headerRows: 1,
                  widths: ['*', 'auto', 130],
                  body: body
                },
                layout: {
                  fillColor: function (i, node, columnIndex) {
                    if (i === 0) return '#9e1c1f';
                    return null;
                  },
                  paddingTop: function (i, node, columnIndex) {
                    if (i === 0) return 10;
        
                    return 2
                  },
                  paddingBottom: function (i, node, columnIndex) {
                    if (i === 0) return 10;
        
                    return 2
                  },
                },
                margin: [10, 5, 30, 5],
              },
            )
          }
        }
  
        this.pushQuillContent(content, report.sections[3]);
        this.pushQuillContent(content, report.sections[7]);
        this.pushQuillContent(content, report.sections[4]);
        this.pushQuillContent(content, report.sections[5]);
        this.pushQuillContent(content, report.sections[6]);
  
  
        let affiliationsList = report.authors.map((item, i) => {
          let authorAffs = []
          if (!item.affiliations && !item.userAffiliations) {
            return authorAffs
          }
          
          authorAffs.push(`${item.firstName} ${item.lastName}, ${item.email}`)
          let affList;
  
          const data: [] = item.affiliations.length ? item.affiliations : item.userAffiliations;
          
          let len = data.length;
          if (len > 0) {
            affList = data.map((aff: Affiliation, i) => {
              let name = aff.name || '';
              let position = aff.position || '';
      
              if (i === len - 1) {
                return { text: `${name}, ${position}`, margin: [0, 0, 0, 5] };
              } else {
                return `${name}, ${position}`;
              }
            })
          }
      
          return authorAffs.concat(affList);
      })
  
        if (affiliationsList.length) {
          content.push(
            {
              text: this.strings['SUBHEADINGS.AUTHOR AFFILIATIONS'],
              style: 'subheader'
            },
            {
              ol: affiliationsList,
              style: 'affiliations'
            },
          )
        }
  
        // @TODO attachments
        
        let imgs = [];
  
        report.attachments.forEach(img => {
          imgs.push({
            image: 'data:image/jpeg;base64,' + img.fileBlob,
            width: 500,
          }, {
            table: {
              widths: [500],
              body: [
                [{ text: img.description || null, italics: true, fontSize: 10, alignment: 'left', fillColor: img.description ? '#F5F5F5' : null, margin: [5, 0, 5, 2] }],
                [" "],
              ],
            },
            layout: 'noBorders'
          })
        })
  
        if (imgs.length) {
          content.push(
            {
              text: this.strings['CASE SECTION.ATTACHMENTS'],
              style: 'subheader'
            },
            imgs
          )
        }
  
        let pageMargins: number[] = [55, 60, 55, 35]
  
        let docDefinition = {
          pageMargins: pageMargins,
          header: {
            columns: [
              {
                text: 'Case report generated using CARE-writer, care-writer.com',
                style: 'pageHeader'
              },
              {
                image: 'logo',
                width: 80,
              },
            ],
            style: 'header'
          },
          footer: function (currentPage, pageCount) {
            return { text: currentPage.toString() + ' of ' + pageCount, style: 'pageFooter' }
          },
          content: content,
          defaultStyle: {
            font: 'Roboto',
          },
          images: { logo: logo },
          styles: {
            pageHeader: {
              fontSize: 9
            },
            pageFooter: {
              fontSize: 9,
              margin: [25, 5, 25, 10]
            },
            header: {
              margin: [25, 10, 25, 5]
            },
            subheader: {
              bold: true,
              decoration: 'underline',
              fontSize: 12,
              margin: [0, 5, 0, 5]
            },
            abstractSubheader: {
              bold: true,
              decoration: 'underline',
              fontSize: 12,
              margin: [10, 10, 0, 10]
            },
            normal: {
              fontSize: 11,
              lineHeight: 1.5,
              margin: [0, 5, 30, 5],
            },
            abstract: {
              fontSize: 11,
              lineHeight: 1.3,
              margin: [10, 5, 10, 5]
            },
            affiliations: {
              fontSize: 10,
            },
            tableHead: {
              color: '#ffffff',
              fontSize: 12,
            },
            tableDateRow: {
              background: '#CCCCCC',
            },
            tableRow: {
              fontSize: 11
            },
          },
        }
  
        if (this.platform.SAFARI) {
          pdfMake.createPdf(docDefinition).download();
        } else {
          pdfMake.createPdf(docDefinition, null, pdfMake.fonts).open();
        }
      }) 
      
    })
  }

  pushQuillContent(content: any[], section: RequiredSection): any {
    let data: string = section.content;
    let tempContent = content;
    const heading = this.strings[`CASE SECTION.${section.heading.toUpperCase()}`]

    if (data != "{}") {
      if (JSON.parse(data).ops.length === 1 && JSON.parse(data).ops[0].insert == emptyOps) {
        return;
      }
      data = this.deltaConvert.genPDF(data)
      tempContent.push({
        text: heading,
        style: 'subheader',
      })
      content.push({ stack: data, style: 'normal' })

    }

    return content;
  }
}