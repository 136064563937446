<h1 class="aff-header">{{'SUBHEADINGS.MY AFFILIATIONS' | translate}}</h1>
<ng-container *ngIf="affiliations$ | async as affiliations">
  <ng-container *ngIf="affiliations.length; else addAffs">
    <div>
      <app-message-info content="{{'INFO.AFFILIATIONS' | translate}}" icon="info"></app-message-info>
    </div>
    <button class="btn-manage" mat-stroked-button (click)="openModal(affiliations)">{{'SUBHEADINGS.MANAGE AFFILIATIONS' | translate}}</button>
    <mat-list>
      <mat-list-item *ngFor="let a of affiliations; let i = index">
        <span>{{i+1}}.&nbsp;<strong>{{a.name}}:</strong> {{a.position}}</span>
      </mat-list-item>
    </mat-list>
  </ng-container>
  <ng-template #addAffs>
    <app-message-info content="{{'INFO.AFFILIATIONS' | translate}}" icon="info"></app-message-info>
    <div class="msg">
      <button mat-stroked-button (click)="openModal(affiliations)">{{'SUBHEADINGS.ADD AFFILIATIONS' | translate}}</button>
    </div>
  </ng-template>
</ng-container>
