
<app-error template="smallTemp" *ngIf="error" [error]="error" compOrigin="report-keywords"></app-error>

<mat-tab-group *ngIf="data.length != 0" class="help-tab-group" mat-align-tabs="start" id="tab-group">
  <mat-tab *ngFor="let el of data" [label]="el.fields.title">
    <ng-template matTabContent>
      <mat-card [@simpleFadeAnimation]="'in'" class="mat-elevation-z0">
        <span class="mat-title subsection-title">
          {{el.fields.title}}
        </span>
        <div class="section">
          <p class="mat-body"><span markdown>{{el.fields.summary}}</span></p>
          <ng-container *ngIf="el.fields.examples.length != 0">
            <ng-container *ngFor="let h of el.fields.examples" class="mat-body">
              <app-menu-example [id]="h"></app-menu-example>
            </ng-container>
          </ng-container>
        </div>
      </mat-card>
    </ng-template>
  </mat-tab>
</mat-tab-group>