<ng-container *ngIf="user">
  <form [formGroup]="profileForm" (ngSubmit)="onProfileSubmit(profileForm)">
    <div class="input-container">
      <label class="mat-body">{{'FORMS.GIVEN NAME' | translate}}</label>
      <input matLine formControlName="givenName">
    </div>
    <div class="input-container">
      <label class="mat-body">{{'FORMS.FAMILY NAME' | translate}}</label>
      <input matLine formControlName="familyName">
    </div>
    <div class="action-row">
      <button mat-button color="accent" type="submit">{{'ACTIONS.SAVE' | translate}}</button>&nbsp;&nbsp;
      <a *ngIf="!hideCancel" class="link mat-body" (click)="emitCloseRequest()">{{'ACTIONS.CANCEL' | translate}}</a>
    </div>
  </form>
</ng-container>