import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Affiliation } from 'src/app/api/generated/graphql';
import { AffiliationsService } from 'src/app/services/affiliations.service';
import { ModalService } from 'src/app/services/modal.service';

@Component({
  selector: 'app-user-affiliations',
  templateUrl: './user-affiliations.component.html',
  styleUrls: ['./user-affiliations.component.css']
})
export class UserAffiliationsComponent implements OnInit {

  affiliations$: Observable<Affiliation[]> | null = null;

  constructor(
    private userAffsService: AffiliationsService,
    private modalService: ModalService,
  ) {}

  ngOnInit(): void {
    this.affiliations$ = this.userAffsService.getMyAffiliations();
  }

  openModal(affs: Affiliation[]) {
    this.modalService.openUpdateMyAffilitions(affs);
  }

}
