<ng-container *ngIf="user; else loadingView">
  <mat-card *ngIf="user.email">
    <mat-card-header>
      <div mat-card-avatar style="background-image: url({{user.picture}}); background-size: cover;"></div>
      <mat-progress-spinner *ngIf="loading" mode="indeterminate" diameter="20"></mat-progress-spinner>
      <mat-card-title>{{user.name}}</mat-card-title>
      <mat-card-subtitle>{{user.email}}</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content style="min-height: 100%;">
      <app-updateprofile *ngIf="editMode" [user]="user" (updateUser)="getUserProfile()" (closeProfile)="editMode = false"></app-updateprofile>
    </mat-card-content>
    <mat-card-actions *ngIf="!editMode">
      <button type="button" mat-button (click)="editMode = true">{{'ACTIONS.MANAGE PROFILE' | translate}}</button>
    </mat-card-actions>
  </mat-card>
</ng-container>

<ng-template #loadingView>
  <mat-progress-bar class="loading" mode="indeterminate"></mat-progress-bar>
</ng-template>  