import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from '@auth0/auth0-angular';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { first, take } from 'rxjs/operators';
import { UpdateUserProfileGQL, UserProfileDocument } from '../../api/generated/graphql';

interface DialogData {
  user: Observable<any>
  redirect_url: string
}

@Component({
  selector: 'profile-dialog',
  templateUrl: 'profile-dialog.component.html',
})
export class ProfileDialogComponent implements OnInit {

  error: any;
  showMessage: boolean = false;
  firstName: string = "";
  lastName: string = "";
  name: string = "";
  lang: string = "";
  email: string = "";
  emailVerified: boolean = false;
  user: any;
  editUser: boolean = false;
  loading: boolean = true;
  updateRequired: boolean = false;

  constructor(
    private updateUserGQL: UpdateUserProfileGQL,
    private auth: AuthService,
    public dialogRef: MatDialogRef<ProfileDialogComponent>,
    public dialog: MatDialog,
    private translate: TranslateService,
    private _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  ngOnInit(): void {
      this.data.user.pipe(first()).subscribe( u => {
        this.user = u;
        this.firstName = u.givenName;
        this.lastName = u.familyName;
        this.name = u.name;
        this.email = u.email;
        this.emailVerified = u.email_verified;
        this.lang = u.usermeta.language;

        if (!this.firstName || !this.lastName) {
          this.updateRequired = true;
        }

        this.loading = false;
      })
  }

  closeDialog(): void {
    this.dialog.closeAll()
  }

  confirm(): void {
    this.loading = true;
    
    const user = this.user;
    const first = user.givenName
    const last = user.familyName
    const drip = user.usermeta.addedToDrip || false
    const language = this.translate.currentLang

      this.updateUserGQL
        .mutate({
          userID: this.user.id,
          givenName: first,
          familyName: last,
          name: first + " " + last,
          lang: language,
          completedProfile: true,
          addedToDrip: drip,
        }, {
          refetchQueries: [{ query: UserProfileDocument }]
        })
        .subscribe({
          error: err => {
            this.error = err;
            console.error(err);
          },
          complete: () => {
            this.updateAndRoute()
          }
        }
        );

  }

  onSuccess() {

    this.translate.get(['SNACKS.PROFILE UPDATED', 'SNACKS.DISMISS']).pipe(first()).subscribe((res: any) => {

      this._snackBar.open(res['SNACKS.PROFILE UPDATED'], res['SNACKS.DISMISS'], {
        duration: 3000,
      });

    })
  }

  updateAndRoute() {
    this.auth.loginWithRedirect({
      appState: {
        target: this.data.redirect_url
      },
    })
  }
    
}
