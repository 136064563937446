import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { User } from '../../api/generated/graphql';
import { ProfileService } from '../../services/profile/profile.service';

@Component({
  selector: 'app-profile-card',
  templateUrl: './profile-card.component.html',
  styleUrls: ['./profile-card.component.css']
})
export class ProfileCardComponent implements OnInit, OnDestroy {

  user!: User;
  loading: boolean = true;
  unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    public profileService: ProfileService,
  ) { }

  ngOnInit() {
    this.getUserProfile();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getUserProfile() {
    const obs = this.profileService.getProfile();
    
    obs
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((profile) => {
      this.loading = false;
      this.user = profile;
    })
  }

}

