import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DiagnosticsService } from 'src/app/services/diagnostics.service';
import { Diagnostic, GroupForTableDisplay } from '../../../classes/diagnostic';

@Component({
  selector: 'app-view-diagnostics',
  templateUrl: './view-diagnostics.component.html',
  styleUrls: ['./view-diagnostics.component.css']
})
export class ViewDiagnosticsComponent implements OnInit, OnDestroy {

  displayedColumns: string[] = ['type', 'value', 'unit'];
  dataSource$: BehaviorSubject<Diagnostic[]>;
  currentIndex: number = 0;
  @Input() reportID: string = '';
  @Input() isPreview: boolean = true;
  loading: boolean = true;
  unsubscribe$: Subject<void>;

  constructor(
    private diagnosticsService: DiagnosticsService,
    private router: Router,
  ) {
    this.unsubscribe$ = new Subject<void>();
    this.dataSource$ = new BehaviorSubject(null);
  }

  ngOnInit() {
    if (this.reportID) {

      const url = this.router.url;
      let segments: string[] = [];
      segments = url.split('/');
      segments = segments.filter(s => s != '');
      const isShareView = segments[0] === 'share' ? true : false;
      
      
      if (!isShareView) {
        this.diagnosticsService.getDiagnostics(this.reportID).pipe(takeUntil(this.unsubscribe$)).subscribe({
          next: (data) => {
            const sortedItems = GroupForTableDisplay(data);
            if (data.length) {
              this.dataSource$.next(sortedItems)
            } else {
              this.dataSource$.next(null)
            }
          }
        })
      } else {
        this.diagnosticsService.getPublicDiagnostics(this.reportID).pipe(takeUntil(this.unsubscribe$)).subscribe({
          next: (data) => {
            const sortedItems = GroupForTableDisplay(data);
            if (data.length) {
              this.dataSource$.next(sortedItems)
            } else {
              this.dataSource$.next(null)
            }
          }
        })
      }
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  isDateHeader(index, item): boolean {
    return item.isDateHeader;
  }

}
