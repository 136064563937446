import { Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { takeUntil, take } from 'rxjs/operators';
import { ReportService } from 'src/app/services/report/report.service';
import Quill from 'quill';

@Component({
  selector: 'app-quill-share',
  templateUrl: './quill-share.component.html',
  styleUrls: ['./quill-share.component.css']
})
export class QuillShareComponent implements OnInit {

  @Input() sectionID: string = '';
  @Input() reportID: string = '';
  @Input() altID: boolean = false;
  @Input() isPreviewView: boolean = false;
  heading: string = '';
  hasContent: boolean = false;

  @ViewChild('share', { read: ElementRef, static: true })
  container!: ElementRef
  quill: any;
  content: string = '';
  unsubscribe$: Subject<void> = new Subject<void>();
  content$: BehaviorSubject<any>;

  constructor(
    private renderer: Renderer2,
    private reportService: ReportService,
  ) {
    this.content$ = new BehaviorSubject(null);
  }

  ngOnInit() {
    if (this.sectionID && this.reportID) {
      const obs = this.reportService.getPublicSectionsAsObservable();
      obs.pipe(takeUntil(this.unsubscribe$)).subscribe({
        next: (sections) => {
          if (!sections.length) return;
          let section: any;
          section = sections.filter(s => s.id === this.sectionID)[0];
          if (section.content) {
            this.content$.next(section.content)
            this.heading = section.heading
          }
          let divID = "r" + this.sectionID.substring(0, 7);
          if (this.altID) {
            divID = divID + "alt"
          }
          this.createEditor(divID);
          this.initEditor(divID);
        }
      })
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  createEditor(divID: string) {
    const div = this.renderer.createElement('div');
    this.renderer.setProperty(div, "id", divID);
    this.renderer.appendChild(this.container.nativeElement, div);
  }

  initEditor(divID: string) {
    var options = {
      placeholder: '',
      readOnly: true,
      theme: 'bubble',
      modules: {
        toolbar: false,
      }
    };

    const name = "#" + divID;
    this.quill = new Quill(name, options);
    this.content$.pipe(take(1)).subscribe({
      next: (data) => {
        this.quill.setContents(JSON.parse(data));
      },
      complete: () => {
        if (this.quill.getText().trim().length != 0) {
          this.hasContent = true;
        }
      }
    })

  }

  validate(str: string): string {

    if (str === "patient perspective") {
      return "perspective"
    } else {
      return str
    }
  }
}
