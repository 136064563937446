import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

declare let gtag: Function;

@Component({
  selector: 'app-user-toolbar',
  templateUrl: './user-toolbar.component.html',
  styleUrls: ['./user-toolbar.component.css']
})
export class UserToolbarComponent implements OnInit {

  @Input() isAuthenticated$: Observable<boolean> = new Observable();
  @Input() isHandset$: BehaviorSubject<boolean>;
  
  constructor() {
    this.isHandset$ = new BehaviorSubject(false);
   }

  ngOnInit(): void {
  }

  sendEvent(): void {
    gtag('event', 'logout', {
      'event_category': 'auth',
      'event_label': 'method'
    });
  }

}
