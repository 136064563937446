import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-case-navigation',
  templateUrl: './case-navigation.component.html',
  styleUrls: ['./case-navigation.component.css']
})
export class CaseNavigationComponent implements OnInit, OnDestroy {
  @Input() reportID: string = '';
  @Input() enableAltView: boolean = false;
  activePath: string = 'write';
  activateMediumLayout$: BehaviorSubject<boolean>;
  unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    breakpointObserver: BreakpointObserver,
  ) {

    this.activateMediumLayout$ = new BehaviorSubject(false);

    breakpointObserver.observe([
      Breakpoints.Medium,
      Breakpoints.Small,
    ]).pipe(takeUntil(this.unsubscribe$)).subscribe(result => {
      if (result.matches) {
        this.activateMediumLayout$.next(true);
        return;
      }

      this.activateMediumLayout$.next(false);

    });
  }

  ngOnInit(): void {
    const routePath = this.route.snapshot.url[0];
    
    if (routePath) {
      this.activePath = routePath.path;
    }
    
  }
  
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
