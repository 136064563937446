import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appHeaderLessTabs]'
})
export class HeaderLessTabsDirective {

  constructor(private eleRef: ElementRef) { }

  ngOnInit(): void {
    this.eleRef.nativeElement.children[0].style.display = "none";
  }

}

