import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { first } from 'rxjs/operators';
import { ReportService } from 'src/app/services/report/report.service';

@Component({
  selector: 'app-add-user-to-group',
  templateUrl: './add-user-to-group.component.html',
  styleUrls: ['./add-user-to-group.component.css']
})
export class AddUserToGroupComponent implements OnInit, OnDestroy {
  @Input() reportID: string = '';
  userEmailControl?: FormControl;
  error: any;
  unsubscribe$: Subject<void> = new Subject<void>();
  sentInvites: string[] = [];
  
  constructor(
    private _snackBar: MatSnackBar,
    private translate: TranslateService,
    private reportService: ReportService,
  ) { }

  ngOnInit(): void {
    this.userEmailControl = new FormControl('', { validators: [Validators.required, Validators.email], updateOn: 'submit'})
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  sendInvitation(email: string): any {
    this.error = null;

    this.userEmailControl.setValue(email);
    if (this.userEmailControl.invalid) {
      return false;
    }
    const obs = this.reportService.sendInvitation(this.reportID, email);

    obs.pipe(first()).subscribe({
      next: (data) => {
        this.onSuccess();
        this.sentInvites.push(email)
      },
      error: (err: any) => {
        console.error(err);
        this.error = err;
      },
      complete: () => this.userEmailControl = new FormControl('', Validators.required)
    })
  }

  onSuccess() {
    this.translate.get(['SNACKS.INVITATION SENT', 'SNACKS.DISMISS']).pipe(first()).subscribe((res: any) => {

      this._snackBar.open(res['SNACKS.INVITATION SENT'], res['SNACKS.DISMISS'], {
        duration: 3000,
      });
    });
  }

}
