<ng-container *ngIf="author && reportId">
  <ng-container *ngIf="!editMode; else updateCorrAuthor">
    <div class="content-section" (dblclick)="editMode = true">
      <strong class="mat-body">{{'CASE SECTION.CORRESPONDING AUTHOR' | translate}}: </strong>
      <span *ngIf="author.user.id; else userTmp" class="mat-body">{{author.user.givenName}} {{author.user.familyName}}, {{author.user.email}}</span>
      <ng-template #userTmp>
        <span class="mat-body">{{author.firstName}} {{author.lastName}}, {{author.email}}</span>
      </ng-template>

      <button class="edit-menu" mat-icon-button [matMenuTriggerFor]="menuSection">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menuSection="matMenu">
        <button mat-menu-item (click)="editMode = true; $event.stopPropagation();">{{'ACTIONS.CHANGE' | translate}}</button>
      </mat-menu>

    </div>
  </ng-container>
  <ng-template #updateCorrAuthor>
    <div class="editable-section" (clickOutside)="editMode = false">
      <mat-icon color="accent">chevron_right</mat-icon><span class="mat-body">{{'MESSAGES.SELECT CORR AUTHOR' | translate}}</span>&nbsp;
      <select #corrAuthor (change)="selectAuthor(corrAuthor.value)">
        <option value=""> {{'MESSAGES.SELECT AUTHOR' | translate}} </option>
        <option *ngFor="let a of (allAuthors | async)" [value]="a.id">
          <span *ngIf="a.user.id; else userTmp2">{{ a.user.givenName }} {{a.user.familyName}}</span>
          <ng-template #userTmp2>
            <span>{{ a.firstName }} {{a.lastName}}</span>
          </ng-template>
        </option>
      </select>
    </div>
  </ng-template>
</ng-container>