import { ErrorHandler } from '@angular/core';
import { HttpErrorResponse } from "@angular/common/http";
import LogRocket from "logrocket";

class LogRocketErrorHandler implements ErrorHandler {
  handleError(error: Error | HttpErrorResponse): void {
    if (error instanceof HttpErrorResponse) {
      LogRocket.error(error);
    } else {
      LogRocket.captureException(error);
    }
  }
}

export default LogRocketErrorHandler