import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserProfileDocument, UpdateUserProfileGQL } from "../../api/generated/graphql";
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-updateprofile',
  templateUrl: './updateprofile.component.html',
  styleUrls: ['./updateprofile.component.css']
})
export class UpdateprofileComponent implements OnInit {
  profileForm!: FormGroup;
  
  @Input() user: any;
  @Input() hideCancel: boolean = false;
  @Output() closeProfile = new EventEmitter();
  @Output() updateUser = new EventEmitter();

  error: any;

  constructor(
    private updateUserGQL: UpdateUserProfileGQL,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    if (this.user) {
      this.profileForm = new FormGroup({
        givenName: new FormControl(this.user.givenName, [Validators.required]),
        familyName: new FormControl(this.user.familyName, [Validators.required])
      })
    }
  }

  validator(form: FormGroup) {
    if (form.invalid) {
      return false;
    }

    return true;
  }

  get givenName() { return this.profileForm.get('givenName'); }
  get familyName() { return this.profileForm.get('familyName'); }

  onProfileSubmit(form: FormGroup) {
    const valid = this.validator(form)
    const name = form.controls.givenName.value.trim() + " " + form.controls.familyName.value.trim();
    const language = this.translate.currentLang
    const drip = this.user.usermeta.addedToDrip || false
    
    if (valid) {

      this.updateUserGQL
        .mutate({
          userID: this.user.id,
          familyName: form.controls.familyName.value.trim(),
          givenName: form.controls.givenName.value.trim(),
          name: name,
          lang: language,
          completedProfile: true,
          addedToDrip: drip,
        }, {
          refetchQueries: [{ query: UserProfileDocument }]
        })
        .subscribe({
          next: (response: any) => {
            this.user.family_name = response.data.updateUser.familyName;
            this.user.given_name = response.data.updateUser.givenName;
            this.updateUser.emit()
          },
          error: (err: any) => {
            this.error = err;
            console.error(err);
          },
          complete: () => this.emitCloseRequest()
        }
        );
    }
  }

  emitCloseRequest() {
    this.closeProfile.emit()
  }

}