import { Component } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { ModalService } from 'src/app/services/modal.service';
import { ValidateUserService } from '../../services/validate-user.service';

@Component({
  selector: 'app-new-case',
  templateUrl: './new-case.component.html',
  styleUrls: ['./new-case.component.css'],
})
export class NewCaseComponent {
  user$: Observable<any>;

  constructor(
    private auth: AuthService,
    private validateUserService: ValidateUserService,
    private modalService: ModalService,
  ) {
    this.user$ = this.auth.user$;
    this.user$.pipe(first()).subscribe({
      next: (u) => {
        const isValid = this.validateUserService.validateProfile(u);
        if (!isValid) {
          this.modalService.openConfirmProfile("/reports/new");
        }
      }
    })
  }
}
