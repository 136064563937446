import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Author } from '../authors/author';

@Component({
  selector: 'app-affiliations-expansion',
  templateUrl: './affiliations-expansion.component.html',
  styleUrls: ['./affiliations-expansion.component.css']
})
export class AffiliationsExpansionComponent implements OnInit {
  show: boolean = false;
  @Input() authors: Author[] = [];
  @Input() reportID: string = '';
  
  constructor(
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
  }

  toggleShow() {
    this.show = !this.show;
  }

}
