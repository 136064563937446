<app-error *ngIf="error" [error]="error"></app-error>

<div class="section-header">
  <h3 class="mat-title">{{'PLURALS.CASE REPORTS' | translate}}</h3>
  <button mat-stroked-button routerLink="/reports/new">{{'NAVIGATION.NEW CASE' | translate}}</button>
</div>
<ng-container *ngIf="mergedReports$ | async as reports; else loadingDocs">
  <ng-container *ngIf="reports.length; else newReport">
    <app-published-cases [reports]="reports"></app-published-cases>
  </ng-container>
  <ng-template #newReport>
    <div class="column">
      <div class="inner">
        <button mat-fab color="link" routerLink="/reports/new"><mat-icon>add</mat-icon></button>
        <div class="spacer"></div>
        <span>{{'FORMS.CREATE CASE' | translate}}</span>
      </div>
    </div>
  </ng-template>
</ng-container>


<ng-template #loadingDocs>
  <mat-list class="left-spinner">
    <mat-list-item>
      <mat-spinner diameter="20"></mat-spinner>
    </mat-list-item>
  </mat-list>
</ng-template>