import { Component, Input } from '@angular/core';
import { AllDiagnosticsDocument, DeleteDiagnosticGQL } from 'src/app/api/generated/graphql';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';

declare let gtag: Function;

@Component({
  selector: 'app-delete-diagnostic',
  templateUrl: './delete-diagnostic.component.html',
  styleUrls: ['./delete-diagnostic.component.css']
})
export class DeleteDiagnosticComponent {

  @Input() obsId: string = '';
  @Input() reportId: string = '';
  error: any;

  constructor(
    private _snackBar: MatSnackBar,
    private translate: TranslateService,
    private deleteDiagnosticGQL: DeleteDiagnosticGQL) { }

  delete() {

    this.deleteDiagnosticGQL
      .mutate({
        id: this.obsId
      }, {
        refetchQueries: [{ query: AllDiagnosticsDocument, variables: { id: this.reportId } }]
      })
      .subscribe({
        error: err => {
          this.error = err;
          console.error(err);
        },
        complete: () => this.onSuccess()
      }
      );
  }

  onSuccess() {
    this.sendEvent();

    this.translate.get(['SNACKS.DIAGNOSTIC DELETED', 'SNACKS.DISMISS']).pipe(first()).subscribe((res: any) => {
      this._snackBar.open(res['SNACKS.DIAGNOSTIC DELETED'], res['SNACKS.DISMISS'], {
        duration: 3000,
      });
    })
  }

  sendEvent(): void {
    gtag('event', 'delete_diagnostic', {
      'event_category': 'db-delete',
      'event_label': 'method'
    });
  }

}
