import { Unit } from '../components/diagnostics/unit';
import { Type } from '../components/diagnostics/type';
import { Diagnostic as ApiDiagnostic } from 'src/app/api/generated/graphql';

export class Diagnostic {
  id: string;
  type: Type;
  value: number;
  alternateValue: number;
  defaultValue: string;
  unit: Unit | null;
  date: Date | number;

  
  constructor(diag: ApiDiagnostic) {
    Object.assign(this, diag);
    // Since our server expects UTC date strings when creating a Diagnostic, it returns the date as a UTC string. We need to make sure it is UTC in order to display it correctly, regardless of timezone.
    let date: any = diag.date.match(/\d{4}-\d{2}-\d{2}/g).toString();
    date = new Date(date);
    date = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate())
    this.date = date;
  }
}

/**
 * GroupForTableDisplay take in the diagnostic
 * from the api and converts them into the Diagnostic()
 * before grouping them for display in a table
 * @param  data ApiDiagnostic[]
 * @returns grouped Diagnostic[] by UTC date
 */
export const GroupForTableDisplay = (data: ApiDiagnostic[]): Diagnostic[] => {
  let arr: Diagnostic[] = [];

  arr = data.map(element => {
    return new Diagnostic(element);
  })

  arr = SortDiagnosticsByDate(arr);

  let groupedData = arr.reduce((result, currentValue) => {
    let current: number = (<number>currentValue.date);
    (result[current] = result[current] || []).push(
      currentValue
    );
    return result;
  }, []);

  let newArray: any[] = [];

  for (const prop in groupedData) {
    newArray.push({dateHeader: prop, isDateHeader: true});
    let data = groupedData[prop];
    data.sort((a: { type: { name: string; }; }, b: { type: { name: string; }; }) => {
      return a.type.name.toLowerCase().localeCompare(b.type.name.toLowerCase());
    })

    newArray.push(...data);
  }

  return newArray;
}

/**
 * 
 * @param data Diagnostic[]
 * @returns sorted data Diagnostic[]
 */
const SortDiagnosticsByDate = (data: Diagnostic[]): Diagnostic[] => {
  return data.sort((c1, c2) => {
    return (<number>c1.date) - (<number>c2.date);
  })
}