import { Component, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { Report } from 'src/app/api/generated/graphql';

@Component({
  selector: 'app-share-bottom-sheet',
  templateUrl: './share-bottom-sheet.component.html',
  styleUrls: ['./share-bottom-sheet.component.css']
})
export class ShareBottomSheetComponent {

  report: Report | null = null;

  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: { report: Report }) {
    this.report = data.report;
  }

}
