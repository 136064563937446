import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { ReportService } from 'src/app/services/report/report.service';
import { Location } from '@angular/common';
import { Report } from 'src/app/api/generated/graphql';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {

  reportId!: string;
  report: Report;

  constructor(
    private reportService: ReportService,
    private location: Location,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    if (this.route.parent) {
      this.route.parent.paramMap
        .subscribe(m => {
          this.reportId = m.get("id")
          if (this.reportId) {
            const obs = this.reportService.getReport(this.reportId).pipe(first());
            obs.subscribe({
              next: (data) => {
                this.report = data;
              }
            })
          }
        })
    }
  }

  goBack() {
    this.location.back()
  }

}

