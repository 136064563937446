import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { first, take } from 'rxjs/operators';
import { IsPublicGQL, Report as ApiReport } from 'src/app/api/generated/graphql';
import { Report } from 'src/app/classes/report';
import { MysavedreportsService } from 'src/app/services/mysavedreports.service';
import { ReportService } from 'src/app/services/report/report.service';

@Component({
  selector: 'app-share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.css']
})
export class ShareComponent implements OnInit {
  isAuthenticated$: Observable<boolean>;
  reportID: string = '';
  casereport$: Observable<Report> | null = null;
  error: any;
  isStarred$ = new BehaviorSubject(null);
  isPublic: boolean | null = null;
  loading: boolean = true;
  showLogin: boolean = false;
  lastUpdate: Date;

  constructor(
    private reportService: ReportService,
    private starService: MysavedreportsService,
    private route: ActivatedRoute,
    private isPublicGQL: IsPublicGQL,
    private translate: TranslateService,
    private _snackBar: MatSnackBar,
    private auth: AuthService
  ) {
    this.isAuthenticated$ = this.auth.isAuthenticated$;
  }

  ngOnInit(): void {
    this.route.paramMap
      .pipe(first())
      .subscribe(m => {
        const id = m.get("id")
        if (id) {
          this.reportID = id;
          this.isAuthenticated$.pipe(first()).subscribe((authed: boolean) => {
            if (authed) {
              this.showLogin = false;
                const val = this.checkPublicStatus(this.reportID)
                val.pipe(first()).subscribe(data => {
                  if (data.data.isPublic) {
                    this.isPublic = true;
                    this.loading = false;
                    this.starService.isReportStarred(this.reportID).pipe(first()).subscribe(data => {
                      this.isStarred$.next(data.data.isStarred)
                    })
                    this.casereport$ = this.reportService.getPublicReport(id);
                    this.reportService.getLastUpdateAsObservable().subscribe((last: Date) => {
                      this.lastUpdate = last;
                    })
                  } else {
                    this.loading = false;
                    this.isPublic = false;
                  }
                })

              } else {
                this.loading = false;
                this.showLogin = true;
              }
            })
          }
        })
  }

  toggleStar(reportID: string): void {

    const val = this.isStarred$.pipe(
      first()
    )

    val.subscribe(v => {
      if (!v) {
        this.starService.createNewStar(reportID).subscribe({
          next: () => {
            this.isStarred$.next(true)
            this.isStarred$.unsubscribe
          },
          error: err => {
            this.error = err;
            console.log(err);
          }
        })
      } else {
        this.starService.removeStar(reportID).subscribe({
          next: () => {
            this.isStarred$.next(false)
            this.isStarred$.unsubscribe
          },
          error: err => {
            this.error = err;
            console.log(err);
          }
        })
      }
    })

  }

  checkPublicStatus(reportID: string): Observable<any> {
    return this.isPublicGQL.fetch({ id: reportID })
      .pipe(
        first()
      )
  }

  confirmCopy(event: boolean) {
    if (event == true) {
      this.translate.get(['SNACKS.COPIED EMAIL', 'SNACKS.DISMISS']).pipe(first()).subscribe((res: string) => {

        this._snackBar.open(res['SNACKS.COPIED EMAIL'], res['SNACKS.DISMISS'], {
          duration: 2000,
        });

      })
    }
  }

  


}
