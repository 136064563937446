<div class="container">
  <button mat-icon-button disableRipple class="btn-close" (click)="closeDialog()">
    <mat-icon>close</mat-icon>
  </button>
  <app-message-info *ngIf="!showMessage" content="{{'MESSAGES.PUBLIC REPORT' | translate}}" learnMoreLink="https://care-writer.com/terms-of-service" icon="announcement"></app-message-info>
    
  <div *ngIf="showMessage">
    <div style="text-align: center;" mat-dialog-title>{{ 'MESSAGES.PREP DOCUMENT' | translate }}</div>
    <p style="text-align: center;" class="mat-body">{{'MESSAGES.PLEASE WAIT' | translate}}</p>
  </div>
</div>
<div class="row">
  <button *ngIf="!showMessage" mat-button (click)="closeDialog()">{{'ACTIONS.CANCEL' | translate}}</button>&nbsp;
  <button *ngIf="!showMessage" mat-stroked-button (click)="generatePDF()">{{'ACTIONS.AGREE CONTINUE' | translate}}</button>&nbsp;
</div>