import { Component, OnInit, Input } from '@angular/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { Report, UpdatePublicStatusGQL } from 'src/app/api/generated/graphql';

declare let gtag: Function;

@Component({
  selector: 'app-share-toggle',
  templateUrl: './share-toggle.component.html',
  styleUrls: ['./share-toggle.component.css']
})
export class ShareToggleComponent implements OnInit {

  @Input() report!: Report;
  checked: boolean = false;
  rootUrl: string = "https://app.care-writer.com/share/";
  error: any;

  horizontalPosition: MatSnackBarHorizontalPosition = 'end';
  verticalPosition: MatSnackBarVerticalPosition = 'top';


  constructor(
    private updatePublicStatusGQL: UpdatePublicStatusGQL,
    private _snackBar: MatSnackBar,
    private translate: TranslateService,
  ) { }

  ngOnInit() {
    if (this.report) {
      this.checked = this.report.isPublic
    }
  }

  onCheckPublic() {

    const val = this.checked ? 'on' : 'off';
    
    gtag('event', val, {
      'event_category': 'share-toggle',
      'event_label': 'method'
    });

    this.updatePublicStatusGQL
      .mutate({
        reportId: this.report.id,
        isPublic: this.checked,
      })
      .pipe(take(1))
      .subscribe({
        error: err => {
          this.error = err;
          console.error(err);
        },
        complete: () => this.openSnackBar(val)
      });
  }

  openSnackBar(message: string | null) {
    let obs: Observable<any>;

    if (message === 'on') {
      obs = this.translate.get('MESSAGES.SHARING ON');
    } else if (message === 'off') {
      obs = this.translate.get('MESSAGES.SHARING OFF');
    } else {
      obs = this.translate.get('MESSAGES.LINK COPIED');
    }

    obs.pipe(take(1)).subscribe((res: string) => {
      this._snackBar.open(res, null, {
        duration: 700,
        panelClass: 'small-snack',
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
    })

  }

}
