import { Component, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RevisionsService } from 'src/app/services/revisions.service';
import { SnackService } from 'src/app/services/snack.service';

@Component({
  selector: 'app-save-revision-dialog',
  templateUrl: './save-revision-dialog.component.html',
  styleUrls: ['./save-revision-dialog.component.css']
})
export class SaveRevisionDialogComponent {

  name: FormControl;

  constructor(
    private revisionService: RevisionsService,
    public dialogRef: MatDialogRef<SaveRevisionDialogComponent>,
    public dialog: MatDialog,
    private snacks: SnackService,
    @Inject(MAT_DIALOG_DATA) public data: string
  ) {
    this.name = new FormControl("")
  }

  saveRevision(): void {
    this.revisionService.saveRevision(this.data, this.name.value)
      .subscribe({
        complete: () => {
          this.snacks.generateSnack('VERSIONS.SAVED');
          this.dialog.closeAll()
        }
      })
  }

}
