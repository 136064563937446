<div class="container">
  <ng-container *ngIf="starred$ | async as starred">
    <ng-container *ngIf="starred.length;">
      <mat-card class="saved-report" *ngFor="let rep of starred; let i = index">
        <h3 class="mat-title">{{rep.title}}</h3>
        <mat-card-content>
          <div fxLayout="column" fxLayoutAlign="start start">
            <div>
              <div class="mat-small">{{'MESSAGES.UPDATED' | translate}}: {{rep.updatedAt | date:'yyyy-MM-dd, h:mm a'}}</div>
              <span class="mat-small">{{'CASE SECTION.AUTHORS' | translate}}:</span>
              <span *ngFor="let author of rep.authors; last as isLast; let i = index">
                <span *ngIf="author.user.id; else userTmp" class="mat-small">
                  {{ author.user.givenName }}&nbsp;{{ author.user.familyName }}<span *ngIf="!isLast">,</span>
                </span>
                <ng-template #userTmp>
                  <span class="mat-small">
                    {{ author.firstName }}&nbsp;{{ author.lastName }}<span *ngIf="!isLast">,</span>
                  </span>
                </ng-template>
              </span>
            </div>
          </div>
        </mat-card-content>

        <mat-card-actions>
          <button mat-stroked-button [routerLink]="'/public/'+rep.id" (click)="sendEvent()">{{'ACTIONS.VIEW' | translate}}</button>
          <button matTooltip="{{'ACTIONS.REMOVE SAVED CASE' | translate}}" mat-button [matMenuTriggerFor]="menuR" aria-hidden="false"
            aria-label="Remove saved report">
            {{'ACTIONS.REMOVE' | translate}}
          </button>
        
          <mat-menu #menuR="matMenu">
            <button class="btn-delete" mat-menu-item (click)="removeSavedReport(rep.id)">
              <mat-icon class="default-icon" aria-hidden="false" aria-label="Remove saved report">
                remove_circle_outline
              </mat-icon>
              <span>{{'ACTIONS.REMOVE' | translate}}</span>
            </button>
            <button mat-menu-item>
              <mat-icon class="default-icon" aria-hidden="false" aria-label="Cancel">cancel</mat-icon>
              <span>{{'ACTIONS.CANCEL' | translate}}</span>
            </button>
          </mat-menu>
        </mat-card-actions>
      </mat-card>
    </ng-container>
  </ng-container>
</div>

