<div class="column">
  <div class="input-container">
    <textarea
    placeholder="{{'CASE SECTION.KEYWORDS' | translate}}"
    type="text"
    [formControl]="keywordsControl"
    cdkTextareaAutosize
    cdkAutosizeMinRows="1"
    cdkAutosizeMaxRows="4">
    </textarea>
  
  
    <div *ngIf="keywordsControl?.invalid && (keywordsControl?.dirty || keywordsControl?.touched)" class="mat-small input-error">
      <span *ngIf="keywordsControl?.hasError('required')" class="mat-small">&nbsp; {{'FORMS.FIELD REQUIRED' |
        translate}}</span>
      <span *ngIf="keywordsControl?.hasError('maxlength')" class="mat-small">{{keywordsControl.value.length}}/255 &nbsp;
        {{'FORMS.MAX LENGTH' | translate}}</span>
    </div>
  </div>

</div>
<div class="btn-row">
  <button type="button" (click)="onSubmit()" mat-stroked-button>{{'ACTIONS.SAVE' | translate}}</button>&nbsp;
  <button mat-button (click)="emitClose();">{{'ACTIONS.CANCEL' | translate}}</button>
</div>