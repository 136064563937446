import { Component, Input, OnInit } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { BehaviorSubject, Observable } from 'rxjs';
import { Author } from 'src/app/api/generated/graphql';
import { AuthorsService } from 'src/app/services/authors.service';
import { ReportService } from 'src/app/services/report/report.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-document-group-settings',
  templateUrl: './document-group-settings.component.html',
  styleUrls: ['./document-group-settings.component.css']
})
export class DocumentGroupSettingsComponent implements OnInit {
  @Input() reportID: string = null;
  authors$: BehaviorSubject<Author[]>;
  error: any;
  isAuthenticated$: Observable<boolean>;
  showAddInput: boolean = false;
  ownerEmail: Observable<string> | null = null;

  constructor(
    private authorService: AuthorsService,
    private reportService: ReportService,
  ) {
    this.authors$ = new BehaviorSubject(null);
  }

  ngOnInit(): void {
    this.authorService.getAuthors(this.reportID).subscribe(this.authors$);
    this.ownerEmail = this.reportService.getReportOwnerEmail(this.reportID);
  }

  trackByFn(index, item) {
    return item.id; // or item.id
  }

  drop(event: CdkDragDrop<string[]>) {
    const authorID = event.item.data.id
    moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    this.updateAuthorOrder(authorID, event.currentIndex, event.previousIndex);
  }

  updateAuthorOrder(authorID: string, newOrder: number, previousOrder: number) {
    const obs = this.authorService.updateAuthorOrder(authorID, this.reportID, newOrder, previousOrder);

    obs.pipe(first()).subscribe();
  }

}
