<ng-container *ngIf="authors$ | async as authors">
  <div class="pad">
    <ng-container *ngIf="authors.length">
      <div class="item" *ngFor="let a of authors">
        <ng-container *ngIf="!a.user.givenName; else user">
          <span class="mat-body">{{a.firstName}} {{a.lastName}}, {{a.email}}&nbsp;</span>
          <ng-container *ngIf="a.affiliations.length">
            <ol>
              <ng-container *ngFor="let aff of a.affiliations; last as isLast">
                <li><span class="mat-body">{{aff.name}}</span><span *ngIf="aff.position" class="mat-body">,
                    {{aff.position}}</span></li>
              </ng-container>
            </ol>
          </ng-container>
        </ng-container>
        <ng-template #user>
          <span class="mat-body">{{a.user.givenName}} {{a.user.familyName}}, {{a.user.email}}&nbsp;</span>
          <ng-container *ngIf="a.userAffiliations.length">
            <ol>
              <ng-container *ngFor="let aff of a.userAffiliations; last as isLast">
                <li><span class="mat-body">{{aff.name}}, {{aff.position}}</span></li>
              </ng-container>
            </ol>
          </ng-container>
        </ng-template>
      </div>
    </ng-container>
  </div>
  </ng-container>