import { Injectable } from '@angular/core';
import { HelpExampleSectionGQL, HelpSectionsGQL, HelpSubSectionGQL, FilterHelpSectionsGQL } from 'src/app/api/generated/graphql';
import { map, filter, catchError } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';

declare let gtag: Function;

@Injectable({
  providedIn: 'root'
})
export class AirtableService {

  constructor(
    private helpSectionsGQL: HelpSectionsGQL,
    private helpExampleSectionGQL: HelpExampleSectionGQL,
    private helpSubSectionGQL: HelpSubSectionGQL,
    private filteredHelpSectionsGQL: FilterHelpSectionsGQL,
    private translate: TranslateService
    ) { }

  getSections(): Observable<any> {
    return this.helpSectionsGQL.fetch({ lang: this.translate.currentLang})
      .pipe(
        map((result: any) => {
          return (<any>result.data).helpSections.Records
        }),
        catchError((err: any) => {
          console.error(err);
          this.sendEvent(err);
          return of(null)
        })
      );
  }

  // currently accepts only one param to make a filterByFormula for a call to Airtable Help Sections
  getSectionByTitle(param: string) {
    return this.filteredHelpSectionsGQL.fetch({
      filterKey: "Title",
      filterValue: param,
      lang: this.translate.currentLang
    })
      .pipe(
        filter((result: any) => !result.loading),
        map((result: any) => {
          return (<any>result.data).filterHelpSections.Records[0]
        }),
        catchError((err: any) => {
          console.error(err);
          this.sendEvent(err);
          return of(null)
        })
      );
  }

  getExampleByID(id: string) {
    return this.helpExampleSectionGQL.fetch({ id: id, lang: this.translate.currentLang})
      .pipe(
        filter((result: any) => !result.loading),
        map((result: any) => {
          return (<any>result.data).helpExampleSection
        }),
        catchError((err: any) => {
          console.error(err);
          this.sendEvent(err);
          return of(null)
        })
      );
  }

  getSubsectionByID(id: string) {
    return this.helpSubSectionGQL.fetch({ id: id, lang: this.translate.currentLang })
      .pipe(
        filter((result: any) => !result.loading),
        map((result: any) => {
          return (<any>result.data).helpSubSection
        }),
        catchError((err: any) => {
          console.error(err);
          this.sendEvent(err);
          return of(null)
        })
        
      );
  }

  sendEvent(err: string): void {
    gtag('event', 'exception', {
      'exDescription': 'AirtableHelpMenu::' + err,
      'exFatal': false  // set to true if the error is fatal
    });
  }

}