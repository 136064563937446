import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Report, RequiredSection, UpdateLastEditorGQL, UpdateRequiredSectionGQL } from 'src/app/api/generated/graphql';


@Injectable({
  providedIn: 'root'
})
export class RequiredSectionsService {

  constructor(
    private reqSectGQL: UpdateRequiredSectionGQL,
    private updateLastEditorGQL: UpdateLastEditorGQL,
  ) { }

  public updateRequiredSection(sectionID: string, reportID: string, content: string): Observable<Report> {
    const obs = this.reqSectGQL
      .mutate(
        {
          reportID: reportID,
          sectionID: sectionID,
          content: content
        }
      )
      .pipe(map((result: any) => {
        return result.data.updateRequiredSection;
      }))

    return obs;
  }

  public updateLastUpdated(sectionID: string, reportID: string): Observable<RequiredSection> {
    const obs = this.updateLastEditorGQL.mutate({
      reportID: reportID,
      sectionID: sectionID,
    })
      .pipe(map((result: any) => {
        return result.data;
      }))

    return obs;

  }

}