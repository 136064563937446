import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-double-value',
  templateUrl: './double-value.component.html',
  styleUrls: ['./double-value.component.css']
})
export class DoubleValueComponent implements OnInit {
  @Input()
  form!: FormGroup;
  @Input()
  labels: string[] = [];
  loading: boolean = true;
  
  constructor() { }

  ngOnInit(): void {
    if (this.form) {
      this.loading = false;
    }
  }

}
