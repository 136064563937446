<ng-container *ngIf="report">
  <div>
    <app-quill-read-only [reportID]="report.id" [sectionID]="report.sections[0].id" [isPreviewView]="true"></app-quill-read-only>
    <ng-container *ngIf="translation && !translation?.deletedAt">
      <app-quill-translate [translation]="translation"></app-quill-translate>
    </ng-container>

    <div class="keywords">
      <span class="mat-subheading-1">{{'CASE SECTION.KEYWORDS' | translate}}</span><br />
      <span *ngIf="report.keywords" class="mat-body">{{report.keywords}}</span><br />
      <span *ngIf="translation && !translation?.deletedAt" class="mat-body"><i>{{translation.keywords}}</i></span>
    </div>

    <app-quill-read-only [reportID]="report.id" [sectionID]="report.sections[1].id" [isPreviewView]="true"></app-quill-read-only>

    <app-visits-preview [reportID]="report.id"></app-visits-preview>

    <app-quill-read-only [reportID]="report.id" [sectionID]="report.sections[2].id" [isPreviewView]="true"></app-quill-read-only>
    
    <app-view-diagnostics [reportID]="report.id"></app-view-diagnostics>

    <app-quill-read-only [reportID]="report.id" [sectionID]="report.sections[7].id" [isPreviewView]="true"></app-quill-read-only>

    <app-quill-read-only [reportID]="report.id" [sectionID]="report.sections[3].id" [isPreviewView]="true"></app-quill-read-only>
  
    <app-quill-read-only [reportID]="report.id" [sectionID]="report.sections[4].id" [isPreviewView]="true"></app-quill-read-only>
  
    <app-quill-read-only [reportID]="report.id" [sectionID]="report.sections[5].id" [isPreviewView]="true"></app-quill-read-only>
  
    <app-quill-read-only [reportID]="report.id" [sectionID]="report.sections[6].id" [isPreviewView]="true"></app-quill-read-only>
  
  </div>
</ng-container>