<ng-container *ngIf="!loading">

  <h3 class="mat-headline">{{'MESSAGES.UPDATE ADD NAME' | translate}}</h3>
  
  <ng-container *ngIf="updateRequired; else confirmMessage">
    <p class="mat-subheading-2">{{'MESSAGES.FULL NAME MISSING' | translate}}</p>
  </ng-container>
  <ng-template #confirmMessage>
    <p class="mat-subheading-2">{{'MESSAGES.CONFIRM NAME 1' | translate}} {{name}}. <span [innerHTML]="'MESSAGES.CONFIRM NAME 2' | translate"></span>&nbsp;
      <span [innerHTML]="'MESSAGES.CONFIRM NAME 3' | translate"></span></p>
      <p class="mat-subheading-2">{{'MESSAGES.ASK ONCE' | translate}}</p>
      <mat-action-row *ngIf="!editUser && !updateRequired">
        <button mat-flat-button (click)="editUser = true">{{'ACTIONS.EDIT' | translate}}</button>
        <button mat-flat-button color="accent" (click)="confirm()">{{'ACTIONS.CONFIRM' | translate}}</button>
      </mat-action-row>
    </ng-template>
    
    <ng-container *ngIf="editUser || updateRequired">
      <app-updateprofile hideCancel="true" [user]="user" (updateUser)="updateAndRoute()" (closeProfile)="editUser = false; closeDialog();"></app-updateprofile>
    </ng-container>
</ng-container>

<ng-container *ngIf="loading">
  <mat-progress-spinner mode="indeterminate" diameter="20"></mat-progress-spinner>
</ng-container>