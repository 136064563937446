<div class="nav">
  <a class="item" routerLink="/dashboard" routerLinkActive="active-link">
    <mat-icon>dashboard</mat-icon>
    <span>
      {{'NAVIGATION.DASHBOARD' | translate}}
    </span>
  </a>
  <a class="item" routerLink="/saved-cases" routerLinkActive="active-link">
    <mat-icon>star_border</mat-icon>
    <span>
      {{'MISC.FAVORITES' | translate}}
    </span>
  </a>
</div>

<div class="nav sub-nav mat-subheading-2">
  <div>Resources</div>
  <mat-divider inset="false"></mat-divider>
  <a class="item" href="https://care-writer.com/how-to-use" target="_blank">
    <mat-icon>build</mat-icon>
    <span>
      {{'LINKS.HOW TO' | translate}}&nbsp;<mat-icon inline="true" class="launch-icon">launch</mat-icon>
    </span>
  </a>
  <a class="item" href="https://care-writer.com/faq" target="_blank">
    <mat-icon>question_answer</mat-icon>
    <span>
      {{ 'LINKS.FAQ' | translate }}&nbsp;<mat-icon inline="true" class="launch-icon">launch</mat-icon>
    </span>
  </a>
</div>