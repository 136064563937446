import { Component, Inject } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-add-diagnostics-entry',
  templateUrl: './add-diagnostics-entry.component.html',
  styleUrls: ['./add-diagnostics-entry.component.css']
})
export class AddDiagnosticsEntryComponent {

  reportId: string = '';

  constructor(
    public dialogRef: MatDialogRef<AddDiagnosticsEntryComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.reportId = data.reportId;
  }

  closeDialog(): void {
    this.dialog.closeAll()
  }

}
