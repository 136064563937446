import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { catchError, filter, first, last, map } from 'rxjs/operators';
import { Report as ApiReport } from 'src/app/api/generated/graphql';
import { Report } from 'src/app/classes/report';
import { MysavedreportsService } from 'src/app/services/mysavedreports.service';

declare let gtag: Function;

@Component({
  selector: 'app-mysavedreports',
  templateUrl: './mysavedreports.component.html',
  styleUrls: ['./mysavedreports.component.css']
})
export class MysavedreportsComponent implements OnInit {
  
  error: any;
  starred$: Observable<Report[]> = new Observable<Report[]>;
  showName: boolean = false;

  constructor(
    private starService: MysavedreportsService,
    private _snackBar: MatSnackBar,
    private translate: TranslateService,
  ) { }

  ngOnInit(): void {
    this.starred$ = this.fetchStarred()
  }

  fetchStarred(): Observable<any> {
    return this.starService.getMyStarredReports()
      .pipe(
        filter(result => !result.loading),
        map((result: {data: {myStarredReports: ApiReport[]}}) => {
          const reports: ApiReport[] = (<any>result.data).myStarredReports;
          let newReps: Report[] = reports.map(element => {
            let r = new Report(element);
            return r;
          });

          return newReps;
        }),
        catchError(err => {
          this.error = err;
          console.error(err);
          return null;
        }),
      );
  }

  confirmCopy(event) {
    if (event == true) {

      this.translate.get(['SNACKS.COPIED EMAIL', 'SNACKS.DISMISS']).pipe(first()).subscribe((res: string) => {
        this._snackBar.open(res['SNACKS.COPIED EMAIL'], res['SNACKS.DISMISS'], {
          duration: 2000,
        });

      })
    }
  }

  sendEvent() {
    gtag('event', 'view-item', {
      'event_category': 'engagement',
      'event_label': 'unsaved-report'
    });
  }

  removeSavedReport(reportID: string) {
    this.starService.removeStar(reportID).pipe(first()).subscribe({
      error: err => {
        this.error = err;
        console.log(err);
      },
      complete: () => this.sendEvent()
    })
 
  }

}
