<ng-container *ngIf="(invites$ | async) as invites">
  <ng-container *ngIf="invites.length; else noNotifications">
    <button mat-icon-button [matMenuTriggerFor]="notificationsMenu"
      aria-label="Profile menu">
      <mat-icon class="material-icons-round" [matBadge]="invites.length > 0 ? invites.length : null" matBadgeSize="small" matBadgeColor="warn" appBtnWhite>notifications_none</mat-icon>
    </button>
    <mat-menu #notificationsMenu="matMenu">
      <p class="mat-small p2">You have {{invites.length}} invitation<span *ngIf="invites.length != 1">s</span> waiting.</p>
      <mat-divider></mat-divider>
      <ng-container *ngFor="let invite of invites">
        <button mat-menu-item [routerLink]="['/invitation', invite.id ]">
          <span class="mat-small">{{invite.report.owner.email}} has invited you to co-author a report</span>
        </button>
      </ng-container>
    </mat-menu>
  </ng-container>
  <ng-template #noNotifications>
    <button mat-icon-button [matMenuTriggerFor]="notificationsMenuEmpty" aria-label="Profile menu">
      <mat-icon class="material-icons-round" appBtnWhite>notifications_none</mat-icon>
    </button>
    <mat-menu #notificationsMenuEmpty="matMenu">
      <p class="mat-small pad">You have 0 notifications.</p>
    </mat-menu>
  </ng-template>
</ng-container>