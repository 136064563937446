import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { Visit } from 'src/app/api/generated/graphql';
import { ModalService } from 'src/app/services/modal.service';


@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.css']
})
export class TimelineComponent implements OnInit {

  @Input() note: Visit | null = null;
  reportId: string = '';
  allowEdit: boolean = false;
  
  constructor(
    private route: ActivatedRoute,
    private modalService: ModalService,
    ) { }

  ngOnInit(): void {
    this.route.url.pipe(first()).subscribe( u => {
      if (u.length) {
        if (u[0]['path'] === 'visits') {
          this.allowEdit = true;
        }
      }
    })

    if (this.route.parent) {
      this.route.parent.paramMap.pipe(first()).subscribe(m => {
        const id = m.get("id");
        this.reportId = id;
      })
    }
  }

  editItem(): void {
    this.modalService.openUpdateVisit(this.note, this.reportId);
  }

}
