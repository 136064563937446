import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-group-members',
  templateUrl: './group-members.component.html',
  styleUrls: ['./group-members.component.css']
})
export class GroupMembersComponent implements OnInit {
  @Input() reportID: string = '';

  constructor() { }

  ngOnInit(): void {
  }

}
