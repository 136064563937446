import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { TranslatableContent } from 'src/app/components/translator/translator.component';
import { TranslatorService } from '../../services/translator.service';
import { first } from 'rxjs/operators';
import Quill from 'quill';

@Component({
  selector: 'app-request-translation',
  templateUrl: './request-translation.component.html',
  styleUrls: ['./request-translation.component.css'],
})
export class RequestTranslationComponent implements OnInit {

  errorMessage: string;
  
  constructor(
    private translate: TranslateService,
    private translatorService: TranslatorService,
    public dialogRef: MatDialogRef<RequestTranslationComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: TranslatableContent
  ) {
  }

  ngOnInit(): void {
  }
  
  requestTranslation(): void {
    this.translatorService.translateDelta(this.data.reportID, this.data.sectionID).pipe(first()).subscribe({
      next: (data) => {

        if (data) {
          var options = {
            placeholder: '',
            readOnly: true,
            theme: 'bubble',
            modules: {
              toolbar: false,
            }
          };

          let quill = new Quill('#translator', options);
          let delta = quill.clipboard.convert(data);

          this.translatorService.translateReport(this.data.reportID, delta).pipe(first()).subscribe({
            error: (err) => {
              this.errorMessage = err;
              console.error(err);
            }
          })
        }
      },
      error: (err) => {
        this.errorMessage = err;
        console.error(err);
      },
      complete: () => this.closeDialog()
    })
  }

  closeDialog(): void {
    this.dialog.closeAll()
  }

}
