import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-saved-cases',
  templateUrl: './saved-cases.component.html',
  styleUrls: ['./saved-cases.component.css']
})
export class SavedCasesComponent implements OnInit {
  
  constructor() {}

  ngOnInit(): void {
  }

}
