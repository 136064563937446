<app-message-info *ngIf="showMessage" content="{{'INFO.VISITS' | translate}}"></app-message-info>
<form [formGroup]="visitForm" (ngSubmit)="onSubmit(visitForm, $event)" class="form-container" autocomplete="off">
  <div class="input-container date-container">
    <label class="mat-body">{{'MISC.DATE' | translate}}&nbsp;(mm/dd/yyyy)</label>
    <input #dateInput required formControlName="dateValue" [matDatepicker]="picker" placeholder="{{'FORMS.CHOOSE DATE' | translate}}">
    <div class="datepick">
      <mat-datepicker-toggle disableRipple matSuffix [for]="picker">
        <mat-icon class="picker-icon" matDatepickerToggleIcon>
          calendar_month
        </mat-icon>
      </mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </div>

    <ng-container *ngIf="dateValue?.invalid && dateValue?.dirty">
      <div class="input-error" *ngIf="dateValue.errors.required">{{'FORMS.VALID DATE' | translate}}</div>
    </ng-container>
  </div>

  <div class="input-container summary">
    <label class="mat-body">{{'FORMS.SUMMARY' | translate}}</label>
    <textarea rows="4" required formControlName="summaryValue" placeholder="{{'FORMS.ENTER SUMMARY' | translate}}"></textarea>

    <ng-container *ngIf="summaryValue?.invalid && (summaryValue?.dirty || summaryValue?.touched); else valid">
      <div class="mat-small min-height" style="color: red;">{{summaryValue.value.length}}/255<span
          *ngIf="summaryValue.hasError('maxlength')" class="mat-small" style="color: red;">&nbsp;{{'FORMS.MAX LENGTH' | translate}}</span>
      </div>
    </ng-container>
    <ng-template #valid>
      <div class="min-height">
        <span *ngIf="summaryValue.value" class="mat-small">{{summaryValue.value.length}}/255</span>
      </div>
    </ng-template>
  </div>

  <button type="submit" [disabled]="!visitForm.valid" aria-label="Add visit" mat-stroked-button [class.mat-flat-button]="visitForm.valid" [color]="visitForm.valid ? 'accent' : null">
    <mat-icon class="all-valid">check_circle_outline</mat-icon>&nbsp;
      <span class="mat-body">{{'ACTIONS.SAVE' | translate}}</span>
  </button>
</form>