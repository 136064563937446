import { Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import Quill from 'quill';
import Delta from 'quill-delta';

@Component({
  selector: 'app-quill-diff',
  templateUrl: './quill-diff.component.html',
  styleUrls: ['./quill-diff.component.css'],
})
export class QuillDiffComponent implements OnInit {
  @Input() deltaOne!: any;
  @Input() deltaTwo!: any;
  @Input() versionId!: string;
  @ViewChild('deltaDiff', { read: ElementRef, static: true })
  deltaDiff!: ElementRef;
  quill!: Quill;

  constructor(
    private renderer: Renderer2
  ) { }

  ngOnInit(): void {
    if (this.deltaOne && this.deltaTwo) {
      let divId = "r" + this.versionId.substring(0, 7);
      this.createEditor(divId);
      this.initEditor(divId);

      const d1 = new Delta(JSON.parse(this.deltaOne));
      const d2 = new Delta(JSON.parse(this.deltaTwo));
      this.generateDeltaDiff(d1, d2);
    }
  }

  createEditor(divId) {
    const div = this.renderer.createElement('div');
    this.renderer.setProperty(div, "id", divId);
    this.renderer.appendChild(this.deltaDiff.nativeElement, div);
  }

  initEditor(divId) {
    var options = {
      placeholder: '',
      readOnly: true,
      theme: 'bubble',
      modules: {
        toolbar: false,
      }
    };
    const name = "#" + divId;
    this.quill = new Quill(name, options);
  }

  generateDeltaDiff(deltaOne: Delta, deltaTwo: Delta): void {
    let diff = deltaTwo.diff(deltaOne);

    for (var i = 0; i < diff.ops.length; i++) {
      var op = diff.ops[i];
      // if the change was an insertion
      if (op.hasOwnProperty('insert')) {
        // color it green
        op.attributes = {
          background: "#cce8cc",
          color: "#003700"
        };
      }
      // if the change was a deletion 
      if (op.hasOwnProperty('delete')) {
        // keep the text
        op.retain = op.delete;
        delete op.delete;
        // but color it red and struckthrough
        op.attributes = {
          color: "#f54254",
          strike: true
        };
      }
    }
    this.quill.setContents(deltaTwo.compose(diff));
  }

}