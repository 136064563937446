import { NgModule } from '@angular/core';
import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';
import { HttpClientModule } from '@angular/common/http'
import { HttpLink } from 'apollo-angular/http'
import { InMemoryCache, ApolloLink } from '@apollo/client/core'
import { environment } from 'src/environments/environment';
import { onError } from 'apollo-link-error';
import extractFiles from 'extract-files/extractFiles.mjs'
import isExtractableFile from 'extract-files/isExtractableFile.mjs';

const uri = environment.graphQLuri;

export function createApollo(httpLink: HttpLink) {

  const link = httpLink.create({ uri: uri, extractFiles: (body) => extractFiles(body, isExtractableFile) })

  const errLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.map(({ message, locations, path }) =>
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      );
    if (networkError) console.log(`[Network error]: ${networkError}`, networkError);
  });

  return {
    link: ApolloLink.from([(errLink as unknown) as ApolloLink, link]),
    cache: new InMemoryCache(),
  };
}


@NgModule({
  exports: [ApolloModule, HttpClientModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink],
    },
  ],
})
export class GraphQLModule { }