<div class="title-container">
  <span class="mat-subtitle-2">{{"TRANSLATION.TRANSLATION" | translate}}&nbsp;&nbsp;</span>
  <span class="beta mat-small">{{"MISC.BETA" | translate}}</span>
</div>
<span class="mat-body mb">{{"TRANSLATION.TRANSLATE TO" | translate}}</span>
<app-message-info *ngIf="translation" content="{{'TRANSLATION.EXISTING' | translate}}"></app-message-info>
<div class="mb">
  <button mat-stroked-button appAccentHover appDefaultGrey (click)="openTranslatorDialog(); editTranslation = false;">
    <mat-icon>language</mat-icon>&nbsp;{{"TRANSLATION.TRANSLATE" | translate}}
  </button>
</div>
<div *ngIf="translation" class="slide">
  <span mat-line class="mat-small">{{"TRANSLATION.APPEAR IN" | translate}}</span>
  <mat-slide-toggle class="mat-body" [(ngModel)]="checked" (change)="showOrHide();">{{"TRANSLATION.SHOW" | translate}}</mat-slide-toggle>
</div>
<div class="slide" *ngIf="translation && !translation.deletedAt">
  <span mat-line class="mat-small">{{"TRANSLATION.MAKE ADJUSTMENTS" | translate}}</span>
  <button *ngIf="!editTranslation" mat-stroked-button appDefaultGrey appAccentHover (click)="editTranslation = true">{{"TRANSLATION.EDIT" | translate}}</button>
  <button *ngIf="editTranslation" mat-flat-button color="accent" (click)="editTranslation = false">{{"ACTIONS.DONE" | translate}}</button>
</div>
<ng-container *ngIf="translation && editTranslation">
  <app-quill-translate [translation]="translation" [readOnly]="false"></app-quill-translate>
</ng-container>