import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-message-info',
  templateUrl: './message-info.component.html',
  styleUrls: ['./message-info.component.css']
})
export class MessageInfoComponent {
  @Input() content: string = '';
  @Input() learnMoreLink: string | null = null;
  @Input() icon: string = 'lightbulb';
  @Input() iconColor: string = 'orange';
  
  constructor() { }

  ngOnInit(): void {
  }

}
