import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { Author } from 'src/app/api/generated/graphql';
import { AffiliationsService } from 'src/app/services/affiliations.service';

@Component({
  selector: 'app-report-author-affiliations',
  templateUrl: './report-author-affiliations.component.html',
  styleUrls: ['./report-author-affiliations.component.css']
})
export class ReportAuthorAffiliationsComponent implements OnInit {
  @Input() reportID: string = '';
  @Input() authors: Author[] = [];
  authors$: Observable<Author[]> | null = null;
  
  constructor(
    private affsService: AffiliationsService,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    if (this.reportID) {
      if (this.authors.length) {
        this.authors$ = of(this.authors);
      } else {
        this.authors$ = this.affsService.reportAuthorAffiliations(this.reportID);
      }
    }
  }
}
