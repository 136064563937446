<ng-container *ngIf="!loading">
  <div *ngIf="form" [formGroup]="form" class="row">
    <div class="column">
      <div class="label-1 row">
        <label class="mat-body">{{labels[0]}}&nbsp;</label>
        <input type="number" autocomplete="off" formControlName="singleValue">
      </div>
      <div class="row">
        <label class="mat-body">{{labels[1]}}&nbsp;</label>
        <input type="number" autocomplete="off" formControlName="alternateValue">
      </div>
    </div>
    <div>
      <app-unit-input [form]="form"></app-unit-input>
    </div>
  </div>
</ng-container>