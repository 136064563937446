<ng-container *ngIf="casereport$ | async as report; else loading">
  <ng-container>
    <div class="column view-width">
      <div class="btn-row">
        <button mat-button (click)="openRevisionModal()">Save Version&nbsp;<mat-icon>history</mat-icon></button>
      </div>
      <mat-divider></mat-divider>

      <app-case-header [isCaseView]="true" [report]="report"></app-case-header>
      <ng-container *ngIf="user$ | async as user">
        <ng-container *ngIf="report.owner.id !== user.sub; else groupsEditable">
          <app-group-members-read-only [nonEditable]="true" [reportID]="report.id"></app-group-members-read-only>
        </ng-container>
        <ng-template #groupsEditable>
          <app-group-members [reportID]="report.id"></app-group-members>
        </ng-template>
      </ng-container>
      <app-content-preview [report]="report"></app-content-preview>
    </div>
  </ng-container>
</ng-container>

<ng-template #loading>
  <div class="centered-spinner">
    <mat-spinner diameter="28"></mat-spinner>
  </div>
</ng-template>