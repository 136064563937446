import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Time: any;
  Upload: any;
};

export type Affiliation = {
  __typename?: 'Affiliation';
  authorID?: Maybe<Scalars['ID']>;
  careUserID?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  position: Scalars['String'];
};

export type AirtableFilter = {
  filterKey: Scalars['String'];
  filterValue: Scalars['String'];
};

export type ApiUser = {
  __typename?: 'ApiUser';
  email: Scalars['String'];
  familyName: Scalars['String'];
  givenName: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Attachment = {
  __typename?: 'Attachment';
  description: Scalars['String'];
  fileBlob?: Maybe<Scalars['String']>;
  fileName: Scalars['String'];
  fileType: Scalars['String'];
  id: Scalars['ID'];
  order: Scalars['Int'];
  report: Report;
  storageName: Scalars['String'];
};

export type Author = {
  __typename?: 'Author';
  affiliations: Array<Affiliation>;
  careUserID?: Maybe<Scalars['ID']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
  order: Scalars['Int'];
  report: Report;
  user: User;
  userAffiliations: Array<Affiliation>;
};

export type CreateStarResponse = {
  __typename?: 'CreateStarResponse';
  id: Scalars['ID'];
  reportTitle: Scalars['String'];
};

export type DeleteStarResponse = {
  __typename?: 'DeleteStarResponse';
  id: Scalars['ID'];
  reportTitle: Scalars['String'];
};

export type Diagnostic = {
  __typename?: 'Diagnostic';
  alternateValue?: Maybe<Scalars['Float']>;
  date: Scalars['Time'];
  defaultValue?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  report: Report;
  type: DiagnosticType;
  unit?: Maybe<DiagnosticUnit>;
  value?: Maybe<Scalars['Float']>;
};

export type DiagnosticDefaultValue = {
  __typename?: 'DiagnosticDefaultValue';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type DiagnosticSubtype = {
  __typename?: 'DiagnosticSubtype';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type DiagnosticType = {
  __typename?: 'DiagnosticType';
  defaultValueNames: Array<Maybe<Scalars['String']>>;
  defaultValues: Array<DiagnosticDefaultValue>;
  id: Scalars['ID'];
  name: Scalars['String'];
  subtypeNames: Array<Maybe<Scalars['String']>>;
  subtypes: Array<DiagnosticSubtype>;
};

export type DiagnosticUnit = {
  __typename?: 'DiagnosticUnit';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Fields = {
  __typename?: 'Fields';
  examples: Array<Scalars['String']>;
  subsections: Array<Scalars['String']>;
  summary: Scalars['String'];
  title: Scalars['String'];
};

export type HelpExample = {
  __typename?: 'HelpExample';
  fields: HelpExampleFields;
  id: Scalars['ID'];
};

export type HelpExampleFields = {
  __typename?: 'HelpExampleFields';
  citation: Scalars['String'];
  content: Scalars['String'];
  title: Scalars['String'];
};

export type HelpSection = {
  __typename?: 'HelpSection';
  Records: Array<Section>;
};

export type Invite = {
  __typename?: 'Invite';
  id: Scalars['ID'];
  inviteeEmail: Scalars['String'];
  report: Report;
  status: Scalars['String'];
  user: User;
};

export type Mutation = {
  __typename?: 'Mutation';
  createAffiliation: Affiliation;
  createAuthor: Author;
  createDiagnostic: Diagnostic;
  createReport: Report;
  createSectionComment: SectionComment;
  createStar: CreateStarResponse;
  createTask: Report;
  createVisit: Visit;
  deleteAffiliation: Affiliation;
  deleteAttachment: Report;
  deleteAuthor: Report;
  deleteDiagnostic: Diagnostic;
  deleteReport: Report;
  deleteSectionComment: SectionComment;
  deleteStar: DeleteStarResponse;
  deleteTask: Task;
  deleteTranslation: Translation;
  deleteVisit: Visit;
  respondToInvite: Scalars['Boolean'];
  restoreRevision: Report;
  restoreTranslation: Translation;
  saveAttachment: Attachment;
  saveRevision: Scalars['Boolean'];
  sendInvitation: Scalars['String'];
  setCorrespondingAuthor: Report;
  translateDelta: Scalars['String'];
  translateReport: Translation;
  updateAffiliation: Affiliation;
  updateAttachment: Attachment;
  updateAttachmentOrder: Report;
  updateAuthor: Author;
  updateAuthorOrder: Report;
  updateKeywords: Report;
  updateLastEditor: RequiredSection;
  updatePublicStatus: Scalars['Boolean'];
  updateRequiredSection: Report;
  updateSectionComment: SectionComment;
  updateTask: Report;
  updateTitle: Report;
  updateTranslation: Translation;
  updateUser: ApiUser;
  updateVisit: Visit;
};


export type MutationCreateAffiliationArgs = {
  input: NewAffiliation;
};


export type MutationCreateAuthorArgs = {
  input: NewAuthor;
};


export type MutationCreateDiagnosticArgs = {
  input: NewDiagnostic;
};


export type MutationCreateReportArgs = {
  keywords: Scalars['String'];
  title: Scalars['String'];
};


export type MutationCreateSectionCommentArgs = {
  input: NewSectionComment;
};


export type MutationCreateStarArgs = {
  reportID: Scalars['ID'];
};


export type MutationCreateTaskArgs = {
  input: NewTask;
};


export type MutationCreateVisitArgs = {
  input: NewVisit;
};


export type MutationDeleteAffiliationArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteAttachmentArgs = {
  id: Scalars['ID'];
  order: Scalars['Int'];
  reportId: Scalars['ID'];
};


export type MutationDeleteAuthorArgs = {
  id: Scalars['ID'];
  order: Scalars['Int'];
  reportId: Scalars['ID'];
};


export type MutationDeleteDiagnosticArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteReportArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteSectionCommentArgs = {
  sectionCommentId: Scalars['String'];
};


export type MutationDeleteStarArgs = {
  reportID: Scalars['ID'];
};


export type MutationDeleteTaskArgs = {
  reportID: Scalars['String'];
  taskId: Scalars['String'];
};


export type MutationDeleteTranslationArgs = {
  id: Scalars['String'];
};


export type MutationDeleteVisitArgs = {
  id: Scalars['ID'];
  reportId: Scalars['ID'];
};


export type MutationRespondToInviteArgs = {
  email: Scalars['String'];
  reportID: Scalars['ID'];
  response: Scalars['String'];
};


export type MutationRestoreRevisionArgs = {
  revisionId: Scalars['ID'];
};


export type MutationRestoreTranslationArgs = {
  id: Scalars['String'];
};


export type MutationSaveAttachmentArgs = {
  description: Scalars['String'];
  file: Scalars['Upload'];
  fileType: Scalars['String'];
  reportID: Scalars['ID'];
};


export type MutationSaveRevisionArgs = {
  name?: InputMaybe<Scalars['String']>;
  reportId: Scalars['ID'];
};


export type MutationSendInvitationArgs = {
  inviteeEmail: Scalars['String'];
  reportID: Scalars['ID'];
};


export type MutationSetCorrespondingAuthorArgs = {
  authorId: Scalars['ID'];
  reportId: Scalars['ID'];
};


export type MutationTranslateDeltaArgs = {
  reportID: Scalars['ID'];
  sectionID: Scalars['ID'];
};


export type MutationTranslateReportArgs = {
  reportID: Scalars['ID'];
  sectionContent: Scalars['String'];
};


export type MutationUpdateAffiliationArgs = {
  input: UpdateAffiliation;
};


export type MutationUpdateAttachmentArgs = {
  attachmentId: Scalars['ID'];
  description: Scalars['String'];
  reportId: Scalars['ID'];
};


export type MutationUpdateAttachmentOrderArgs = {
  attachmentId: Scalars['ID'];
  order: Scalars['Int'];
  previousOrder: Scalars['Int'];
  reportId: Scalars['ID'];
};


export type MutationUpdateAuthorArgs = {
  input: UpdateAuthor;
};


export type MutationUpdateAuthorOrderArgs = {
  authorId: Scalars['ID'];
  order: Scalars['Int'];
  previousOrder: Scalars['Int'];
  reportId: Scalars['ID'];
};


export type MutationUpdateKeywordsArgs = {
  keywords: Scalars['String'];
  reportId: Scalars['ID'];
};


export type MutationUpdateLastEditorArgs = {
  reportID: Scalars['ID'];
  sectionID: Scalars['ID'];
};


export type MutationUpdatePublicStatusArgs = {
  isPublic: Scalars['Boolean'];
  reportId: Scalars['ID'];
};


export type MutationUpdateRequiredSectionArgs = {
  content: Scalars['String'];
  reportID: Scalars['ID'];
  sectionID: Scalars['ID'];
};


export type MutationUpdateSectionCommentArgs = {
  body: Scalars['String'];
  sectionCommentId: Scalars['String'];
};


export type MutationUpdateTaskArgs = {
  input: UpdateTask;
};


export type MutationUpdateTitleArgs = {
  reportId: Scalars['ID'];
  title: Scalars['String'];
};


export type MutationUpdateTranslationArgs = {
  input: UpdateTranslation;
};


export type MutationUpdateUserArgs = {
  input: UserConfig;
};


export type MutationUpdateVisitArgs = {
  input: UpdateVisit;
};

export type NewAffiliation = {
  authorID?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  position: Scalars['String'];
};

export type NewAuthor = {
  careUserID?: InputMaybe<Scalars['ID']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  reportID: Scalars['ID'];
};

export type NewDiagnostic = {
  alternateValue?: InputMaybe<Scalars['Float']>;
  date: Scalars['Time'];
  defaultValue?: InputMaybe<Scalars['String']>;
  reportID: Scalars['ID'];
  typeID: Scalars['ID'];
  unitID?: InputMaybe<Scalars['ID']>;
  value?: InputMaybe<Scalars['Float']>;
};

export type NewSectionComment = {
  body: Scalars['String'];
  parentID: Scalars['ID'];
  sectionID: Scalars['ID'];
};

export type NewTask = {
  body: Scalars['String'];
  reportID: Scalars['ID'];
  userID: Scalars['ID'];
};

export type NewVisit = {
  date: Scalars['Time'];
  reportID: Scalars['ID'];
  summary: Scalars['String'];
};

export type PublicReport = {
  __typename?: 'PublicReport';
  attachments: Array<Attachment>;
  authors: Array<Author>;
  corrAuthor: Author;
  createdAt: Scalars['Time'];
  diagnostics: Array<Diagnostic>;
  id: Scalars['ID'];
  isPublic: Scalars['Boolean'];
  keywords?: Maybe<Scalars['String']>;
  sections: Array<RequiredSection>;
  title: Scalars['String'];
  translation: Translation;
  updatedAt: Scalars['Time'];
  visits: Array<Visit>;
};

export type Query = {
  __typename?: 'Query';
  affiliations: Array<Affiliation>;
  diagnosticTypes: Array<DiagnosticType>;
  diagnosticUnits: Array<DiagnosticUnit>;
  filterHelpSections: HelpSection;
  getInvite: Invite;
  getSection: RequiredSection;
  hasCreatedReport: Scalars['Boolean'];
  hasReport: Scalars['Boolean'];
  helpExampleSection: HelpExample;
  helpSections: HelpSection;
  helpSubSection: Section;
  isPublic: Scalars['Boolean'];
  isStarred: Scalars['Boolean'];
  myCoauthorReports: Array<Report>;
  myInvites: Array<Invite>;
  myStarredReports: Array<Report>;
  profile: User;
  publicReport: PublicReport;
  report?: Maybe<Report>;
  reports: Array<Report>;
  starredReportOwner?: Maybe<User>;
};


export type QueryAffiliationsArgs = {
  authorID: Scalars['ID'];
};


export type QueryFilterHelpSectionsArgs = {
  input: AirtableFilter;
  lang?: Scalars['String'];
};


export type QueryGetInviteArgs = {
  inviteID: Scalars['ID'];
};


export type QueryGetSectionArgs = {
  reportID: Scalars['ID'];
  sectionID: Scalars['ID'];
};


export type QueryHelpExampleSectionArgs = {
  id: Scalars['ID'];
  lang?: Scalars['String'];
};


export type QueryHelpSectionsArgs = {
  lang?: Scalars['String'];
};


export type QueryHelpSubSectionArgs = {
  id: Scalars['ID'];
  lang?: Scalars['String'];
};


export type QueryIsPublicArgs = {
  id: Scalars['ID'];
};


export type QueryIsStarredArgs = {
  reportID: Scalars['ID'];
};


export type QueryPublicReportArgs = {
  id: Scalars['ID'];
};


export type QueryReportArgs = {
  id: Scalars['ID'];
};


export type QueryStarredReportOwnerArgs = {
  reportID: Scalars['ID'];
};

export type Report = {
  __typename?: 'Report';
  attachments: Array<Attachment>;
  authors: Array<Author>;
  corrAuthor: Author;
  createdAt: Scalars['Time'];
  diagnostics: Array<Diagnostic>;
  id: Scalars['ID'];
  isPublic: Scalars['Boolean'];
  keywords?: Maybe<Scalars['String']>;
  owner: User;
  revisions: Array<ReportHistory>;
  sections: Array<RequiredSection>;
  tasks: Array<Task>;
  title: Scalars['String'];
  translation: Translation;
  updatedAt: Scalars['Time'];
  visits: Array<Visit>;
};

export type ReportHistory = {
  __typename?: 'ReportHistory';
  createdAt: Scalars['Time'];
  id: Scalars['ID'];
  keywords: Scalars['String'];
  name: Scalars['String'];
  owner: User;
  reportID: Scalars['String'];
  sections: Array<SectionHistory>;
  title: Scalars['String'];
};

export type RequiredSection = {
  __typename?: 'RequiredSection';
  comments: Array<SectionComment>;
  content?: Maybe<Scalars['String']>;
  createdAt: Scalars['Time'];
  heading: Scalars['String'];
  id: Scalars['ID'];
  lastEditedBy: Scalars['String'];
  order: Scalars['Int'];
  updatedAt: Scalars['Time'];
  versions: Array<SectionHistory>;
};

export type Section = {
  __typename?: 'Section';
  fields: Fields;
  id: Scalars['String'];
};

export type SectionComment = {
  __typename?: 'SectionComment';
  body: Scalars['String'];
  id: Scalars['ID'];
  owner: User;
  parentID: Scalars['String'];
};

export type SectionHistory = {
  __typename?: 'SectionHistory';
  content?: Maybe<Scalars['String']>;
  createdAt: Scalars['Time'];
  heading: Scalars['String'];
  id: Scalars['ID'];
  order: Scalars['Int'];
  owner: User;
  updatedAt: Scalars['Time'];
};

export type Task = {
  __typename?: 'Task';
  body: Scalars['String'];
  completed: Scalars['Boolean'];
  id: Scalars['ID'];
  user: User;
};

export type Translation = {
  __typename?: 'Translation';
  abstract: Scalars['String'];
  deletedAt?: Maybe<Scalars['Time']>;
  id: Scalars['ID'];
  keywords: Scalars['String'];
  reportID: Scalars['ID'];
  title: Scalars['String'];
};

export type UpdateAffiliation = {
  authorID?: InputMaybe<Scalars['ID']>;
  careUserID?: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  position: Scalars['String'];
};

export type UpdateAuthor = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
  reportID: Scalars['ID'];
};

export type UpdateTask = {
  body: Scalars['String'];
  completed: Scalars['Boolean'];
  id: Scalars['ID'];
  reportID: Scalars['ID'];
  userID: Scalars['ID'];
};

export type UpdateTranslation = {
  abstract: Scalars['String'];
  id: Scalars['ID'];
  keywords: Scalars['String'];
  reportID: Scalars['ID'];
  title: Scalars['String'];
};

export type UpdateVisit = {
  date: Scalars['Time'];
  id: Scalars['ID'];
  reportID: Scalars['ID'];
  summary: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  email: Scalars['String'];
  familyName: Scalars['String'];
  givenName: Scalars['String'];
  id: Scalars['ID'];
  myAffiliations: Array<Affiliation>;
  name: Scalars['String'];
  nickname: Scalars['String'];
  picture?: Maybe<Scalars['String']>;
  usermeta: UserMeta;
};

export type UserConfig = {
  addedToDrip: Scalars['Boolean'];
  completedProfile: Scalars['Boolean'];
  familyName: Scalars['String'];
  givenName: Scalars['String'];
  language: Scalars['String'];
  name: Scalars['String'];
  userID: Scalars['ID'];
};

export type UserMeta = {
  __typename?: 'UserMeta';
  addedToDrip: Scalars['Boolean'];
  language: Scalars['String'];
  profileCompleted: Scalars['Boolean'];
};

export type Visit = {
  __typename?: 'Visit';
  date: Scalars['Time'];
  id: Scalars['ID'];
  report: Report;
  summary: Scalars['String'];
};

export type AffiliationsQueryVariables = Exact<{
  authorID: Scalars['ID'];
}>;


export type AffiliationsQuery = { __typename?: 'Query', affiliations: Array<{ __typename?: 'Affiliation', id: string, name: string, position: string }> };

export type CreateAffiliationMutationVariables = Exact<{
  name: Scalars['String'];
  position: Scalars['String'];
  authorID?: InputMaybe<Scalars['ID']>;
}>;


export type CreateAffiliationMutation = { __typename?: 'Mutation', createAffiliation: { __typename?: 'Affiliation', id: string, name: string, position: string, authorID?: string | null, careUserID?: string | null } };

export type UpdateAffiliationMutationVariables = Exact<{
  id: Scalars['ID'];
  name: Scalars['String'];
  position: Scalars['String'];
  authorID?: InputMaybe<Scalars['ID']>;
}>;


export type UpdateAffiliationMutation = { __typename?: 'Mutation', updateAffiliation: { __typename?: 'Affiliation', id: string, name: string, position: string, authorID?: string | null, careUserID?: string | null } };

export type DeleteAffiliationMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteAffiliationMutation = { __typename?: 'Mutation', deleteAffiliation: { __typename?: 'Affiliation', id: string } };

export type SaveAttachmentMutationVariables = Exact<{
  reportID: Scalars['ID'];
  file: Scalars['Upload'];
  description: Scalars['String'];
  fileType: Scalars['String'];
}>;


export type SaveAttachmentMutation = { __typename?: 'Mutation', saveAttachment: { __typename?: 'Attachment', id: string, description: string, fileName: string, fileType: string, storageName: string, report: { __typename?: 'Report', id: string } } };

export type AllAttachmentsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type AllAttachmentsQuery = { __typename?: 'Query', report?: { __typename?: 'Report', id: string, attachments: Array<{ __typename?: 'Attachment', id: string, description: string, fileBlob?: string | null, fileName: string, fileType: string, storageName: string, order: number }> } | null };

export type AllPublicAttachmentsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type AllPublicAttachmentsQuery = { __typename?: 'Query', publicReport: { __typename?: 'PublicReport', id: string, attachments: Array<{ __typename?: 'Attachment', id: string, description: string, fileBlob?: string | null, fileName: string, fileType: string, storageName: string, order: number }> } };

export type UpdateAttachmentMutationVariables = Exact<{
  attachmentId: Scalars['ID'];
  reportId: Scalars['ID'];
  description: Scalars['String'];
}>;


export type UpdateAttachmentMutation = { __typename?: 'Mutation', updateAttachment: { __typename?: 'Attachment', id: string, description: string } };

export type UpdateAttachmentOrderMutationVariables = Exact<{
  attachmentId: Scalars['ID'];
  reportId: Scalars['ID'];
  order: Scalars['Int'];
  previousOrder: Scalars['Int'];
}>;


export type UpdateAttachmentOrderMutation = { __typename?: 'Mutation', updateAttachmentOrder: { __typename?: 'Report', id: string, attachments: Array<{ __typename?: 'Attachment', id: string, description: string, fileName: string, fileType: string, storageName: string, order: number }> } };

export type DeleteAttachmentMutationVariables = Exact<{
  id: Scalars['ID'];
  reportId: Scalars['ID'];
  order: Scalars['Int'];
}>;


export type DeleteAttachmentMutation = { __typename?: 'Mutation', deleteAttachment: { __typename?: 'Report', id: string } };

export type GetReportTitlesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetReportTitlesQuery = { __typename?: 'Query', reports: Array<{ __typename?: 'Report', id: string, title: string }> };

export type UserAttachmentsQueryVariables = Exact<{ [key: string]: never; }>;


export type UserAttachmentsQuery = { __typename?: 'Query', reports: Array<{ __typename?: 'Report', id: string, attachments: Array<{ __typename?: 'Attachment', id: string, description: string, fileBlob?: string | null, fileName: string, fileType: string, storageName: string, order: number }> }> };

export type AllAuthorsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type AllAuthorsQuery = { __typename?: 'Query', report?: { __typename?: 'Report', id: string, corrAuthor: { __typename?: 'Author', id: string }, authors: Array<{ __typename?: 'Author', id: string, firstName: string, lastName: string, email: string, order: number, careUserID?: string | null, user: { __typename?: 'User', id: string, givenName: string, familyName: string, email: string } }> } | null };

export type CreateAuthorMutationVariables = Exact<{
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  reportId: Scalars['ID'];
  careUserID?: InputMaybe<Scalars['ID']>;
}>;


export type CreateAuthorMutation = { __typename?: 'Mutation', createAuthor: { __typename?: 'Author', id: string, firstName: string, lastName: string, email: string, report: { __typename?: 'Report', id: string } } };

export type UpdateAuthorMutationVariables = Exact<{
  id: Scalars['ID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  reportId: Scalars['ID'];
}>;


export type UpdateAuthorMutation = { __typename?: 'Mutation', updateAuthor: { __typename?: 'Author', id: string } };

export type DeleteAuthorMutationVariables = Exact<{
  id: Scalars['ID'];
  reportId: Scalars['ID'];
  order: Scalars['Int'];
}>;


export type DeleteAuthorMutation = { __typename?: 'Mutation', deleteAuthor: { __typename?: 'Report', id: string } };

export type SetCorrespondingAuthorMutationVariables = Exact<{
  authorId: Scalars['ID'];
  reportId: Scalars['ID'];
}>;


export type SetCorrespondingAuthorMutation = { __typename?: 'Mutation', setCorrespondingAuthor: { __typename?: 'Report', id: string, corrAuthor: { __typename?: 'Author', id: string } } };

export type UpdateOrderMutationVariables = Exact<{
  authorId: Scalars['ID'];
  reportId: Scalars['ID'];
  order: Scalars['Int'];
  previousOrder: Scalars['Int'];
}>;


export type UpdateOrderMutation = { __typename?: 'Mutation', updateAuthorOrder: { __typename?: 'Report', id: string, corrAuthor: { __typename?: 'Author', id: string }, authors: Array<{ __typename?: 'Author', id: string, firstName: string, lastName: string, email: string, order: number }> } };

export type ReportsQueryVariables = Exact<{ [key: string]: never; }>;


export type ReportsQuery = { __typename?: 'Query', reports: Array<{ __typename?: 'Report', id: string, title: string, keywords?: string | null, createdAt: any, updatedAt: any, isPublic: boolean, sections: Array<{ __typename?: 'RequiredSection', id: string, heading: string, content?: string | null, updatedAt: any, createdAt: any, lastEditedBy: string, order: number }>, owner: { __typename?: 'User', id: string }, corrAuthor: { __typename?: 'Author', id: string, firstName: string, lastName: string, email: string, user: { __typename?: 'User', id: string, givenName: string, familyName: string, email: string } } }> };

export type ReportOwnerQueryVariables = Exact<{
  reportID: Scalars['ID'];
}>;


export type ReportOwnerQuery = { __typename?: 'Query', report?: { __typename?: 'Report', id: string, owner: { __typename?: 'User', id: string, email: string } } | null };

export type ReportQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ReportQuery = { __typename?: 'Query', report?: { __typename?: 'Report', id: string, isPublic: boolean, title: string, keywords?: string | null, createdAt: any, owner: { __typename?: 'User', id: string }, corrAuthor: { __typename?: 'Author', id: string, firstName: string, lastName: string, email: string, user: { __typename?: 'User', id: string, givenName: string, familyName: string, email: string } }, sections: Array<{ __typename?: 'RequiredSection', id: string, heading: string, content?: string | null, updatedAt: any }> } | null };

export type GetReportPdfQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetReportPdfQuery = { __typename?: 'Query', report?: { __typename?: 'Report', id: string, title: string, keywords?: string | null, owner: { __typename?: 'User', id: string }, corrAuthor: { __typename?: 'Author', id: string, firstName: string, lastName: string, email: string }, authors: Array<{ __typename?: 'Author', id: string, firstName: string, lastName: string, email: string, userAffiliations: Array<{ __typename?: 'Affiliation', id: string, name: string, position: string }>, affiliations: Array<{ __typename?: 'Affiliation', id: string, name: string, position: string }> }>, sections: Array<{ __typename?: 'RequiredSection', id: string, heading: string, content?: string | null }>, visits: Array<{ __typename?: 'Visit', id: string, date: any, summary: string }>, diagnostics: Array<{ __typename?: 'Diagnostic', id: string, value?: number | null, alternateValue?: number | null, defaultValue?: string | null, date: any, type: { __typename?: 'DiagnosticType', id: string, name: string }, unit?: { __typename?: 'DiagnosticUnit', id: string, name: string } | null }>, attachments: Array<{ __typename?: 'Attachment', id: string, description: string, fileBlob?: string | null, fileName: string, fileType: string, storageName: string, order: number }> } | null };

export type ReportContentQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ReportContentQuery = { __typename?: 'Query', report?: { __typename?: 'Report', id: string, sections: Array<{ __typename?: 'RequiredSection', id: string, content?: string | null }> } | null };

export type ReportSectionCommentsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ReportSectionCommentsQuery = { __typename?: 'Query', report?: { __typename?: 'Report', id: string, sections: Array<{ __typename?: 'RequiredSection', id: string, comments: Array<{ __typename?: 'SectionComment', id: string, body: string, parentID: string, owner: { __typename?: 'User', id: string, email: string } }> }> } | null };

export type ReportTasksQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ReportTasksQuery = { __typename?: 'Query', report?: { __typename?: 'Report', id: string, tasks: Array<{ __typename?: 'Task', id: string, body: string, completed: boolean, user: { __typename?: 'User', id: string } }> } | null };

export type CreateReportMutationVariables = Exact<{
  title: Scalars['String'];
  keywords: Scalars['String'];
}>;


export type CreateReportMutation = { __typename?: 'Mutation', createReport: { __typename?: 'Report', id: string, title: string, keywords?: string | null, owner: { __typename?: 'User', id: string, familyName: string, givenName: string, email: string }, sections: Array<{ __typename?: 'RequiredSection', id: string, order: number, heading: string }> } };

export type DeleteReportMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteReportMutation = { __typename?: 'Mutation', deleteReport: { __typename?: 'Report', id: string } };

export type UpdateTitleMutationVariables = Exact<{
  reportId: Scalars['ID'];
  title: Scalars['String'];
}>;


export type UpdateTitleMutation = { __typename?: 'Mutation', updateTitle: { __typename?: 'Report', id: string, title: string } };

export type UpdateKeywordsMutationVariables = Exact<{
  reportId: Scalars['ID'];
  keywords: Scalars['String'];
}>;


export type UpdateKeywordsMutation = { __typename?: 'Mutation', updateKeywords: { __typename?: 'Report', id: string, keywords?: string | null } };

export type CreateSectionCommentMutationVariables = Exact<{
  sectionID: Scalars['ID'];
  body: Scalars['String'];
  parentID: Scalars['ID'];
}>;


export type CreateSectionCommentMutation = { __typename?: 'Mutation', createSectionComment: { __typename?: 'SectionComment', id: string } };

export type CreateTaskMutationVariables = Exact<{
  reportID: Scalars['ID'];
  body: Scalars['String'];
  userID: Scalars['ID'];
}>;


export type CreateTaskMutation = { __typename?: 'Mutation', createTask: { __typename?: 'Report', id: string } };

export type DeleteTaskMutationVariables = Exact<{
  taskID: Scalars['String'];
  reportID: Scalars['String'];
}>;


export type DeleteTaskMutation = { __typename?: 'Mutation', deleteTask: { __typename?: 'Task', id: string } };

export type UpdateTaskMutationVariables = Exact<{
  reportID: Scalars['ID'];
  id: Scalars['ID'];
  userID: Scalars['ID'];
  body: Scalars['String'];
  completed: Scalars['Boolean'];
}>;


export type UpdateTaskMutation = { __typename?: 'Mutation', updateTask: { __typename?: 'Report', id: string, tasks: Array<{ __typename?: 'Task', id: string, body: string, completed: boolean, user: { __typename?: 'User', id: string } }> } };

export type DeleteSectionCommentMutationVariables = Exact<{
  sectionCommentID: Scalars['String'];
}>;


export type DeleteSectionCommentMutation = { __typename?: 'Mutation', deleteSectionComment: { __typename?: 'SectionComment', id: string } };

export type UpdateSectionCommentMutationVariables = Exact<{
  sectionCommentID: Scalars['String'];
  body: Scalars['String'];
}>;


export type UpdateSectionCommentMutation = { __typename?: 'Mutation', updateSectionComment: { __typename?: 'SectionComment', id: string, body: string } };

export type UserProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type UserProfileQuery = { __typename?: 'Query', profile: { __typename?: 'User', id: string, name: string, givenName: string, familyName: string, nickname: string, email: string, picture?: string | null, usermeta: { __typename?: 'UserMeta', language: string, addedToDrip: boolean, profileCompleted: boolean } } };

export type UpdateUserProfileMutationVariables = Exact<{
  userID: Scalars['ID'];
  familyName: Scalars['String'];
  givenName: Scalars['String'];
  name: Scalars['String'];
  lang: Scalars['String'];
  completedProfile: Scalars['Boolean'];
  addedToDrip: Scalars['Boolean'];
}>;


export type UpdateUserProfileMutation = { __typename?: 'Mutation', updateUser: { __typename?: 'ApiUser', id: string, familyName: string, givenName: string, name: string } };

export type UpdatePublicStatusMutationVariables = Exact<{
  reportId: Scalars['ID'];
  isPublic: Scalars['Boolean'];
}>;


export type UpdatePublicStatusMutation = { __typename?: 'Mutation', updatePublicStatus: boolean };

export type SendInvitationMutationVariables = Exact<{
  reportID: Scalars['ID'];
  inviteeEmail: Scalars['String'];
}>;


export type SendInvitationMutation = { __typename?: 'Mutation', sendInvitation: string };

export type RespondToInviteMutationVariables = Exact<{
  reportID: Scalars['ID'];
  email: Scalars['String'];
  response: Scalars['String'];
}>;


export type RespondToInviteMutation = { __typename?: 'Mutation', respondToInvite: boolean };

export type GetInviteQueryVariables = Exact<{
  inviteID: Scalars['ID'];
}>;


export type GetInviteQuery = { __typename?: 'Query', getInvite: { __typename?: 'Invite', id: string, status: string, inviteeEmail: string, user: { __typename?: 'User', id: string, name: string }, report: { __typename?: 'Report', id: string, title: string } } };

export type MyCoauthorReportsQueryVariables = Exact<{ [key: string]: never; }>;


export type MyCoauthorReportsQuery = { __typename?: 'Query', myCoauthorReports: Array<{ __typename?: 'Report', id: string, isPublic: boolean, title: string, keywords?: string | null, createdAt: any, updatedAt: any, corrAuthor: { __typename?: 'Author', id: string, firstName: string, lastName: string, email: string }, sections: Array<{ __typename?: 'RequiredSection', id: string, heading: string, content?: string | null, updatedAt: any }> }> };

export type HelpSectionsQueryVariables = Exact<{
  lang: Scalars['String'];
}>;


export type HelpSectionsQuery = { __typename?: 'Query', helpSections: { __typename?: 'HelpSection', Records: Array<{ __typename?: 'Section', id: string, fields: { __typename?: 'Fields', summary: string, title: string, examples: Array<string>, subsections: Array<string> } }> } };

export type HelpExampleSectionQueryVariables = Exact<{
  id: Scalars['ID'];
  lang: Scalars['String'];
}>;


export type HelpExampleSectionQuery = { __typename?: 'Query', helpExampleSection: { __typename?: 'HelpExample', id: string, fields: { __typename?: 'HelpExampleFields', title: string, content: string, citation: string } } };

export type HelpSubSectionQueryVariables = Exact<{
  id: Scalars['ID'];
  lang: Scalars['String'];
}>;


export type HelpSubSectionQuery = { __typename?: 'Query', helpSubSection: { __typename?: 'Section', id: string, fields: { __typename?: 'Fields', title: string, summary: string, examples: Array<string> } } };

export type FilterHelpSectionsQueryVariables = Exact<{
  filterKey: Scalars['String'];
  filterValue: Scalars['String'];
  lang: Scalars['String'];
}>;


export type FilterHelpSectionsQuery = { __typename?: 'Query', filterHelpSections: { __typename?: 'HelpSection', Records: Array<{ __typename?: 'Section', id: string, fields: { __typename?: 'Fields', summary: string } }> } };

export type MyInvitesQueryVariables = Exact<{ [key: string]: never; }>;


export type MyInvitesQuery = { __typename?: 'Query', myInvites: Array<{ __typename?: 'Invite', id: string, user: { __typename?: 'User', email: string }, report: { __typename?: 'Report', title: string, owner: { __typename?: 'User', email: string } } }> };

export type AllDiagnosticsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type AllDiagnosticsQuery = { __typename?: 'Query', report?: { __typename?: 'Report', id: string, title: string, diagnostics: Array<{ __typename?: 'Diagnostic', id: string, value?: number | null, alternateValue?: number | null, defaultValue?: string | null, date: any, type: { __typename?: 'DiagnosticType', id: string, name: string }, unit?: { __typename?: 'DiagnosticUnit', id: string, name: string } | null }> } | null };

export type AllPublicDiagnosticsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type AllPublicDiagnosticsQuery = { __typename?: 'Query', publicReport: { __typename?: 'PublicReport', id: string, title: string, diagnostics: Array<{ __typename?: 'Diagnostic', id: string, value?: number | null, alternateValue?: number | null, defaultValue?: string | null, date: any, type: { __typename?: 'DiagnosticType', id: string, name: string }, unit?: { __typename?: 'DiagnosticUnit', id: string, name: string } | null }> } };

export type CreateDiagnosticMutationVariables = Exact<{
  reportID: Scalars['ID'];
  unitID?: InputMaybe<Scalars['ID']>;
  typeID: Scalars['ID'];
  value?: InputMaybe<Scalars['Float']>;
  alternateValue?: InputMaybe<Scalars['Float']>;
  defaultValue?: InputMaybe<Scalars['String']>;
  date: Scalars['Time'];
}>;


export type CreateDiagnosticMutation = { __typename?: 'Mutation', createDiagnostic: { __typename?: 'Diagnostic', id: string } };

export type DeleteDiagnosticMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteDiagnosticMutation = { __typename?: 'Mutation', deleteDiagnostic: { __typename?: 'Diagnostic', id: string } };

export type GetTypesAndUnitsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTypesAndUnitsQuery = { __typename?: 'Query', diagnosticTypes: Array<{ __typename?: 'DiagnosticType', id: string, name: string, subtypes: Array<{ __typename?: 'DiagnosticSubtype', id: string, name: string }>, defaultValues: Array<{ __typename?: 'DiagnosticDefaultValue', id: string, name: string }> }>, diagnosticUnits: Array<{ __typename?: 'DiagnosticUnit', id: string, name: string }> };

export type UpdateRequiredSectionMutationVariables = Exact<{
  reportID: Scalars['ID'];
  sectionID: Scalars['ID'];
  content: Scalars['String'];
}>;


export type UpdateRequiredSectionMutation = { __typename?: 'Mutation', updateRequiredSection: { __typename?: 'Report', id: string, sections: Array<{ __typename?: 'RequiredSection', id: string, content?: string | null, updatedAt: any }> } };

export type UpdateLastEditorMutationVariables = Exact<{
  reportID: Scalars['ID'];
  sectionID: Scalars['ID'];
}>;


export type UpdateLastEditorMutation = { __typename?: 'Mutation', updateLastEditor: { __typename?: 'RequiredSection', id: string, updatedAt: any, lastEditedBy: string } };

export type ReportUpdatesQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ReportUpdatesQuery = { __typename?: 'Query', report?: { __typename?: 'Report', id: string, title: string, keywords?: string | null, updatedAt: any, isPublic: boolean, sections: Array<{ __typename?: 'RequiredSection', id: string, heading: string, content?: string | null, updatedAt: any, createdAt: any, lastEditedBy: string, order: number }>, owner: { __typename?: 'User', id: string }, corrAuthor: { __typename?: 'Author', id: string, firstName: string, lastName: string, email: string, user: { __typename?: 'User', id: string, givenName: string, familyName: string, email: string } } } | null };

export type RestoreRevisionMutationVariables = Exact<{
  revisionId: Scalars['ID'];
}>;


export type RestoreRevisionMutation = { __typename?: 'Mutation', restoreRevision: { __typename?: 'Report', id: string } };

export type SaveRevisionMutationVariables = Exact<{
  reportId: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
}>;


export type SaveRevisionMutation = { __typename?: 'Mutation', saveRevision: boolean };

export type ReportRevisionsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ReportRevisionsQuery = { __typename?: 'Query', report?: { __typename?: 'Report', id: string, title: string, keywords?: string | null, updatedAt: any, sections: Array<{ __typename?: 'RequiredSection', id: string, heading: string, content?: string | null, updatedAt: any, createdAt: any, lastEditedBy: string, order: number }>, revisions: Array<{ __typename?: 'ReportHistory', id: string, title: string, keywords: string, reportID: string, createdAt: any, name: string, owner: { __typename?: 'User', name: string }, sections: Array<{ __typename?: 'SectionHistory', id: string, heading: string, content?: string | null, order: number, updatedAt: any, createdAt: any }> }> } | null };

export type PublicReportQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type PublicReportQuery = { __typename?: 'Query', publicReport: { __typename?: 'PublicReport', id: string, isPublic: boolean, updatedAt: any, title: string, keywords?: string | null, authors: Array<{ __typename?: 'Author', id: string, lastName: string, firstName: string, careUserID?: string | null, email: string, order: number, userAffiliations: Array<{ __typename?: 'Affiliation', name: string, position: string }>, affiliations: Array<{ __typename?: 'Affiliation', name: string, position: string }>, user: { __typename?: 'User', id: string, givenName: string, familyName: string, email: string } }>, corrAuthor: { __typename?: 'Author', id: string, firstName: string, lastName: string, email: string, user: { __typename?: 'User', id: string, givenName: string, familyName: string, email: string } }, sections: Array<{ __typename?: 'RequiredSection', id: string, heading: string, content?: string | null, order: number, updatedAt: any }>, diagnostics: Array<{ __typename?: 'Diagnostic', id: string, value?: number | null, alternateValue?: number | null, defaultValue?: string | null, date: any, type: { __typename?: 'DiagnosticType', id: string, name: string }, unit?: { __typename?: 'DiagnosticUnit', id: string, name: string } | null }>, visits: Array<{ __typename?: 'Visit', id: string, summary: string, date: any }>, attachments: Array<{ __typename?: 'Attachment', id: string, description: string, fileBlob?: string | null, fileName: string, fileType: string, storageName: string, order: number }> } };

export type IsPublicQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type IsPublicQuery = { __typename?: 'Query', isPublic: boolean };

export type MyStarredReportsQueryVariables = Exact<{ [key: string]: never; }>;


export type MyStarredReportsQuery = { __typename?: 'Query', myStarredReports: Array<{ __typename?: 'Report', id: string, title: string, updatedAt: any, sections: Array<{ __typename?: 'RequiredSection', updatedAt: any }>, authors: Array<{ __typename?: 'Author', firstName: string, lastName: string, email: string, user: { __typename?: 'User', id: string, familyName: string, givenName: string, email: string } }> }> };

export type StarredReportOwnerQueryVariables = Exact<{
  reportID: Scalars['ID'];
}>;


export type StarredReportOwnerQuery = { __typename?: 'Query', starredReportOwner?: { __typename?: 'User', id: string, name: string, email: string } | null };

export type IsStarredQueryVariables = Exact<{
  reportID: Scalars['ID'];
}>;


export type IsStarredQuery = { __typename?: 'Query', isStarred: boolean };

export type CreateStarMutationVariables = Exact<{
  reportID: Scalars['ID'];
}>;


export type CreateStarMutation = { __typename?: 'Mutation', createStar: { __typename?: 'CreateStarResponse', id: string, reportTitle: string } };

export type DeleteStarMutationVariables = Exact<{
  reportID: Scalars['ID'];
}>;


export type DeleteStarMutation = { __typename?: 'Mutation', deleteStar: { __typename?: 'DeleteStarResponse', id: string, reportTitle: string } };

export type TranslationQueryVariables = Exact<{
  reportID: Scalars['ID'];
}>;


export type TranslationQuery = { __typename?: 'Query', report?: { __typename?: 'Report', id: string, translation: { __typename?: 'Translation', id: string, title: string, keywords: string, abstract: string, deletedAt?: any | null, reportID: string } } | null };

export type TranslateDeltaMutationVariables = Exact<{
  reportID: Scalars['ID'];
  sectionID: Scalars['ID'];
}>;


export type TranslateDeltaMutation = { __typename?: 'Mutation', translateDelta: string };

export type TranslateReportMutationVariables = Exact<{
  reportID: Scalars['ID'];
  sectionContent: Scalars['String'];
}>;


export type TranslateReportMutation = { __typename?: 'Mutation', translateReport: { __typename?: 'Translation', id: string, title: string, keywords: string, abstract: string, deletedAt?: any | null, reportID: string } };

export type DeleteTranslationMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteTranslationMutation = { __typename?: 'Mutation', deleteTranslation: { __typename?: 'Translation', id: string } };

export type RestoreTranslationMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type RestoreTranslationMutation = { __typename?: 'Mutation', restoreTranslation: { __typename?: 'Translation', id: string, title: string, keywords: string, abstract: string, deletedAt?: any | null, reportID: string } };

export type UpdateTranslationMutationVariables = Exact<{
  id: Scalars['ID'];
  reportID: Scalars['ID'];
  title: Scalars['String'];
  keywords: Scalars['String'];
  abstract: Scalars['String'];
}>;


export type UpdateTranslationMutation = { __typename?: 'Mutation', updateTranslation: { __typename?: 'Translation', id: string, title: string, keywords: string, abstract: string, deletedAt?: any | null, reportID: string } };

export type MyAffiliationsQueryVariables = Exact<{ [key: string]: never; }>;


export type MyAffiliationsQuery = { __typename?: 'Query', profile: { __typename?: 'User', id: string, myAffiliations: Array<{ __typename?: 'Affiliation', id: string, name: string, position: string }> } };

export type ReportAuthorAffiliationsQueryVariables = Exact<{
  reportID: Scalars['ID'];
}>;


export type ReportAuthorAffiliationsQuery = { __typename?: 'Query', report?: { __typename?: 'Report', id: string, authors: Array<{ __typename?: 'Author', id: string, lastName: string, firstName: string, careUserID?: string | null, email: string, userAffiliations: Array<{ __typename?: 'Affiliation', name: string, position: string }>, affiliations: Array<{ __typename?: 'Affiliation', name: string, position: string }>, user: { __typename?: 'User', id: string, givenName: string, familyName: string, email: string } }> } | null };

export type AllVisitsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type AllVisitsQuery = { __typename?: 'Query', report?: { __typename?: 'Report', id: string, visits: Array<{ __typename?: 'Visit', id: string, summary: string, date: any }> } | null };

export type AllPublicVisitsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type AllPublicVisitsQuery = { __typename?: 'Query', publicReport: { __typename?: 'PublicReport', id: string, visits: Array<{ __typename?: 'Visit', id: string, summary: string, date: any }> } };

export type CreateVisitMutationVariables = Exact<{
  reportID: Scalars['ID'];
  date: Scalars['Time'];
  summary: Scalars['String'];
}>;


export type CreateVisitMutation = { __typename?: 'Mutation', createVisit: { __typename?: 'Visit', id: string, date: any, summary: string } };

export type UpdateVisitMutationVariables = Exact<{
  id: Scalars['ID'];
  date: Scalars['Time'];
  summary: Scalars['String'];
  reportID: Scalars['ID'];
}>;


export type UpdateVisitMutation = { __typename?: 'Mutation', updateVisit: { __typename?: 'Visit', id: string } };

export type DeleteVisitMutationVariables = Exact<{
  id: Scalars['ID'];
  reportId: Scalars['ID'];
}>;


export type DeleteVisitMutation = { __typename?: 'Mutation', deleteVisit: { __typename?: 'Visit', id: string } };

export const AffiliationsDocument = gql`
    query Affiliations($authorID: ID!) {
  affiliations(authorID: $authorID) {
    id
    name
    position
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AffiliationsGQL extends Apollo.Query<AffiliationsQuery, AffiliationsQueryVariables> {
    override document = AffiliationsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateAffiliationDocument = gql`
    mutation CreateAffiliation($name: String!, $position: String!, $authorID: ID) {
  createAffiliation(
    input: {name: $name, position: $position, authorID: $authorID}
  ) {
    id
    name
    position
    authorID
    careUserID
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateAffiliationGQL extends Apollo.Mutation<CreateAffiliationMutation, CreateAffiliationMutationVariables> {
    override document = CreateAffiliationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateAffiliationDocument = gql`
    mutation UpdateAffiliation($id: ID!, $name: String!, $position: String!, $authorID: ID) {
  updateAffiliation(
    input: {id: $id, name: $name, position: $position, authorID: $authorID}
  ) {
    id
    name
    position
    authorID
    careUserID
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateAffiliationGQL extends Apollo.Mutation<UpdateAffiliationMutation, UpdateAffiliationMutationVariables> {
    override document = UpdateAffiliationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteAffiliationDocument = gql`
    mutation DeleteAffiliation($id: ID!) {
  deleteAffiliation(id: $id) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteAffiliationGQL extends Apollo.Mutation<DeleteAffiliationMutation, DeleteAffiliationMutationVariables> {
    override document = DeleteAffiliationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SaveAttachmentDocument = gql`
    mutation SaveAttachment($reportID: ID!, $file: Upload!, $description: String!, $fileType: String!) {
  saveAttachment(
    reportID: $reportID
    file: $file
    description: $description
    fileType: $fileType
  ) {
    id
    description
    fileName
    fileType
    storageName
    report {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SaveAttachmentGQL extends Apollo.Mutation<SaveAttachmentMutation, SaveAttachmentMutationVariables> {
    override document = SaveAttachmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AllAttachmentsDocument = gql`
    query AllAttachments($id: ID!) {
  report(id: $id) {
    id
    attachments {
      id
      description
      fileBlob
      fileName
      fileType
      storageName
      order
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AllAttachmentsGQL extends Apollo.Query<AllAttachmentsQuery, AllAttachmentsQueryVariables> {
    override document = AllAttachmentsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AllPublicAttachmentsDocument = gql`
    query AllPublicAttachments($id: ID!) {
  publicReport(id: $id) {
    id
    attachments {
      id
      description
      fileBlob
      fileName
      fileType
      storageName
      order
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AllPublicAttachmentsGQL extends Apollo.Query<AllPublicAttachmentsQuery, AllPublicAttachmentsQueryVariables> {
    override document = AllPublicAttachmentsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateAttachmentDocument = gql`
    mutation UpdateAttachment($attachmentId: ID!, $reportId: ID!, $description: String!) {
  updateAttachment(
    attachmentId: $attachmentId
    reportId: $reportId
    description: $description
  ) {
    id
    description
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateAttachmentGQL extends Apollo.Mutation<UpdateAttachmentMutation, UpdateAttachmentMutationVariables> {
    override document = UpdateAttachmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateAttachmentOrderDocument = gql`
    mutation UpdateAttachmentOrder($attachmentId: ID!, $reportId: ID!, $order: Int!, $previousOrder: Int!) {
  updateAttachmentOrder(
    attachmentId: $attachmentId
    reportId: $reportId
    order: $order
    previousOrder: $previousOrder
  ) {
    id
    attachments {
      id
      description
      fileName
      fileType
      storageName
      order
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateAttachmentOrderGQL extends Apollo.Mutation<UpdateAttachmentOrderMutation, UpdateAttachmentOrderMutationVariables> {
    override document = UpdateAttachmentOrderDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteAttachmentDocument = gql`
    mutation DeleteAttachment($id: ID!, $reportId: ID!, $order: Int!) {
  deleteAttachment(id: $id, reportId: $reportId, order: $order) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteAttachmentGQL extends Apollo.Mutation<DeleteAttachmentMutation, DeleteAttachmentMutationVariables> {
    override document = DeleteAttachmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetReportTitlesDocument = gql`
    query GetReportTitles {
  reports {
    id
    title
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetReportTitlesGQL extends Apollo.Query<GetReportTitlesQuery, GetReportTitlesQueryVariables> {
    override document = GetReportTitlesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UserAttachmentsDocument = gql`
    query UserAttachments {
  reports {
    id
    attachments {
      id
      description
      fileBlob
      fileName
      fileType
      storageName
      order
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UserAttachmentsGQL extends Apollo.Query<UserAttachmentsQuery, UserAttachmentsQueryVariables> {
    override document = UserAttachmentsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AllAuthorsDocument = gql`
    query AllAuthors($id: ID!) {
  report(id: $id) {
    id
    corrAuthor {
      id
    }
    authors {
      id
      firstName
      lastName
      email
      order
      careUserID
      user {
        id
        givenName
        familyName
        email
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AllAuthorsGQL extends Apollo.Query<AllAuthorsQuery, AllAuthorsQueryVariables> {
    override document = AllAuthorsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateAuthorDocument = gql`
    mutation CreateAuthor($firstName: String!, $lastName: String!, $email: String!, $reportId: ID!, $careUserID: ID) {
  createAuthor(
    input: {firstName: $firstName, lastName: $lastName, email: $email, reportID: $reportId, careUserID: $careUserID}
  ) {
    report {
      id
    }
    id
    firstName
    lastName
    email
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateAuthorGQL extends Apollo.Mutation<CreateAuthorMutation, CreateAuthorMutationVariables> {
    override document = CreateAuthorDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateAuthorDocument = gql`
    mutation UpdateAuthor($id: ID!, $firstName: String!, $lastName: String!, $email: String!, $reportId: ID!) {
  updateAuthor(
    input: {id: $id, firstName: $firstName, lastName: $lastName, email: $email, reportID: $reportId}
  ) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateAuthorGQL extends Apollo.Mutation<UpdateAuthorMutation, UpdateAuthorMutationVariables> {
    override document = UpdateAuthorDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteAuthorDocument = gql`
    mutation DeleteAuthor($id: ID!, $reportId: ID!, $order: Int!) {
  deleteAuthor(id: $id, reportId: $reportId, order: $order) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteAuthorGQL extends Apollo.Mutation<DeleteAuthorMutation, DeleteAuthorMutationVariables> {
    override document = DeleteAuthorDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SetCorrespondingAuthorDocument = gql`
    mutation SetCorrespondingAuthor($authorId: ID!, $reportId: ID!) {
  setCorrespondingAuthor(authorId: $authorId, reportId: $reportId) {
    id
    corrAuthor {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SetCorrespondingAuthorGQL extends Apollo.Mutation<SetCorrespondingAuthorMutation, SetCorrespondingAuthorMutationVariables> {
    override document = SetCorrespondingAuthorDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateOrderDocument = gql`
    mutation UpdateOrder($authorId: ID!, $reportId: ID!, $order: Int!, $previousOrder: Int!) {
  updateAuthorOrder(
    authorId: $authorId
    reportId: $reportId
    order: $order
    previousOrder: $previousOrder
  ) {
    id
    corrAuthor {
      id
    }
    authors {
      id
      firstName
      lastName
      email
      order
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateOrderGQL extends Apollo.Mutation<UpdateOrderMutation, UpdateOrderMutationVariables> {
    override document = UpdateOrderDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ReportsDocument = gql`
    query Reports {
  reports {
    id
    title
    keywords
    createdAt
    updatedAt
    isPublic
    sections {
      id
      heading
      content
      updatedAt
      createdAt
      lastEditedBy
      order
    }
    owner {
      id
    }
    corrAuthor {
      id
      firstName
      lastName
      email
      user {
        id
        givenName
        familyName
        email
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ReportsGQL extends Apollo.Query<ReportsQuery, ReportsQueryVariables> {
    override document = ReportsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ReportOwnerDocument = gql`
    query ReportOwner($reportID: ID!) {
  report(id: $reportID) {
    id
    owner {
      id
      email
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ReportOwnerGQL extends Apollo.Query<ReportOwnerQuery, ReportOwnerQueryVariables> {
    override document = ReportOwnerDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ReportDocument = gql`
    query Report($id: ID!) {
  report(id: $id) {
    id
    isPublic
    title
    keywords
    createdAt
    owner {
      id
    }
    corrAuthor {
      id
      firstName
      lastName
      email
      user {
        id
        givenName
        familyName
        email
      }
    }
    sections {
      id
      heading
      content
      updatedAt
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ReportGQL extends Apollo.Query<ReportQuery, ReportQueryVariables> {
    override document = ReportDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetReportPdfDocument = gql`
    query GetReportPDF($id: ID!) {
  report(id: $id) {
    id
    title
    keywords
    owner {
      id
    }
    corrAuthor {
      id
      firstName
      lastName
      email
    }
    authors {
      id
      firstName
      lastName
      email
      userAffiliations {
        id
        name
        position
      }
      affiliations {
        id
        name
        position
      }
    }
    sections {
      id
      heading
      content
    }
    visits {
      id
      date
      summary
    }
    diagnostics {
      id
      type {
        id
        name
      }
      value
      alternateValue
      defaultValue
      unit {
        id
        name
      }
      date
    }
    attachments {
      id
      description
      fileBlob
      fileName
      fileType
      storageName
      order
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetReportPdfGQL extends Apollo.Query<GetReportPdfQuery, GetReportPdfQueryVariables> {
    override document = GetReportPdfDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ReportContentDocument = gql`
    query ReportContent($id: ID!) {
  report(id: $id) {
    id
    sections {
      id
      content
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ReportContentGQL extends Apollo.Query<ReportContentQuery, ReportContentQueryVariables> {
    override document = ReportContentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ReportSectionCommentsDocument = gql`
    query ReportSectionComments($id: ID!) {
  report(id: $id) {
    id
    sections {
      id
      comments {
        id
        body
        parentID
        owner {
          id
          email
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ReportSectionCommentsGQL extends Apollo.Query<ReportSectionCommentsQuery, ReportSectionCommentsQueryVariables> {
    override document = ReportSectionCommentsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ReportTasksDocument = gql`
    query ReportTasks($id: ID!) {
  report(id: $id) {
    id
    tasks {
      id
      user {
        id
      }
      body
      completed
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ReportTasksGQL extends Apollo.Query<ReportTasksQuery, ReportTasksQueryVariables> {
    override document = ReportTasksDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateReportDocument = gql`
    mutation CreateReport($title: String!, $keywords: String!) {
  createReport(title: $title, keywords: $keywords) {
    id
    title
    keywords
    owner {
      id
      familyName
      givenName
      email
    }
    sections {
      id
      order
      heading
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateReportGQL extends Apollo.Mutation<CreateReportMutation, CreateReportMutationVariables> {
    override document = CreateReportDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteReportDocument = gql`
    mutation DeleteReport($id: ID!) {
  deleteReport(id: $id) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteReportGQL extends Apollo.Mutation<DeleteReportMutation, DeleteReportMutationVariables> {
    override document = DeleteReportDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateTitleDocument = gql`
    mutation UpdateTitle($reportId: ID!, $title: String!) {
  updateTitle(reportId: $reportId, title: $title) {
    id
    title
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateTitleGQL extends Apollo.Mutation<UpdateTitleMutation, UpdateTitleMutationVariables> {
    override document = UpdateTitleDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateKeywordsDocument = gql`
    mutation UpdateKeywords($reportId: ID!, $keywords: String!) {
  updateKeywords(reportId: $reportId, keywords: $keywords) {
    id
    keywords
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateKeywordsGQL extends Apollo.Mutation<UpdateKeywordsMutation, UpdateKeywordsMutationVariables> {
    override document = UpdateKeywordsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateSectionCommentDocument = gql`
    mutation CreateSectionComment($sectionID: ID!, $body: String!, $parentID: ID!) {
  createSectionComment(
    input: {sectionID: $sectionID, body: $body, parentID: $parentID}
  ) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateSectionCommentGQL extends Apollo.Mutation<CreateSectionCommentMutation, CreateSectionCommentMutationVariables> {
    override document = CreateSectionCommentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateTaskDocument = gql`
    mutation CreateTask($reportID: ID!, $body: String!, $userID: ID!) {
  createTask(input: {reportID: $reportID, body: $body, userID: $userID}) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateTaskGQL extends Apollo.Mutation<CreateTaskMutation, CreateTaskMutationVariables> {
    override document = CreateTaskDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteTaskDocument = gql`
    mutation DeleteTask($taskID: String!, $reportID: String!) {
  deleteTask(taskId: $taskID, reportID: $reportID) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteTaskGQL extends Apollo.Mutation<DeleteTaskMutation, DeleteTaskMutationVariables> {
    override document = DeleteTaskDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateTaskDocument = gql`
    mutation UpdateTask($reportID: ID!, $id: ID!, $userID: ID!, $body: String!, $completed: Boolean!) {
  updateTask(
    input: {reportID: $reportID, id: $id, userID: $userID, body: $body, completed: $completed}
  ) {
    id
    tasks {
      id
      body
      completed
      user {
        id
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateTaskGQL extends Apollo.Mutation<UpdateTaskMutation, UpdateTaskMutationVariables> {
    override document = UpdateTaskDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteSectionCommentDocument = gql`
    mutation DeleteSectionComment($sectionCommentID: String!) {
  deleteSectionComment(sectionCommentId: $sectionCommentID) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteSectionCommentGQL extends Apollo.Mutation<DeleteSectionCommentMutation, DeleteSectionCommentMutationVariables> {
    override document = DeleteSectionCommentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateSectionCommentDocument = gql`
    mutation UpdateSectionComment($sectionCommentID: String!, $body: String!) {
  updateSectionComment(sectionCommentId: $sectionCommentID, body: $body) {
    id
    body
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateSectionCommentGQL extends Apollo.Mutation<UpdateSectionCommentMutation, UpdateSectionCommentMutationVariables> {
    override document = UpdateSectionCommentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UserProfileDocument = gql`
    query UserProfile {
  profile {
    id
    name
    givenName
    familyName
    nickname
    email
    picture
    usermeta {
      language
      addedToDrip
      profileCompleted
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UserProfileGQL extends Apollo.Query<UserProfileQuery, UserProfileQueryVariables> {
    override document = UserProfileDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateUserProfileDocument = gql`
    mutation UpdateUserProfile($userID: ID!, $familyName: String!, $givenName: String!, $name: String!, $lang: String!, $completedProfile: Boolean!, $addedToDrip: Boolean!) {
  updateUser(
    input: {userID: $userID, familyName: $familyName, givenName: $givenName, name: $name, language: $lang, completedProfile: $completedProfile, addedToDrip: $addedToDrip}
  ) {
    id
    familyName
    givenName
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateUserProfileGQL extends Apollo.Mutation<UpdateUserProfileMutation, UpdateUserProfileMutationVariables> {
    override document = UpdateUserProfileDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdatePublicStatusDocument = gql`
    mutation UpdatePublicStatus($reportId: ID!, $isPublic: Boolean!) {
  updatePublicStatus(reportId: $reportId, isPublic: $isPublic)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdatePublicStatusGQL extends Apollo.Mutation<UpdatePublicStatusMutation, UpdatePublicStatusMutationVariables> {
    override document = UpdatePublicStatusDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SendInvitationDocument = gql`
    mutation SendInvitation($reportID: ID!, $inviteeEmail: String!) {
  sendInvitation(reportID: $reportID, inviteeEmail: $inviteeEmail)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SendInvitationGQL extends Apollo.Mutation<SendInvitationMutation, SendInvitationMutationVariables> {
    override document = SendInvitationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RespondToInviteDocument = gql`
    mutation RespondToInvite($reportID: ID!, $email: String!, $response: String!) {
  respondToInvite(reportID: $reportID, email: $email, response: $response)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RespondToInviteGQL extends Apollo.Mutation<RespondToInviteMutation, RespondToInviteMutationVariables> {
    override document = RespondToInviteDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetInviteDocument = gql`
    query GetInvite($inviteID: ID!) {
  getInvite(inviteID: $inviteID) {
    id
    status
    inviteeEmail
    user {
      id
      name
    }
    report {
      id
      title
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetInviteGQL extends Apollo.Query<GetInviteQuery, GetInviteQueryVariables> {
    override document = GetInviteDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MyCoauthorReportsDocument = gql`
    query MyCoauthorReports {
  myCoauthorReports {
    id
    isPublic
    title
    keywords
    corrAuthor {
      id
      firstName
      lastName
      email
    }
    sections {
      id
      heading
      content
      updatedAt
    }
    createdAt
    updatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MyCoauthorReportsGQL extends Apollo.Query<MyCoauthorReportsQuery, MyCoauthorReportsQueryVariables> {
    override document = MyCoauthorReportsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const HelpSectionsDocument = gql`
    query HelpSections($lang: String!) {
  helpSections(lang: $lang) {
    Records {
      id
      fields {
        summary
        title
        examples
        subsections
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class HelpSectionsGQL extends Apollo.Query<HelpSectionsQuery, HelpSectionsQueryVariables> {
    override document = HelpSectionsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const HelpExampleSectionDocument = gql`
    query HelpExampleSection($id: ID!, $lang: String!) {
  helpExampleSection(id: $id, lang: $lang) {
    id
    fields {
      title
      content
      citation
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class HelpExampleSectionGQL extends Apollo.Query<HelpExampleSectionQuery, HelpExampleSectionQueryVariables> {
    override document = HelpExampleSectionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const HelpSubSectionDocument = gql`
    query HelpSubSection($id: ID!, $lang: String!) {
  helpSubSection(id: $id, lang: $lang) {
    id
    fields {
      title
      summary
      examples
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class HelpSubSectionGQL extends Apollo.Query<HelpSubSectionQuery, HelpSubSectionQueryVariables> {
    override document = HelpSubSectionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FilterHelpSectionsDocument = gql`
    query FilterHelpSections($filterKey: String!, $filterValue: String!, $lang: String!) {
  filterHelpSections(
    input: {filterKey: $filterKey, filterValue: $filterValue}
    lang: $lang
  ) {
    Records {
      id
      fields {
        summary
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FilterHelpSectionsGQL extends Apollo.Query<FilterHelpSectionsQuery, FilterHelpSectionsQueryVariables> {
    override document = FilterHelpSectionsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MyInvitesDocument = gql`
    query MyInvites {
  myInvites {
    id
    user {
      email
    }
    report {
      title
      owner {
        email
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MyInvitesGQL extends Apollo.Query<MyInvitesQuery, MyInvitesQueryVariables> {
    override document = MyInvitesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AllDiagnosticsDocument = gql`
    query AllDiagnostics($id: ID!) {
  report(id: $id) {
    id
    title
    diagnostics {
      id
      type {
        id
        name
      }
      value
      alternateValue
      defaultValue
      unit {
        id
        name
      }
      date
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AllDiagnosticsGQL extends Apollo.Query<AllDiagnosticsQuery, AllDiagnosticsQueryVariables> {
    override document = AllDiagnosticsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AllPublicDiagnosticsDocument = gql`
    query AllPublicDiagnostics($id: ID!) {
  publicReport(id: $id) {
    id
    title
    diagnostics {
      id
      type {
        id
        name
      }
      value
      alternateValue
      defaultValue
      unit {
        id
        name
      }
      date
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AllPublicDiagnosticsGQL extends Apollo.Query<AllPublicDiagnosticsQuery, AllPublicDiagnosticsQueryVariables> {
    override document = AllPublicDiagnosticsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateDiagnosticDocument = gql`
    mutation CreateDiagnostic($reportID: ID!, $unitID: ID, $typeID: ID!, $value: Float, $alternateValue: Float, $defaultValue: String, $date: Time!) {
  createDiagnostic(
    input: {date: $date, value: $value, alternateValue: $alternateValue, defaultValue: $defaultValue, reportID: $reportID, unitID: $unitID, typeID: $typeID}
  ) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateDiagnosticGQL extends Apollo.Mutation<CreateDiagnosticMutation, CreateDiagnosticMutationVariables> {
    override document = CreateDiagnosticDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteDiagnosticDocument = gql`
    mutation DeleteDiagnostic($id: ID!) {
  deleteDiagnostic(id: $id) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteDiagnosticGQL extends Apollo.Mutation<DeleteDiagnosticMutation, DeleteDiagnosticMutationVariables> {
    override document = DeleteDiagnosticDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTypesAndUnitsDocument = gql`
    query GetTypesAndUnits {
  diagnosticTypes {
    id
    name
    subtypes {
      id
      name
    }
    defaultValues {
      id
      name
    }
  }
  diagnosticUnits {
    id
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTypesAndUnitsGQL extends Apollo.Query<GetTypesAndUnitsQuery, GetTypesAndUnitsQueryVariables> {
    override document = GetTypesAndUnitsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateRequiredSectionDocument = gql`
    mutation UpdateRequiredSection($reportID: ID!, $sectionID: ID!, $content: String!) {
  updateRequiredSection(
    reportID: $reportID
    sectionID: $sectionID
    content: $content
  ) {
    id
    sections {
      id
      content
      updatedAt
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateRequiredSectionGQL extends Apollo.Mutation<UpdateRequiredSectionMutation, UpdateRequiredSectionMutationVariables> {
    override document = UpdateRequiredSectionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateLastEditorDocument = gql`
    mutation UpdateLastEditor($reportID: ID!, $sectionID: ID!) {
  updateLastEditor(reportID: $reportID, sectionID: $sectionID) {
    id
    updatedAt
    lastEditedBy
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateLastEditorGQL extends Apollo.Mutation<UpdateLastEditorMutation, UpdateLastEditorMutationVariables> {
    override document = UpdateLastEditorDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ReportUpdatesDocument = gql`
    query ReportUpdates($id: ID!) {
  report(id: $id) {
    id
    title
    keywords
    updatedAt
    isPublic
    sections {
      id
      heading
      content
      updatedAt
      createdAt
      lastEditedBy
      order
    }
    owner {
      id
    }
    corrAuthor {
      id
      firstName
      lastName
      email
      user {
        id
        givenName
        familyName
        email
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ReportUpdatesGQL extends Apollo.Query<ReportUpdatesQuery, ReportUpdatesQueryVariables> {
    override document = ReportUpdatesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RestoreRevisionDocument = gql`
    mutation RestoreRevision($revisionId: ID!) {
  restoreRevision(revisionId: $revisionId) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RestoreRevisionGQL extends Apollo.Mutation<RestoreRevisionMutation, RestoreRevisionMutationVariables> {
    override document = RestoreRevisionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SaveRevisionDocument = gql`
    mutation SaveRevision($reportId: ID!, $name: String) {
  saveRevision(reportId: $reportId, name: $name)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SaveRevisionGQL extends Apollo.Mutation<SaveRevisionMutation, SaveRevisionMutationVariables> {
    override document = SaveRevisionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ReportRevisionsDocument = gql`
    query ReportRevisions($id: ID!) {
  report(id: $id) {
    id
    title
    keywords
    updatedAt
    sections {
      id
      heading
      content
      updatedAt
      createdAt
      lastEditedBy
      order
    }
    revisions {
      id
      title
      keywords
      reportID
      createdAt
      name
      owner {
        name
      }
      sections {
        id
        heading
        content
        order
        updatedAt
        createdAt
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ReportRevisionsGQL extends Apollo.Query<ReportRevisionsQuery, ReportRevisionsQueryVariables> {
    override document = ReportRevisionsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PublicReportDocument = gql`
    query PublicReport($id: ID!) {
  publicReport(id: $id) {
    id
    isPublic
    updatedAt
    authors {
      id
      lastName
      firstName
      careUserID
      email
      order
      userAffiliations {
        name
        position
      }
      affiliations {
        name
        position
      }
      user {
        id
        givenName
        familyName
        email
      }
    }
    corrAuthor {
      id
      firstName
      lastName
      email
      user {
        id
        givenName
        familyName
        email
      }
    }
    title
    keywords
    sections {
      id
      heading
      content
      order
      updatedAt
    }
    diagnostics {
      id
      type {
        id
        name
      }
      value
      alternateValue
      defaultValue
      unit {
        id
        name
      }
      date
    }
    visits {
      id
      summary
      date
    }
    attachments {
      id
      description
      fileBlob
      fileName
      fileType
      storageName
      order
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PublicReportGQL extends Apollo.Query<PublicReportQuery, PublicReportQueryVariables> {
    override document = PublicReportDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const IsPublicDocument = gql`
    query IsPublic($id: ID!) {
  isPublic(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class IsPublicGQL extends Apollo.Query<IsPublicQuery, IsPublicQueryVariables> {
    override document = IsPublicDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MyStarredReportsDocument = gql`
    query MyStarredReports {
  myStarredReports {
    id
    title
    updatedAt
    sections {
      updatedAt
    }
    authors {
      firstName
      lastName
      email
      user {
        id
        familyName
        givenName
        email
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MyStarredReportsGQL extends Apollo.Query<MyStarredReportsQuery, MyStarredReportsQueryVariables> {
    override document = MyStarredReportsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const StarredReportOwnerDocument = gql`
    query StarredReportOwner($reportID: ID!) {
  starredReportOwner(reportID: $reportID) {
    id
    name
    email
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class StarredReportOwnerGQL extends Apollo.Query<StarredReportOwnerQuery, StarredReportOwnerQueryVariables> {
    override document = StarredReportOwnerDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const IsStarredDocument = gql`
    query IsStarred($reportID: ID!) {
  isStarred(reportID: $reportID)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class IsStarredGQL extends Apollo.Query<IsStarredQuery, IsStarredQueryVariables> {
    override document = IsStarredDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateStarDocument = gql`
    mutation CreateStar($reportID: ID!) {
  createStar(reportID: $reportID) {
    id
    reportTitle
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateStarGQL extends Apollo.Mutation<CreateStarMutation, CreateStarMutationVariables> {
    override document = CreateStarDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteStarDocument = gql`
    mutation DeleteStar($reportID: ID!) {
  deleteStar(reportID: $reportID) {
    id
    reportTitle
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteStarGQL extends Apollo.Mutation<DeleteStarMutation, DeleteStarMutationVariables> {
    override document = DeleteStarDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TranslationDocument = gql`
    query Translation($reportID: ID!) {
  report(id: $reportID) {
    id
    translation {
      id
      title
      keywords
      abstract
      deletedAt
      reportID
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TranslationGQL extends Apollo.Query<TranslationQuery, TranslationQueryVariables> {
    override document = TranslationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TranslateDeltaDocument = gql`
    mutation TranslateDelta($reportID: ID!, $sectionID: ID!) {
  translateDelta(reportID: $reportID, sectionID: $sectionID)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TranslateDeltaGQL extends Apollo.Mutation<TranslateDeltaMutation, TranslateDeltaMutationVariables> {
    override document = TranslateDeltaDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TranslateReportDocument = gql`
    mutation TranslateReport($reportID: ID!, $sectionContent: String!) {
  translateReport(reportID: $reportID, sectionContent: $sectionContent) {
    id
    title
    keywords
    abstract
    deletedAt
    reportID
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TranslateReportGQL extends Apollo.Mutation<TranslateReportMutation, TranslateReportMutationVariables> {
    override document = TranslateReportDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteTranslationDocument = gql`
    mutation DeleteTranslation($id: String!) {
  deleteTranslation(id: $id) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteTranslationGQL extends Apollo.Mutation<DeleteTranslationMutation, DeleteTranslationMutationVariables> {
    override document = DeleteTranslationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RestoreTranslationDocument = gql`
    mutation RestoreTranslation($id: String!) {
  restoreTranslation(id: $id) {
    id
    title
    keywords
    abstract
    deletedAt
    reportID
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RestoreTranslationGQL extends Apollo.Mutation<RestoreTranslationMutation, RestoreTranslationMutationVariables> {
    override document = RestoreTranslationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateTranslationDocument = gql`
    mutation UpdateTranslation($id: ID!, $reportID: ID!, $title: String!, $keywords: String!, $abstract: String!) {
  updateTranslation(
    input: {id: $id, reportID: $reportID, title: $title, keywords: $keywords, abstract: $abstract}
  ) {
    id
    title
    keywords
    abstract
    deletedAt
    reportID
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateTranslationGQL extends Apollo.Mutation<UpdateTranslationMutation, UpdateTranslationMutationVariables> {
    override document = UpdateTranslationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MyAffiliationsDocument = gql`
    query MyAffiliations {
  profile {
    id
    myAffiliations {
      id
      name
      position
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MyAffiliationsGQL extends Apollo.Query<MyAffiliationsQuery, MyAffiliationsQueryVariables> {
    override document = MyAffiliationsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ReportAuthorAffiliationsDocument = gql`
    query ReportAuthorAffiliations($reportID: ID!) {
  report(id: $reportID) {
    id
    authors {
      id
      lastName
      firstName
      careUserID
      email
      userAffiliations {
        name
        position
      }
      affiliations {
        name
        position
      }
      user {
        id
        givenName
        familyName
        email
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ReportAuthorAffiliationsGQL extends Apollo.Query<ReportAuthorAffiliationsQuery, ReportAuthorAffiliationsQueryVariables> {
    override document = ReportAuthorAffiliationsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AllVisitsDocument = gql`
    query AllVisits($id: ID!) {
  report(id: $id) {
    id
    visits {
      id
      summary
      date
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AllVisitsGQL extends Apollo.Query<AllVisitsQuery, AllVisitsQueryVariables> {
    override document = AllVisitsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AllPublicVisitsDocument = gql`
    query AllPublicVisits($id: ID!) {
  publicReport(id: $id) {
    id
    visits {
      id
      summary
      date
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AllPublicVisitsGQL extends Apollo.Query<AllPublicVisitsQuery, AllPublicVisitsQueryVariables> {
    override document = AllPublicVisitsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateVisitDocument = gql`
    mutation CreateVisit($reportID: ID!, $date: Time!, $summary: String!) {
  createVisit(input: {reportID: $reportID, date: $date, summary: $summary}) {
    id
    date
    summary
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateVisitGQL extends Apollo.Mutation<CreateVisitMutation, CreateVisitMutationVariables> {
    override document = CreateVisitDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateVisitDocument = gql`
    mutation UpdateVisit($id: ID!, $date: Time!, $summary: String!, $reportID: ID!) {
  updateVisit(
    input: {id: $id, date: $date, summary: $summary, reportID: $reportID}
  ) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateVisitGQL extends Apollo.Mutation<UpdateVisitMutation, UpdateVisitMutationVariables> {
    override document = UpdateVisitDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteVisitDocument = gql`
    mutation DeleteVisit($id: ID!, $reportId: ID!) {
  deleteVisit(id: $id, reportId: $reportId) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteVisitGQL extends Apollo.Mutation<DeleteVisitMutation, DeleteVisitMutationVariables> {
    override document = DeleteVisitDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }

export const Affiliations = gql`
    query Affiliations($authorID: ID!) {
  affiliations(authorID: $authorID) {
    id
    name
    position
  }
}
    `;
export const CreateAffiliation = gql`
    mutation CreateAffiliation($name: String!, $position: String!, $authorID: ID) {
  createAffiliation(
    input: {name: $name, position: $position, authorID: $authorID}
  ) {
    id
    name
    position
    authorID
    careUserID
  }
}
    `;
export const UpdateAffiliation = gql`
    mutation UpdateAffiliation($id: ID!, $name: String!, $position: String!, $authorID: ID) {
  updateAffiliation(
    input: {id: $id, name: $name, position: $position, authorID: $authorID}
  ) {
    id
    name
    position
    authorID
    careUserID
  }
}
    `;
export const DeleteAffiliation = gql`
    mutation DeleteAffiliation($id: ID!) {
  deleteAffiliation(id: $id) {
    id
  }
}
    `;
export const SaveAttachment = gql`
    mutation SaveAttachment($reportID: ID!, $file: Upload!, $description: String!, $fileType: String!) {
  saveAttachment(
    reportID: $reportID
    file: $file
    description: $description
    fileType: $fileType
  ) {
    id
    description
    fileName
    fileType
    storageName
    report {
      id
    }
  }
}
    `;
export const AllAttachments = gql`
    query AllAttachments($id: ID!) {
  report(id: $id) {
    id
    attachments {
      id
      description
      fileBlob
      fileName
      fileType
      storageName
      order
    }
  }
}
    `;
export const AllPublicAttachments = gql`
    query AllPublicAttachments($id: ID!) {
  publicReport(id: $id) {
    id
    attachments {
      id
      description
      fileBlob
      fileName
      fileType
      storageName
      order
    }
  }
}
    `;
export const UpdateAttachment = gql`
    mutation UpdateAttachment($attachmentId: ID!, $reportId: ID!, $description: String!) {
  updateAttachment(
    attachmentId: $attachmentId
    reportId: $reportId
    description: $description
  ) {
    id
    description
  }
}
    `;
export const UpdateAttachmentOrder = gql`
    mutation UpdateAttachmentOrder($attachmentId: ID!, $reportId: ID!, $order: Int!, $previousOrder: Int!) {
  updateAttachmentOrder(
    attachmentId: $attachmentId
    reportId: $reportId
    order: $order
    previousOrder: $previousOrder
  ) {
    id
    attachments {
      id
      description
      fileName
      fileType
      storageName
      order
    }
  }
}
    `;
export const DeleteAttachment = gql`
    mutation DeleteAttachment($id: ID!, $reportId: ID!, $order: Int!) {
  deleteAttachment(id: $id, reportId: $reportId, order: $order) {
    id
  }
}
    `;
export const GetReportTitles = gql`
    query GetReportTitles {
  reports {
    id
    title
  }
}
    `;
export const UserAttachments = gql`
    query UserAttachments {
  reports {
    id
    attachments {
      id
      description
      fileBlob
      fileName
      fileType
      storageName
      order
    }
  }
}
    `;
export const AllAuthors = gql`
    query AllAuthors($id: ID!) {
  report(id: $id) {
    id
    corrAuthor {
      id
    }
    authors {
      id
      firstName
      lastName
      email
      order
      careUserID
      user {
        id
        givenName
        familyName
        email
      }
    }
  }
}
    `;
export const CreateAuthor = gql`
    mutation CreateAuthor($firstName: String!, $lastName: String!, $email: String!, $reportId: ID!, $careUserID: ID) {
  createAuthor(
    input: {firstName: $firstName, lastName: $lastName, email: $email, reportID: $reportId, careUserID: $careUserID}
  ) {
    report {
      id
    }
    id
    firstName
    lastName
    email
  }
}
    `;
export const UpdateAuthor = gql`
    mutation UpdateAuthor($id: ID!, $firstName: String!, $lastName: String!, $email: String!, $reportId: ID!) {
  updateAuthor(
    input: {id: $id, firstName: $firstName, lastName: $lastName, email: $email, reportID: $reportId}
  ) {
    id
  }
}
    `;
export const DeleteAuthor = gql`
    mutation DeleteAuthor($id: ID!, $reportId: ID!, $order: Int!) {
  deleteAuthor(id: $id, reportId: $reportId, order: $order) {
    id
  }
}
    `;
export const SetCorrespondingAuthor = gql`
    mutation SetCorrespondingAuthor($authorId: ID!, $reportId: ID!) {
  setCorrespondingAuthor(authorId: $authorId, reportId: $reportId) {
    id
    corrAuthor {
      id
    }
  }
}
    `;
export const UpdateOrder = gql`
    mutation UpdateOrder($authorId: ID!, $reportId: ID!, $order: Int!, $previousOrder: Int!) {
  updateAuthorOrder(
    authorId: $authorId
    reportId: $reportId
    order: $order
    previousOrder: $previousOrder
  ) {
    id
    corrAuthor {
      id
    }
    authors {
      id
      firstName
      lastName
      email
      order
    }
  }
}
    `;
export const Reports = gql`
    query Reports {
  reports {
    id
    title
    keywords
    createdAt
    updatedAt
    isPublic
    sections {
      id
      heading
      content
      updatedAt
      createdAt
      lastEditedBy
      order
    }
    owner {
      id
    }
    corrAuthor {
      id
      firstName
      lastName
      email
      user {
        id
        givenName
        familyName
        email
      }
    }
  }
}
    `;
export const ReportOwner = gql`
    query ReportOwner($reportID: ID!) {
  report(id: $reportID) {
    id
    owner {
      id
      email
    }
  }
}
    `;
export const Report = gql`
    query Report($id: ID!) {
  report(id: $id) {
    id
    isPublic
    title
    keywords
    createdAt
    owner {
      id
    }
    corrAuthor {
      id
      firstName
      lastName
      email
      user {
        id
        givenName
        familyName
        email
      }
    }
    sections {
      id
      heading
      content
      updatedAt
    }
  }
}
    `;
export const GetReportPdf = gql`
    query GetReportPDF($id: ID!) {
  report(id: $id) {
    id
    title
    keywords
    owner {
      id
    }
    corrAuthor {
      id
      firstName
      lastName
      email
    }
    authors {
      id
      firstName
      lastName
      email
      userAffiliations {
        id
        name
        position
      }
      affiliations {
        id
        name
        position
      }
    }
    sections {
      id
      heading
      content
    }
    visits {
      id
      date
      summary
    }
    diagnostics {
      id
      type {
        id
        name
      }
      value
      alternateValue
      defaultValue
      unit {
        id
        name
      }
      date
    }
    attachments {
      id
      description
      fileBlob
      fileName
      fileType
      storageName
      order
    }
  }
}
    `;
export const ReportContent = gql`
    query ReportContent($id: ID!) {
  report(id: $id) {
    id
    sections {
      id
      content
    }
  }
}
    `;
export const ReportSectionComments = gql`
    query ReportSectionComments($id: ID!) {
  report(id: $id) {
    id
    sections {
      id
      comments {
        id
        body
        parentID
        owner {
          id
          email
        }
      }
    }
  }
}
    `;
export const ReportTasks = gql`
    query ReportTasks($id: ID!) {
  report(id: $id) {
    id
    tasks {
      id
      user {
        id
      }
      body
      completed
    }
  }
}
    `;
export const CreateReport = gql`
    mutation CreateReport($title: String!, $keywords: String!) {
  createReport(title: $title, keywords: $keywords) {
    id
    title
    keywords
    owner {
      id
      familyName
      givenName
      email
    }
    sections {
      id
      order
      heading
    }
  }
}
    `;
export const DeleteReport = gql`
    mutation DeleteReport($id: ID!) {
  deleteReport(id: $id) {
    id
  }
}
    `;
export const UpdateTitle = gql`
    mutation UpdateTitle($reportId: ID!, $title: String!) {
  updateTitle(reportId: $reportId, title: $title) {
    id
    title
  }
}
    `;
export const UpdateKeywords = gql`
    mutation UpdateKeywords($reportId: ID!, $keywords: String!) {
  updateKeywords(reportId: $reportId, keywords: $keywords) {
    id
    keywords
  }
}
    `;
export const CreateSectionComment = gql`
    mutation CreateSectionComment($sectionID: ID!, $body: String!, $parentID: ID!) {
  createSectionComment(
    input: {sectionID: $sectionID, body: $body, parentID: $parentID}
  ) {
    id
  }
}
    `;
export const CreateTask = gql`
    mutation CreateTask($reportID: ID!, $body: String!, $userID: ID!) {
  createTask(input: {reportID: $reportID, body: $body, userID: $userID}) {
    id
  }
}
    `;
export const DeleteTask = gql`
    mutation DeleteTask($taskID: String!, $reportID: String!) {
  deleteTask(taskId: $taskID, reportID: $reportID) {
    id
  }
}
    `;
export const UpdateTask = gql`
    mutation UpdateTask($reportID: ID!, $id: ID!, $userID: ID!, $body: String!, $completed: Boolean!) {
  updateTask(
    input: {reportID: $reportID, id: $id, userID: $userID, body: $body, completed: $completed}
  ) {
    id
    tasks {
      id
      body
      completed
      user {
        id
      }
    }
  }
}
    `;
export const DeleteSectionComment = gql`
    mutation DeleteSectionComment($sectionCommentID: String!) {
  deleteSectionComment(sectionCommentId: $sectionCommentID) {
    id
  }
}
    `;
export const UpdateSectionComment = gql`
    mutation UpdateSectionComment($sectionCommentID: String!, $body: String!) {
  updateSectionComment(sectionCommentId: $sectionCommentID, body: $body) {
    id
    body
  }
}
    `;
export const UserProfile = gql`
    query UserProfile {
  profile {
    id
    name
    givenName
    familyName
    nickname
    email
    picture
    usermeta {
      language
      addedToDrip
      profileCompleted
    }
  }
}
    `;
export const UpdateUserProfile = gql`
    mutation UpdateUserProfile($userID: ID!, $familyName: String!, $givenName: String!, $name: String!, $lang: String!, $completedProfile: Boolean!, $addedToDrip: Boolean!) {
  updateUser(
    input: {userID: $userID, familyName: $familyName, givenName: $givenName, name: $name, language: $lang, completedProfile: $completedProfile, addedToDrip: $addedToDrip}
  ) {
    id
    familyName
    givenName
    name
  }
}
    `;
export const UpdatePublicStatus = gql`
    mutation UpdatePublicStatus($reportId: ID!, $isPublic: Boolean!) {
  updatePublicStatus(reportId: $reportId, isPublic: $isPublic)
}
    `;
export const SendInvitation = gql`
    mutation SendInvitation($reportID: ID!, $inviteeEmail: String!) {
  sendInvitation(reportID: $reportID, inviteeEmail: $inviteeEmail)
}
    `;
export const RespondToInvite = gql`
    mutation RespondToInvite($reportID: ID!, $email: String!, $response: String!) {
  respondToInvite(reportID: $reportID, email: $email, response: $response)
}
    `;
export const GetInvite = gql`
    query GetInvite($inviteID: ID!) {
  getInvite(inviteID: $inviteID) {
    id
    status
    inviteeEmail
    user {
      id
      name
    }
    report {
      id
      title
    }
  }
}
    `;
export const MyCoauthorReports = gql`
    query MyCoauthorReports {
  myCoauthorReports {
    id
    isPublic
    title
    keywords
    corrAuthor {
      id
      firstName
      lastName
      email
    }
    sections {
      id
      heading
      content
      updatedAt
    }
    createdAt
    updatedAt
  }
}
    `;
export const HelpSections = gql`
    query HelpSections($lang: String!) {
  helpSections(lang: $lang) {
    Records {
      id
      fields {
        summary
        title
        examples
        subsections
      }
    }
  }
}
    `;
export const HelpExampleSection = gql`
    query HelpExampleSection($id: ID!, $lang: String!) {
  helpExampleSection(id: $id, lang: $lang) {
    id
    fields {
      title
      content
      citation
    }
  }
}
    `;
export const HelpSubSection = gql`
    query HelpSubSection($id: ID!, $lang: String!) {
  helpSubSection(id: $id, lang: $lang) {
    id
    fields {
      title
      summary
      examples
    }
  }
}
    `;
export const FilterHelpSections = gql`
    query FilterHelpSections($filterKey: String!, $filterValue: String!, $lang: String!) {
  filterHelpSections(
    input: {filterKey: $filterKey, filterValue: $filterValue}
    lang: $lang
  ) {
    Records {
      id
      fields {
        summary
      }
    }
  }
}
    `;
export const MyInvites = gql`
    query MyInvites {
  myInvites {
    id
    user {
      email
    }
    report {
      title
      owner {
        email
      }
    }
  }
}
    `;
export const AllDiagnostics = gql`
    query AllDiagnostics($id: ID!) {
  report(id: $id) {
    id
    title
    diagnostics {
      id
      type {
        id
        name
      }
      value
      alternateValue
      defaultValue
      unit {
        id
        name
      }
      date
    }
  }
}
    `;
export const AllPublicDiagnostics = gql`
    query AllPublicDiagnostics($id: ID!) {
  publicReport(id: $id) {
    id
    title
    diagnostics {
      id
      type {
        id
        name
      }
      value
      alternateValue
      defaultValue
      unit {
        id
        name
      }
      date
    }
  }
}
    `;
export const CreateDiagnostic = gql`
    mutation CreateDiagnostic($reportID: ID!, $unitID: ID, $typeID: ID!, $value: Float, $alternateValue: Float, $defaultValue: String, $date: Time!) {
  createDiagnostic(
    input: {date: $date, value: $value, alternateValue: $alternateValue, defaultValue: $defaultValue, reportID: $reportID, unitID: $unitID, typeID: $typeID}
  ) {
    id
  }
}
    `;
export const DeleteDiagnostic = gql`
    mutation DeleteDiagnostic($id: ID!) {
  deleteDiagnostic(id: $id) {
    id
  }
}
    `;
export const GetTypesAndUnits = gql`
    query GetTypesAndUnits {
  diagnosticTypes {
    id
    name
    subtypes {
      id
      name
    }
    defaultValues {
      id
      name
    }
  }
  diagnosticUnits {
    id
    name
  }
}
    `;
export const UpdateRequiredSection = gql`
    mutation UpdateRequiredSection($reportID: ID!, $sectionID: ID!, $content: String!) {
  updateRequiredSection(
    reportID: $reportID
    sectionID: $sectionID
    content: $content
  ) {
    id
    sections {
      id
      content
      updatedAt
    }
  }
}
    `;
export const UpdateLastEditor = gql`
    mutation UpdateLastEditor($reportID: ID!, $sectionID: ID!) {
  updateLastEditor(reportID: $reportID, sectionID: $sectionID) {
    id
    updatedAt
    lastEditedBy
  }
}
    `;
export const ReportUpdates = gql`
    query ReportUpdates($id: ID!) {
  report(id: $id) {
    id
    title
    keywords
    updatedAt
    isPublic
    sections {
      id
      heading
      content
      updatedAt
      createdAt
      lastEditedBy
      order
    }
    owner {
      id
    }
    corrAuthor {
      id
      firstName
      lastName
      email
      user {
        id
        givenName
        familyName
        email
      }
    }
  }
}
    `;
export const RestoreRevision = gql`
    mutation RestoreRevision($revisionId: ID!) {
  restoreRevision(revisionId: $revisionId) {
    id
  }
}
    `;
export const SaveRevision = gql`
    mutation SaveRevision($reportId: ID!, $name: String) {
  saveRevision(reportId: $reportId, name: $name)
}
    `;
export const ReportRevisions = gql`
    query ReportRevisions($id: ID!) {
  report(id: $id) {
    id
    title
    keywords
    updatedAt
    sections {
      id
      heading
      content
      updatedAt
      createdAt
      lastEditedBy
      order
    }
    revisions {
      id
      title
      keywords
      reportID
      createdAt
      name
      owner {
        name
      }
      sections {
        id
        heading
        content
        order
        updatedAt
        createdAt
      }
    }
  }
}
    `;
export const PublicReport = gql`
    query PublicReport($id: ID!) {
  publicReport(id: $id) {
    id
    isPublic
    updatedAt
    authors {
      id
      lastName
      firstName
      careUserID
      email
      order
      userAffiliations {
        name
        position
      }
      affiliations {
        name
        position
      }
      user {
        id
        givenName
        familyName
        email
      }
    }
    corrAuthor {
      id
      firstName
      lastName
      email
      user {
        id
        givenName
        familyName
        email
      }
    }
    title
    keywords
    sections {
      id
      heading
      content
      order
      updatedAt
    }
    diagnostics {
      id
      type {
        id
        name
      }
      value
      alternateValue
      defaultValue
      unit {
        id
        name
      }
      date
    }
    visits {
      id
      summary
      date
    }
    attachments {
      id
      description
      fileBlob
      fileName
      fileType
      storageName
      order
    }
  }
}
    `;
export const IsPublic = gql`
    query IsPublic($id: ID!) {
  isPublic(id: $id)
}
    `;
export const MyStarredReports = gql`
    query MyStarredReports {
  myStarredReports {
    id
    title
    updatedAt
    sections {
      updatedAt
    }
    authors {
      firstName
      lastName
      email
      user {
        id
        familyName
        givenName
        email
      }
    }
  }
}
    `;
export const StarredReportOwner = gql`
    query StarredReportOwner($reportID: ID!) {
  starredReportOwner(reportID: $reportID) {
    id
    name
    email
  }
}
    `;
export const IsStarred = gql`
    query IsStarred($reportID: ID!) {
  isStarred(reportID: $reportID)
}
    `;
export const CreateStar = gql`
    mutation CreateStar($reportID: ID!) {
  createStar(reportID: $reportID) {
    id
    reportTitle
  }
}
    `;
export const DeleteStar = gql`
    mutation DeleteStar($reportID: ID!) {
  deleteStar(reportID: $reportID) {
    id
    reportTitle
  }
}
    `;
export const Translation = gql`
    query Translation($reportID: ID!) {
  report(id: $reportID) {
    id
    translation {
      id
      title
      keywords
      abstract
      deletedAt
      reportID
    }
  }
}
    `;
export const TranslateDelta = gql`
    mutation TranslateDelta($reportID: ID!, $sectionID: ID!) {
  translateDelta(reportID: $reportID, sectionID: $sectionID)
}
    `;
export const TranslateReport = gql`
    mutation TranslateReport($reportID: ID!, $sectionContent: String!) {
  translateReport(reportID: $reportID, sectionContent: $sectionContent) {
    id
    title
    keywords
    abstract
    deletedAt
    reportID
  }
}
    `;
export const DeleteTranslation = gql`
    mutation DeleteTranslation($id: String!) {
  deleteTranslation(id: $id) {
    id
  }
}
    `;
export const RestoreTranslation = gql`
    mutation RestoreTranslation($id: String!) {
  restoreTranslation(id: $id) {
    id
    title
    keywords
    abstract
    deletedAt
    reportID
  }
}
    `;
export const UpdateTranslation = gql`
    mutation UpdateTranslation($id: ID!, $reportID: ID!, $title: String!, $keywords: String!, $abstract: String!) {
  updateTranslation(
    input: {id: $id, reportID: $reportID, title: $title, keywords: $keywords, abstract: $abstract}
  ) {
    id
    title
    keywords
    abstract
    deletedAt
    reportID
  }
}
    `;
export const MyAffiliations = gql`
    query MyAffiliations {
  profile {
    id
    myAffiliations {
      id
      name
      position
    }
  }
}
    `;
export const ReportAuthorAffiliations = gql`
    query ReportAuthorAffiliations($reportID: ID!) {
  report(id: $reportID) {
    id
    authors {
      id
      lastName
      firstName
      careUserID
      email
      userAffiliations {
        name
        position
      }
      affiliations {
        name
        position
      }
      user {
        id
        givenName
        familyName
        email
      }
    }
  }
}
    `;
export const AllVisits = gql`
    query AllVisits($id: ID!) {
  report(id: $id) {
    id
    visits {
      id
      summary
      date
    }
  }
}
    `;
export const AllPublicVisits = gql`
    query AllPublicVisits($id: ID!) {
  publicReport(id: $id) {
    id
    visits {
      id
      summary
      date
    }
  }
}
    `;
export const CreateVisit = gql`
    mutation CreateVisit($reportID: ID!, $date: Time!, $summary: String!) {
  createVisit(input: {reportID: $reportID, date: $date, summary: $summary}) {
    id
    date
    summary
  }
}
    `;
export const UpdateVisit = gql`
    mutation UpdateVisit($id: ID!, $date: Time!, $summary: String!, $reportID: ID!) {
  updateVisit(
    input: {id: $id, date: $date, summary: $summary, reportID: $reportID}
  ) {
    id
  }
}
    `;
export const DeleteVisit = gql`
    mutation DeleteVisit($id: ID!, $reportId: ID!) {
  deleteVisit(id: $id, reportId: $reportId) {
    id
  }
}
    `;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {}
};
      export default result;
    