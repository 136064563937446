<div class="container">

  <mat-card class="login-card">

    <div id="cw-logo">
      <img src="assets/cw-logo.png">
    </div>
    <div class="btn-container">
      <a mat-button href="https://care-writer.com/how-to-use" target="_blank">{{'LINKS.HOW TO' | translate}}</a>
      <a mat-button href="https://care-writer.com/about" target="_blank">{{'LINKS.ABOUT' | translate}}</a>
    </div>
    <mat-card-title>
      <p id="login-welcome">{{ 'MESSAGES.WELCOME' | translate }}</p>
    </mat-card-title>

    <div class="btn-container">
      <button mat-flat-button color="accent" (click)="signup()">
        {{'MESSAGES.SIGN UP' | translate}}
      </button>
    </div>

    <mat-card-footer>
      <mat-divider inset="true"></mat-divider>
      <span class="mat-small">Already have an account? <a href="javascript:void(0)" (click)="login()"
          class="care-link">{{'ACTIONS.LOG IN' | translate}}</a></span>
    </mat-card-footer>
  </mat-card>
</div>

<ng-template #loadingTemp>
  <mat-spinner></mat-spinner>
</ng-template>