<div class="container">
  <ng-container *ngIf="(dataSource$ | async) as dataSource">
    <h3 class="mat-title mt">{{'CASE SECTION.DIAGNOSTICS' | translate}}</h3>
    <mat-table [dataSource]="dataSource" class="mat-elevation-z1">

      <ng-container matColumnDef="type">
        <mat-header-cell *matHeaderCellDef> {{'MISC.TYPE' | translate}} </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <app-delete-diagnostic *ngIf="!isPreview" [obsId]="element.id" [reportId]="reportID"></app-delete-diagnostic>
          {{element.type.name}} 
        </mat-cell>
      </ng-container>
    
      <ng-container matColumnDef="value">
        <mat-header-cell *matHeaderCellDef> {{'MISC.VALUE' | translate}} </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <ng-container *ngIf="element.value && !element.alternateValue">
            <span>{{element.value}}</span>
          </ng-container>
          <ng-container *ngIf="element.value && element.alternateValue">
            <span>{{element.value}}/{{element.alternateValue}}</span>
          </ng-container>
          <ng-container *ngIf="element.defaultValue">
            <span>{{element.defaultValue}}</span>
          </ng-container>
        </mat-cell>
      </ng-container>
    

      <ng-container matColumnDef="unit">
        <mat-header-cell *matHeaderCellDef> {{'MISC.UNIT' | translate}} </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.unit.name}} </mat-cell>
      </ng-container>
    
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

      <ng-container matColumnDef="dateHeader">
        <mat-cell class="date-header" *matCellDef="let dh"><strong>{{dh.dateHeader | date:'yyyy-MM-dd': 'UTC'}}</strong></mat-cell>
      </ng-container>
      
      <mat-row class="date-row" *matRowDef="let row; columns: ['dateHeader']; when: isDateHeader"> </mat-row>

    </mat-table>

  </ng-container>
</div>

<ng-template #loading>
  <mat-progress-spinner mode="indeterminate" diameter="20"></mat-progress-spinner>
</ng-template>