import { Component, Input } from '@angular/core';
import { AllVisitsDocument, DeleteVisitGQL } from '../../../api/generated/graphql';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';

declare let gtag: Function;

@Component({
  selector: 'app-delete-visit',
  templateUrl: './delete-visit.component.html',
  styleUrls: ['./delete-visit.component.css']
})
export class DeleteVisitComponent {

  @Input() noteId: string = '';
  @Input() reportId: string = '';
  error: any;

  constructor(
    private _snackBar: MatSnackBar,
    private translate: TranslateService,
    private deleteVisitGQL: DeleteVisitGQL) { }

  delete() {
    
    this.deleteVisitGQL
      .mutate({
        id: this.noteId,
        reportId: this.reportId
      }, {
        refetchQueries: [{ query: AllVisitsDocument, variables: { id: this.reportId } }]
      })
      .subscribe({
        error: err => {
          this.error = err;
          console.error(err);
        },
        complete: ()=> this.onSuccess()
      }
      );
  }

  onSuccess() {
    this.sendEvent();

    this.translate.get(['SNACKS.VISIT DELETED', 'SNACKS.DISMISS']).pipe(first()).subscribe((res: any) => {
      this._snackBar.open(res['SNACKS.VISIT DELETED'], res['SNACKS.DISMISS'], {
        duration: 3000,
      });

    })
  }

  sendEvent(): void {
    gtag('event', 'delete_visit', {
      'event_category': 'db-delete',
      'event_label': 'method'
    });
  }

}
