import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Report, ReportDocument, ReportRevisionsDocument, ReportRevisionsGQL, RestoreRevisionGQL, SaveRevisionGQL } from '../api/generated/graphql';

@Injectable({
  providedIn: 'root'
})
export class RevisionsService {

  constructor(
    private revisionsGQL: ReportRevisionsGQL,
    private saveRevisionGQL: SaveRevisionGQL,
    private restoreRevisionGQL: RestoreRevisionGQL,
  ) { }

  public getRevisions(reportID: string): Observable<Report> {
    return this.revisionsGQL.fetch({id: reportID}).pipe(map((result: any) => {
      return result.data.report
    }))
  }

  public restoreRevision(revisionID: string, reportID: string): Observable<Report> {
    return this.restoreRevisionGQL.mutate({revisionId: revisionID}, {
      refetchQueries: [{ query: ReportDocument, variables: { id: reportID } }]
    }).pipe(
      map((result: any) => {
        return result.data.report
      })
    )
  }

  public saveRevision(reportID: string, name: string): Observable<boolean> {
    return this.saveRevisionGQL.mutate({reportId: reportID, name: name}, {
      refetchQueries: [{query: ReportRevisionsDocument, variables: {id: reportID}}]
    }).pipe(
      map((result: any) => {
        return result;
      })
    )
  }

}
