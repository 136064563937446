<ng-container *ngIf="data">
  <mat-card class="mat-elevation-z0">
    <button class="btn-close" mat-icon-button disableRipple mat-dialog-close><mat-icon>close</mat-icon></button>
    <button class="btn-add" (click)="addGroup()" mat-button><mat-icon>add</mat-icon>&nbsp;{{'ACTIONS.ADD' | translate}}</button>
    <mat-card-title>{{'SUBHEADINGS.MANAGE MY AFFILIATIONS' | translate}}</mat-card-title>
    <mat-card-subtitle class="mat-body">{{'MESSAGES.ADD UPDATE AFFILIATIONS' | translate}}</mat-card-subtitle>
    <mat-card-content>
      <ng-container *ngIf="newGroup">
        <div class="input-group" [formGroup]="newGroup">
          <label class="mat-body">{{'FORMS.NAME' | translate}}</label>
          <input type="text" name="name" formControlName="name" />
          <div *ngIf="newGroup.controls.name?.invalid && (newGroup.controls.name?.dirty || newGroup.controls.name?.touched)">
            <div class="input-error" *ngIf="newGroup.controls.name.errors.required">{{'FORMS.REQUIRED' | translate}}</div>
          </div>
          <label class="mat-body">{{'FORMS.POS DESC' | translate}}</label>
          <input type="text" name="position" formControlName="position" />
          <div *ngIf="newGroup.controls.position?.invalid && (newGroup.controls.position?.dirty || newGroup.controls.position?.touched)">
            <div class="input-error" *ngIf="newGroup.controls.position.errors.required">{{'FORMS.REQUIRED' | translate}}</div>
          </div>
        </div>
        <button mat-flat-button [disabled]="!newGroup.valid" class="btn-save" color="accent" (click)="saveNewAffiliation(newGroup)">{{'ACTIONS.SAVE AFFILIATIONS' | translate}}</button>
        <button mat-button (click)="cancelNewGroup()">
          {{'ACTIONS.CANCEL' | translate}}
        </button>
        <mat-divider class="divider"></mat-divider>
      </ng-container>
      <ng-container *ngFor="let group of formArray.controls; let i = index">
        <div class="input-group" [formGroup]="formArray.controls[i]">
          <span class="ordering mat-body">{{i+1}}.</span>
          <label class="mat-body">{{'FORMS.NAME' | translate}}</label>
          <input type="text" name="name" formControlName="name" />
          <label class="mat-body">{{'FORMS.POS DESC' | translate}}</label>
          <input type="text" name="position" formControlName="position" />
          <button mat-icon-button class="btn-remove" (click)="deleteItem(group, i)">
            <mat-icon matTooltip="{{'ACTIONS.REMOVE' | translate}}" class="material-icons-outlined">remove_circle</mat-icon>
          </button>
        </div>
        <button mat-flat-button *ngIf="!group.pristine" class="btn-save" color="accent" (click)="updateAffiliation(group)">{{'ACTIONS.SAVE' | translate}}</button>
      </ng-container>
    </mat-card-content>
  </mat-card>
</ng-container>
