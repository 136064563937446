import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';
import { first } from 'rxjs/operators';
import { Invite } from 'src/app/api/generated/graphql';
import { InvitationsService } from '../../services/invitations.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit {

  invites$: BehaviorSubject<Invite[] | null>;

  constructor(
    private invitesService: InvitationsService,
  ) {
    this.invites$ = new BehaviorSubject(null);
   }

  ngOnInit(): void {
    this.invitesService.getMyInvites().pipe(first()).subscribe(this.invites$)
  }

}
