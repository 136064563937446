import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AllAttachmentsDocument, SaveAttachmentGQL } from 'src/app/api/generated/graphql';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';

declare let gtag: Function;

@Component({
  selector: 'app-new-attachment',
  templateUrl: './new-attachment.component.html',
  styleUrls: ['./new-attachment.component.css']
})
export class NewAttachmentComponent implements OnInit {
  
  @ViewChild('descriptionText')
  descriptionText!: ElementRef;

  @Input() reportId: string = '';
  @Input() fileToUpload: File | null = null;
  @Output() closePreview = new EventEmitter<any>()

  graphicForm: FormGroup;

  loading: boolean = false
  error: any;
  
  constructor(
    private _snackBar: MatSnackBar,
    private translate: TranslateService,
    private saveAttachmentGQL: SaveAttachmentGQL
    ) {
    this.graphicForm = new FormGroup({
      description: new FormControl('', { validators: [Validators.maxLength(500)] }),
    }, { updateOn: 'change' });
    }

  ngOnInit() {
    
  }

  onSubmit(form: FormGroup, event: any) {
    let valid = this.validator(form)

    if (valid) {
      this.save(form)
      // the next two lines are a workaround because since we're
      //using the same form (not rebuilding the view) there isn't
      // any clean way to reset validation and values
      event.currentTarget.reset()
    }
  }

  validator(form: FormGroup) {
    if (form.invalid) {
      return false;
    }

    return true;
  }

  cancelUpload() {
    this.closePreview.emit();
  }

  onSuccess() {
    this.clearFormControls();
    this.cancelUpload();

    this.translate.get(['SNACKS.ATTACHMENT IMPORTED', 'SNACKS.DISMISS']).pipe(first()).subscribe((res: any) => {
      this._snackBar.open(res['SNACKS.ATTACHMENT IMPORTED'], res['SNACKS.DISMISS'], {
        duration: 3000,
      });
    })
  }

  save(form: FormGroup) {
    const fileT = this.fileToUpload?.type;
    this.saveAttachmentGQL
      .mutate({
        reportID: this.reportId,
        file: this.fileToUpload,
        fileType: fileT || '',
        description: form.controls.description.value.trim() || ""
      }, {
        context: {
          useMultipart: true
        },
        refetchQueries: [{ query: AllAttachmentsDocument, variables: { id: this.reportId } }],
      })
      .subscribe({
        complete: () => {this.onSuccess(), this.sendEvent()},
        error: (err: any) => {
          this.error = err;
          console.error(err);
        }
      });
  }

  clearFormControls() {
    this.graphicForm.reset();
  }

  get description() { return this.graphicForm.get('description'); }

  sendEvent(): void {
    gtag('event', 'create attachment', {
      'event_category': 'db-create',
      'event_label': 'method'
    });
  }

}
