import { Component, OnInit, Input, Renderer2, ElementRef, ViewChild, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import Quill from 'quill';
import { formatOptions } from './utils';
import { fromEvent, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, first, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { RequiredSectionsService } from '../../services/required-sections/required-sections.service';
import { RequiredSection } from 'src/app/api/generated/graphql';
import { ReportService } from 'src/app/services/report/report.service';

@Component({
  selector: 'app-quill-editor',
  templateUrl: './quill-editor.component.html',
  styleUrls: ['./quill-editor.component.css'],
})
export class QuillEditorComponent implements OnInit, OnDestroy, OnChanges {

  @Input() reportID: string = '';
  @Input() sectionID: string = '';
  @Input() readOnly: boolean | undefined;

  sectionName: string = '';
  unsubscribe$: Subject<void> = new Subject<void>();
  show: boolean = false;
  content!: string;

  @ViewChild('container', { read: ElementRef, static: true })
  container!: ElementRef;
  
  error: any;
  quill: Quill;

  constructor(
    private renderer: Renderer2,
    private sectionService: RequiredSectionsService,
    private reportService: ReportService,
  ) {}
  
  ngOnInit() {
    if (this.sectionID && this.reportID) {
      const obs = this.reportService.getSectionsAsObservable().pipe(first());
      obs.subscribe({
        next: (sections) => {
          if (!sections.length) return;
          let section: any;
          section = sections.filter(s => s.id === this.sectionID)[0];
          this.content = section.content;
          if (section.heading === "Patient Perspective") {
            this.sectionName = "perspective";
          } else {
            this.sectionName = section.heading;
          }
          this.createToolbar();
          this.createEditor();
          this.initEditor();
          }
        })
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.readOnly.firstChange) return;
    const val = changes.readOnly.currentValue;
    this.toggleEditor(val);
  }

  ngOnDestroy() {
    this.stopRecentUpdates();
  }

  createEditor() {
    const div = this.renderer.createElement('div');
    this.renderer.setProperty(div, "id", this.sectionName)
    this.renderer.appendChild(this.container.nativeElement, div);
  }

  createToolbar() {
    let div = this.renderer.createElement('div');
    this.renderer.setProperty(div, "id", this.sectionName+'-toolbar')
    
    div = this.btnFactory(div);
    
    this.renderer.appendChild(this.container.nativeElement, div);
  }

  initEditor() {
    var options = {
      placeholder: "",
      readOnly: true,
      theme: 'snow',
      formats: formatOptions,
      history: {
        delay: 2000,
        maxStack: 500,
        userOnly: true
      },
      modules: {
        toolbar: {
          container: `#${this.sectionName}-toolbar`,
        }
      }
    };

    const name = "#"+this.sectionName;
    this.quill = new Quill(name, options);
    this.quill.setContents(JSON.parse(this.content));
    this.monitorApiUpdates();
  }

  toggleEditor(readOnly: boolean): void {
    this.stopRecentUpdates();

    if (readOnly) {
      this.monitorApiUpdates();
      this.quill.disable();
      return;
    }
    this.quill.enable();
    this.monitorUserInput();
    const cursorPos = this.quill.getSelection(true).index;
    this.quill.setSelection(cursorPos);
  }

  monitorUserInput() {
    if (this.readOnly) return;

    fromEvent(this.quill, 'text-change').pipe(
      debounceTime(250),
      distinctUntilChanged(),
      switchMap(() => {
        return this.save();
      }),
      catchError((err: any) => {
        console.error(err);
        this.error = err;
        return of(null)
      }),
      takeUntil(this.unsubscribe$),
    ).subscribe()
  }

  async save() {
    const content = JSON.stringify(this.quill.getContents());

    const obs = this.sectionService.updateRequiredSection(this.sectionID, this.reportID, content);

    obs.pipe(
      take(1),
      map( (result: any) => {
        let section: RequiredSection;
        section = result.sections.filter( s => s.id === this.sectionID)
        return section[0];
      }),
      ).subscribe({
        error: (err: any) => {
          console.error(err);
          this.error = err;
        }
      })
  }
  
  monitorApiUpdates(): void {
    if (!this.readOnly) return;
    const obs = this.reportService.getSectionsAsObservable();

    obs.pipe(takeUntil(this.unsubscribe$)).subscribe({
      next: (sections) => {
        if (!sections.length) return;
        let section: any;
        section = sections.filter(s => s.id === this.sectionID)[0];
        if (section.content === '{}') return;
        if (section.content !== this.content) {
          this.content = section.content;
          this.quill.setContents(JSON.parse(section.content));
        }
      }
    })
  }

  stopRecentUpdates(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.unsubscribe$ = new Subject<void>();
  }

  btnFactory(div: HTMLElement): HTMLElement {
    
    const list = [
      {
      class: 'ql-bold',
      value: null,
      },
      {
        class: 'ql-italic',
        value: null,
      },
      {
        class: 'ql-underline',
        value: null,
      },
      {
        class: 'ql-list',
        value: 'ordered',
      },
      {
        class: 'ql-list',
        value: 'bullet',
      },
      {
        class: 'ql-script',
        value: 'sub',
      },
      {
        class: 'ql-script',
        value: 'super',
      },
      {
        class: 'ql-clean',
        value: null,
      }
    ]
    
    list.forEach((item) => {
      const btn = this.renderer.createElement('button');
      this.renderer.addClass(btn, item.class);
      if (item.value) {
        this.renderer.setAttribute(btn, 'value', item.value);
      }
      this.renderer.appendChild(div, btn);
    })
    return div;
  }
  
}
