<ng-container *ngIf="attachments$ | async">
  <ng-container *ngFor="let a of attachments$ | async; let i = index">
    <div class="container">
      <h3 class="mat-title">{{'SUBHEADINGS.FIGURE' | translate}} {{i+1}}</h3>
      <div class="img-container">
        <img [src]="'data:image/png;base64,' + a.fileBlob">
      </div>
      <div *ngIf="a.description?.length" class="description"><span class="mat-body">{{a.description}}</span></div>
    </div>
  </ng-container>
</ng-container>