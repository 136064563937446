import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DeleteTranslationGQL, RestoreTranslationGQL, TranslateDeltaGQL, TranslateReportGQL, Translation, TranslationDocument, TranslationGQL, UpdateTranslationGQL } from '../api/generated/graphql';

@Injectable({
  providedIn: 'root'
})
export class TranslatorService {

  constructor(
    private translatorGQL: TranslationGQL,
    private deleteTranslationGQL: DeleteTranslationGQL,
    private restoreTranslationGQL: RestoreTranslationGQL,
    private translateDeltaGQL: TranslateDeltaGQL,
    private translateReportGQL: TranslateReportGQL,
    private updateTranslationGQL: UpdateTranslationGQL
  ) { }

  public getTranslation(reportID: string): Observable<Translation> {
    const obs = this.translatorGQL.watch({reportID})
      .valueChanges
      .pipe(
        map( result => {
          return result.data.report.translation
        })
      )

    return obs;
  }

  public translateReport(reportID: string, sectionContent: string): Observable<Translation> {
    const obs = this.translateReportGQL.mutate({
      reportID: reportID,
      sectionContent: JSON.stringify(sectionContent)
    },
    { refetchQueries: [{ query: TranslationDocument, variables: { reportID: reportID } }] })
    .pipe(
      map(result => {
        return result.data.translateReport
      })
    )

    return obs;
  }

  public translateDelta(reportID: string, sectionID: string): Observable<string> {
    const obs = this.translateDeltaGQL.mutate({
      reportID: reportID,
      sectionID: sectionID
    }).pipe(
      map( result => {
        return result.data.translateDelta
      })
    )

    return obs;
  }

  public deleteTranslation(reportID: string, id: string): Observable<boolean> {
    const obs = this.deleteTranslationGQL.mutate({id}, {
      refetchQueries: [{ query: TranslationDocument, variables: { reportID: reportID } }]
    }).pipe(
      map(result => {
        if (result.data.deleteTranslation.id) {
          return true;
        } else {
          return false;
        }
      })
    )

    return obs;
  }

  public restoreTranslation(reportID: string, translationID: string): Observable<Translation> {
    const obs = this.restoreTranslationGQL.mutate({ id: translationID }, {
      refetchQueries: [{ query: TranslationDocument, variables: { reportID: reportID } }]
    }).pipe(
      map(result => {
        return result.data.restoreTranslation
      })
    )

    return obs;
  }

  public updateTranslation(id: string, reportID: string, title: string, keywords: string, abstract: string): Observable<Translation> {
    const obs = this.updateTranslationGQL.mutate(
      {
        id: id,
        reportID: reportID,
        title: title,
        keywords: keywords,
        abstract: abstract
      },
      {
      refetchQueries: [{ query: TranslationDocument, variables: { reportID: reportID } }]
    }).pipe(
      map(result => {
        return result.data.updateTranslation
      })
    )

    return obs;
  }

}
