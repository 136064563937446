<mat-icon class="btn-menu" appDefaultGrey [matMenuTriggerFor]="options" aria-hidden="false" aria-label="Delete diagnostic">delete</mat-icon>

<mat-menu #options="matMenu">
  <button class="btn-delete" mat-menu-item (click)="delete()">
    <mat-icon class="default-icon" aria-hidden="false" aria-label="Delete diagnostic">
      delete
    </mat-icon>
    <span>{{'ACTIONS.CONFIRM DELETE' | translate}}</span>
  </button>
  <button mat-menu-item>
    <mat-icon class="default-icon" aria-hidden="false" aria-label="Cancel">cancel</mat-icon>
    <span>{{'ACTIONS.CANCEL' | translate}}</span>
  </button>
</mat-menu>