import { Component, Inject, OnInit } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { Report } from 'src/app/api/generated/graphql';
import { PdfgenService } from 'src/app/services/pdf/pdfgen.service';

@Component({
  selector: 'app-pdf-bottom-sheet',
  templateUrl: './pdf-bottom-sheet.component.html',
  styleUrls: ['./pdf-bottom-sheet.component.css'],
  providers: [PdfgenService]
})
export class PdfBottomSheetComponent implements OnInit {

  showMessage: boolean = false;
  viewingURL: string = '';

  constructor(
    private pdfmake: PdfgenService,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: { report: Report },
    private _bottomSheet: MatBottomSheetRef,
  ) { }

  ngOnInit(): void {
  }

  generatePDF() {
    this.showMessage = true;

    setTimeout(() => {
      this.pdfmake.initGen(this.data.report.id);
      this.close();
    }, 2000);
  }

  close() {
    this._bottomSheet.dismiss();
  }

}