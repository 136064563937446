import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { UpdateTitleGQL } from 'src/app/api/generated/graphql';

@Component({
  selector: 'app-edit-title',
  templateUrl: './edit-title.component.html',
  styleUrls: ['./edit-title.component.css']
})
export class EditTitleComponent implements OnInit {
  @Input() reportId: string = "";
  @Input() currentTitle: string = "";
  @Output() closeTitleEditor = new EventEmitter();

  titleControl: FormControl;
  
  error: any;
  constructor(
    private updateCaseReportTitleGQL: UpdateTitleGQL,
  ) {
    this.titleControl = new FormControl();
   }

  ngOnInit(): void {
    this.titleControl = new FormControl({ value: this.currentTitle, disabled: false }, [Validators.required, Validators.maxLength(255)]);
  }

  save(title: string) {
    this.updateCaseReportTitleGQL
      .mutate({
        reportId: this.reportId,
        title: title.trim()
      })
      .subscribe({
        error: (err: any) => {
          this.error = err;
          console.error(err);
        },
        complete: () => this.emitClose()
      });

  }

  onSubmit() {
    this.titleControl.markAsTouched();
    const valid = this.titleControl.status;
    if (valid === "VALID") {
      const title = this.titleControl.value;
      this.save(title);
    }
  }

  get title() { return this.titleControl.get('title'); }

  emitClose() {
    this.closeTitleEditor.emit()
  }

}
