import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-default-value',
  templateUrl: './default-value.component.html',
  styleUrls: ['./default-value.component.css']
})
export class DefaultValueComponent implements OnInit {
  @Input()
  form!: FormGroup;
  @Input()
  labels!: string[];
  loading: boolean = true;

  constructor() { }

  ngOnInit(): void {
    if (this.form) {
      this.loading = false;
    }
  }

}
