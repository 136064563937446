import { Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { fromEvent, of, Subject } from 'rxjs';
import { first } from 'rxjs/operators';
import Quill from 'quill';
import { debounceTime, distinctUntilChanged, switchMap, catchError, takeUntil } from 'rxjs/operators';
import { Translation } from 'src/app/api/generated/graphql';
import { TranslatorService } from 'src/app/services/translator.service';
import { formatOptions } from '../quill-editor/utils';

@Component({
  selector: 'app-quill-translate',
  templateUrl: './quill-translate.component.html',
  styleUrls: ['./quill-translate.component.css']
})
export class QuillTranslateComponent implements OnInit {

  @Input() translation: Translation;
  @Input() readOnly: boolean = true;

  sectionName: string = 'translated-text';
  unsubscribe$: Subject<void> = new Subject<void>();
  show: boolean = false;

  @ViewChild('container', { read: ElementRef, static: true })
  container!: ElementRef;

  error: any;
  quill: Quill;

  constructor(
    private renderer: Renderer2,
    private translatorService: TranslatorService,
  ) { }

  ngOnInit(): void {
    if (this.translation) {
      this.createToolbar();
      this.createEditor();
      this.initEditor();
      this.monitorUserInput();
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  createEditor() {
    const div = this.renderer.createElement('div');
    this.renderer.setProperty(div, "id", 'translate-editor')
    this.renderer.appendChild(this.container.nativeElement, div);
  }

  createToolbar() {
    if (this.readOnly) return;
    
    let div = this.renderer.createElement('div');
    this.renderer.setProperty(div, "id", 'toolbar')

    div = this.btnFactory(div);

    this.renderer.appendChild(this.container.nativeElement, div);
  }

  initEditor() {
    var options = {
      placeholder: '',
      readOnly: this.readOnly,
      theme: 'snow',
      formats: formatOptions,
      modules: {
        toolbar: !this.readOnly ? {
          container: `#toolbar`,
        } : false,
      }
    };

    this.quill = new Quill('#translate-editor', options);
    this.quill.setContents(JSON.parse(this.translation.abstract));
  }

  monitorUserInput() {
    if (this.readOnly) return;

    fromEvent(this.quill, 'text-change').pipe(
      debounceTime(250),
      distinctUntilChanged(),
      switchMap(() => {
        return this.save();
      }),
      catchError((err: any) => {
        console.error(err);
        this.error = err;
        return of(null)
      }),
      takeUntil(this.unsubscribe$),
    ).subscribe()
  }

  async save() {
    const content = JSON.stringify(this.quill.getContents());

    const obs = this.translatorService.updateTranslation(this.translation.id, this.translation.reportID, this.translation.title, this.translation.keywords, content);

    obs.pipe(
      first()
    ).subscribe({
      error: (err: any) => {
        console.error(err);
        this.error = err;
      }
    })
  }

  btnFactory(div: HTMLElement): HTMLElement {

    const list = [
      {
        class: 'ql-bold',
        value: null,
      },
      {
        class: 'ql-italic',
        value: null,
      },
      {
        class: 'ql-underline',
        value: null,
      },
      {
        class: 'ql-list',
        value: 'ordered',
      },
      {
        class: 'ql-list',
        value: 'bullet',
      },
      {
        class: 'ql-script',
        value: 'sub',
      },
      {
        class: 'ql-script',
        value: 'super',
      },
      {
        class: 'ql-clean',
        value: null,
      }
    ]

    list.forEach((item) => {
      const btn = this.renderer.createElement('button');
      this.renderer.addClass(btn, item.class);
      if (item.value) {
        this.renderer.setAttribute(btn, 'value', item.value);
      }
      this.renderer.appendChild(div, btn);
    })
    return div;
  }

}
