import { Component, Input, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Author } from 'src/app/api/generated/graphql';
import { AuthorsService } from 'src/app/services/authors.service';

@Component({
  selector: 'app-group-members-read-only',
  templateUrl: './group-members-read-only.component.html',
  styleUrls: ['./group-members-read-only.component.css']
})
export class GroupMembersReadOnlyComponent implements OnInit {
  @Input() reportID: string = '';
  @Input() nonEditable: boolean = false;
  @Input() authors: Author[] = [];
  authors$: Observable<Author[]> | null = null;
  editMode: boolean = false;

  constructor(
    private authorService: AuthorsService,
  ) { }

  ngOnInit(): void {
    if (this.authors.length) {
      this.authors$ = of(this.authors);
    } else {
      this.authors$ = this.authorService.getAuthors(this.reportID);
    }
  }

}
