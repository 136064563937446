import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-case-header',
  templateUrl: './case-header.component.html',
  styleUrls: ['./case-header.component.css']
})
export class CaseHeaderComponent implements OnInit {
  
  @Input() report: any;
  @Input() isCaseView: boolean = false;
  
  constructor() { }

  ngOnInit(): void {
  }

}
