import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { TranslateService } from '@ngx-translate/core';
import { AutoSetLanguageService } from '../../services/auto-set-language.service';


@Component({
  selector: 'app-lang-preference',
  templateUrl: './lang-preference.component.html',
  styleUrls: ['./lang-preference.component.css']
})
export class LangPreferenceComponent implements OnInit {
  languages: string[] = [];
  returnTo: string = '';

  constructor(
    public auth: AuthService,
    private langService: AutoSetLanguageService,
    public translate: TranslateService,
    private route: ActivatedRoute,
    ) {}

  ngOnInit(): void {
    this.languages = this.parseLanguageNames(this.translate.getLangs());
    this.returnTo = this.route.snapshot.url.join('/');
  }

  switchLang(lang: string) {
    lang = this.reverseParse(lang);
    this.translate.use(lang);
    this.langService.setLanguagePreference(lang, this.returnTo)
  }

  parseLanguageNames(languages: string[]): string[] {
    return languages.map(l => {
      if (l === 'en') {
        return "English";
      }

      if (l === 'es') {
        return 'Español';
      }

      if (l === 'ru') {
        return 'Русский';
      }

      if (l === 'de') {
        return 'Deutsch';
      }

      return '';
    })
  }

  reverseParse(language: string): string {
    if (language === 'English') {
      return 'en';
    }

    if (language === 'Español') {
      return 'es';
    }

    if (language === 'Русский') {
      return 'ru';
    }

    if (language === 'Deutsch') {
      return 'de';
    }

    return 'en';
  }
}