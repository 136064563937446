<div class="column">
  <app-message-info content="{{'INFO.ADD AUTHOR' | translate}}"></app-message-info>
  <div class="row">
    <input type="email" #email placeholder="{{'FORMS.ENTER EMAIL' | translate}}" [formControl]="userEmailControl" />
    <button mat-button (click)="sendInvitation(email.value)"><mat-icon>send</mat-icon>&nbsp;{{'ACTIONS.SEND INVITE' | translate}}</button>
  </div>
  <div class="error-container" *ngIf="userEmailControl?.invalid && (userEmailControl?.dirty || userEmailControl?.touched)">
    <div class="input-error" *ngIf="userEmailControl?.errors.required">{{'FORMS.FIELD REQUIRED' | translate}}</div>
  </div>
  <ng-container *ngIf="error">
    <span class="input-error">{{'ERRORS.ERROR' | translate}}: {{error.message}}</span>
  </ng-container>
</div>
<mat-list dense *ngIf="sentInvites.length">
  <div mat-subheader>{{'MISC.SENT INVITES' | translate}}</div>
  <mat-list-item *ngFor="let s of sentInvites">{{s}}</mat-list-item>
</mat-list>
