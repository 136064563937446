import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { ReportHistory } from 'src/app/api/generated/graphql';
import { Location } from '@angular/common';
import { RevisionsService } from 'src/app/services/revisions.service';
import Delta from 'quill-delta';
import { ModalService } from 'src/app/services/modal.service';
import { SnackService } from 'src/app/services/snack.service';
import { Report } from 'src/app/classes/report';

var Diff = require('text-diff');

class Revision {
  id: string;
  title: string;
  createdAt: Date;
  keywords: string;
  reportID: string;
  sections: [];
  name: string;
  owner: {}

  constructor(revision: ReportHistory) {
    Object.assign(this, revision)
  }

}

@Component({
  selector: 'app-version-history',
  templateUrl: './version-history.component.html',
  styleUrls: ['./version-history.component.css']
})
export class VersionHistoryComponent implements OnInit {
  report: Report;
  revisions: Revision[];
  currentVersion: Revision;
  nullVersion: Revision;
  currentSelection: Revision[];
  selectedIndex: number = 0;
  reportId!: string;
  titleDiff: string;
  keywordDiff: string;

  constructor(
    private location: Location,
    private route: ActivatedRoute,
    private snacks: SnackService,
    private modalService: ModalService,
    private revisionsService: RevisionsService) {
    this.currentSelection = [];
  }

  ngOnInit(): void {
    if (this.route.parent) {
      this.route.parent.paramMap
        .subscribe(m => {
          this.reportId = m.get("id");
          if (this.reportId) {
            const obs = this.revisionsService.getRevisions(this.reportId).pipe(first());
            obs.subscribe({
              next: (report) => {
                this.report = new Report(report);

                this.revisions = report.revisions.map(rev => {
                  const createdAt = new Date(rev.createdAt);
                  let r = new Revision(rev);
                  r.createdAt = createdAt;
                  return r;
                })

                this.revisions.sort((r1: any, r2: any) => {
                  return r2.createdAt - r1.createdAt
                })

                // make a revision of the current report
                let rh = <ReportHistory>{
                  id: "",
                  reportID: this.report.id,
                  sections: this.report.createSectionHistories(this.report.sections),
                  name: "Current version",
                  title: this.report.title,
                  keywords: this.report.keywords,
                  createdAt: new Date(),
                  owner: {},
                }

                this.currentVersion = new Revision(rh);
                this.revisions.unshift(this.currentVersion);

                if (!this.revisions.length) {
                  return;
                }
                if (this.revisions.length === 1) {
                  this.currentSelection = [this.revisions[0], this.nullVersion]
                  return;
                };

                this.currentSelection = [this.revisions[0], this.revisions[1]]
              }
            })
          }
        })
    }
  }

  restoreRevision(revisionID: string): void {
    this.revisionsService.restoreRevision(revisionID, this.reportId).pipe(first()).subscribe({
      complete: () => {
        this.snacks.generateSnack('VERSIONS.RESTORED');
        this.location.back();
      }
    })
  }

  saveRevision(): void {
    this.modalService.saveRevisionDialog(this.report.id);
  }

  goBack() {
    this.location.back()
  }

  jsonToDelta(data: string): Delta {
    let delta = new Delta(JSON.parse(data))
    return delta;
  }

  generateDiffFromString(newData: string, oldData: string): string{
    var diff = new Diff();
    var textDiff = diff.main(oldData, newData);

    diff.cleanupSemantic(textDiff);
    return diff.prettyHtml(textDiff);
  }

  compareItems(selectedIndex: number): void {
    this.selectedIndex = selectedIndex;
    
    const num = this.revisions.length;
    // what to do if there is 0 or 1 versions saved. If 0, show current version and option to save it. If 1, display the diff against the current version.
    if (!num || num === 1) return;
    
    const currentItem = this.revisions[selectedIndex];
    let nextItem: Revision;
    if (selectedIndex === num - 1) {
      // make a revision of the current report
      let rh = <ReportHistory>{
        id: "",
        reportID: this.report.id,
        sections: this.report.createSectionHistories([]),
        name: "Null version",
        title: this.report.title,
        keywords: "",
        createdAt: "",
        owner: {},
      }
      nextItem = new Revision(rh);      
    } else {
      nextItem = this.revisions[selectedIndex + 1];
    }
    
    this.currentSelection = [currentItem, nextItem]
    return;
  }

}