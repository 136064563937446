<app-message-wide icon="timeline" iconColor="green" content="{{'CASE SECTION.TIMELINE' | translate}}" (addEntry)="openEntryModal('timeline')">
  <mat-icon *ngIf="hasEntries" (click)="panelV.toggle()">keyboard_arrow_down</mat-icon>
</app-message-wide>
<mat-expansion-panel #panelV class="placeholder" hideToggle class="mat-elevation-z0">
  <div class="section visits" *ngIf="(visits$ | async)?.length > 0">
    <div class="container">
      <div *ngFor="let note of visits$ | async">
        <app-timeline [note]="note"></app-timeline>
      </div>
    </div>
  </div>
</mat-expansion-panel>