export class Unit {
  id: string;
  name: string;

  constructor(id: string, name: string) {
    this.id = id;
    this.name = name;
  }

}

export const sortUnitsAlphabetically = (data: Unit[]): Unit[] => {
  let d = [...data];
  return d.sort((a, b) => {
    return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
  })
}