import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Author } from 'src/app/api/generated/graphql';
import { AuthorsService } from 'src/app/services/authors.service';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-corresponding-author',
  templateUrl: './corresponding-author.component.html',
  styleUrls: ['./corresponding-author.component.css']
})
export class CorrespondingAuthorComponent implements OnInit {

  @Input() author: Author | null = null;
  @Input() reportId: string = '';
  @Output() toggleSetCorr = new EventEmitter();
  allAuthors: Observable<Author[]> | null = null;

  checked: boolean = false;
  editMode: boolean = false;
  error: any;
  loading: boolean = false;

  constructor(
    private authorService: AuthorsService,
  ) { }

  ngOnInit() {
    if (this.reportId) {
      this.allAuthors = this.authorService.getAuthors(this.reportId);
    }
  }

  selectAuthor(authorID) {
    const obs = this.authorService.setCorrAuthor(authorID, this.reportId);

    obs.pipe(first()).subscribe({
      complete: () => this.editMode = false
    })
  }

}
