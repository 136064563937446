import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { first, pluck, takeUntil } from 'rxjs/operators';
import { Report } from 'src/app/api/generated/graphql';
import { ModalService } from 'src/app/services/modal.service';
import { ReportService } from 'src/app/services/report/report.service';

@Component({
  selector: 'app-case',
  templateUrl: './case.component.html',
  styleUrls: ['./case.component.css']
})
export class CaseComponent implements OnInit {
  user$: Observable<any> | null = null;
  activeParam$: Observable<string>;
  casereport$: BehaviorSubject<Report> | null = null;
  today = new Date();
  unsubscribe$: Subject<void> = new Subject<void>();
  reportID: string = '';

  constructor(
    private auth: AuthService,
    private activatedRoute: ActivatedRoute,
    private caseReportService: ReportService,
    private modalService: ModalService,
  ) {
    this.user$ = this.auth.user$;
    this.activeParam$ = this.activatedRoute.queryParams.pipe(pluck('section'));
    this.casereport$ = new BehaviorSubject(null);
  }

  ngOnInit(): void {
    this.activatedRoute.paramMap
      .pipe(first())
      .subscribe(m => {
        const id = m.get("id")
        if (id) {
          this.reportID = id;
          const obs = this.caseReportService.getCaseReportAsObservable();
          obs.pipe(takeUntil(this.unsubscribe$)).subscribe(this.casereport$);
        }
      })
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  openRevisionModal(): void {
    this.modalService.saveRevisionDialog(this.reportID);
  }

}
