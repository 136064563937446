import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { BehaviorSubject, from, Observable, of, Subject } from 'rxjs';
import { AuthService } from '@auth0/auth0-angular';
import { ActivatedRoute, Router } from '@angular/router';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { TranslateService } from '@ngx-translate/core';
import { concatMap, delay, first, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-layout-sidenav-container',
  templateUrl: './layout-sidenav-container.component.html',
  styleUrls: ['./layout-sidenav-container.component.css']
})
export class LayoutSidenavContainerComponent implements OnInit, OnDestroy {

  viewTitle$: BehaviorSubject<string>;
  showToolbar: boolean = true;
  @Input() backToLocation: string = '';
  isAuthenticated$: Observable<boolean>;
  isHandset$: BehaviorSubject<boolean>;
  timeToRedirect: number = 3;
  showMessage: boolean = false;
  unsubscribe$: Subject<void> = new Subject();
  
  constructor(
    private location: Location,
    private auth: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService,
    breakpointObserver: BreakpointObserver,
  ) {
    this.isAuthenticated$ = this.auth.isAuthenticated$;
    this.viewTitle$ = new BehaviorSubject('');
    this.isHandset$ = new BehaviorSubject(false);
    breakpointObserver.observe([
      Breakpoints.Handset
    ]).pipe(takeUntil(this.unsubscribe$)).subscribe(result => {
      if (result.matches) {
        this.isHandset$.next(true);
        return;
      }
      
      this.isHandset$.next(false);

    });
  }  

  ngOnInit() {
    this.isAuthenticated$.pipe(first()).subscribe({
      next: (isAuthed) => {
        if (!isAuthed) {
          this.activatedRoute.paramMap.pipe(first()).subscribe(m => {
            const id = m.get("id");
            let target: string;
            if (id) {
              target = `/share/${id}`;
            } else {
              target = this.router.url;
            }
            this.showMessage = true;
            from([3, 2, 1, 0]).pipe(
              concatMap(v => of(v).pipe(delay(1000))),
            ).subscribe({
              next: val => this.timeToRedirect = val,
              complete: () => this.auth.loginWithRedirect({ appState: { target: target } })
            })
          })
        }
      },
      error: (err) => {
        console.log(err);
      }
    })
    
    this.activatedRoute.data
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(d => {
        this.generateTranslation(d.viewName);
        if (d.showToolbar === false) {
          this.showToolbar = d.showToolbar;
        }
      })
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  goBack() {
    this.location.back()
  }

  generateTranslation(word: string): void {
    let obs: Observable<any>;

    switch (word) {
      case 'Dashboard':
        obs = this.translate.get('NAVIGATION.DASHBOARD');
        obs.pipe(first()).subscribe((res: string) => this.viewTitle$.next(res));
        break;
      case 'My Profile':
        obs = this.translate.get('NAVIGATION.MY PROFILE');
        obs.pipe(first()).subscribe((res: string) => this.viewTitle$.next(res));
        break;
      case 'Favorites':
        obs = this.translate.get('MISC.FAVORITES');
        obs.pipe(first()).subscribe((res: string) => this.viewTitle$.next(res));
        break;
      case 'New Case':
        obs = this.translate.get('NAVIGATION.NEW CASE');
        obs.pipe(first()).subscribe((res: string) => this.viewTitle$.next(res));
        break;
    
      default:
        break;
    }
  }

}
