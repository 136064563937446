import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { UpdateKeywordsGQL } from 'src/app/api/generated/graphql';

@Component({
  selector: 'app-edit-keywords',
  templateUrl: './edit-keywords.component.html',
  styleUrls: ['./edit-keywords.component.css']
})
export class EditKeywordsComponent implements OnInit {
  @Input() reportId: string = "";
  @Input() currentKeywords: string | undefined = "";
  @Output() closeKeywordsEditor = new EventEmitter();
  keywordsControl: FormControl;


  error: any;
  constructor(
    private updateCaseReportKeywordsGQL: UpdateKeywordsGQL,
  ) {
    this.keywordsControl = new FormControl();

   }

  ngOnInit(): void {
    this.keywordsControl = new FormControl({ value: this.currentKeywords, disabled: false }, [Validators.required]);
  }

  save(keywords: string) {
    this.updateCaseReportKeywordsGQL
      .mutate({
        reportId: this.reportId,
        keywords: keywords.trim()
      })
      .subscribe({
        error: (err: any) => {
          this.error = err;
          console.error(err);
        },
        complete: () => this.emitClose()
      });
  }

  onSubmit() {
    this.keywordsControl.markAsTouched();

    const valid = this.keywordsControl.status;

    if (valid === "VALID") {
      const keywords = this.keywordsControl.value;
      this.save(keywords);
    }
  }

  get keywords() { return this.keywordsControl.get('keywords'); }

  emitClose() {
    this.closeKeywordsEditor.emit()
  }

}
