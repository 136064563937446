import { Injectable } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { BehaviorSubject, Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { Affiliation, AffiliationsGQL, AllAuthorsDocument, AllAuthorsGQL, Author, CreateAuthorGQL, DeleteAuthorGQL, NewAuthor, Report, SetCorrespondingAuthorGQL, UpdateOrderGQL } from '../api/generated/graphql';

@Injectable({
  providedIn: 'root'
})
export class AuthorsService {

  user$: BehaviorSubject<any>;
  constructor(
    private allAuthorsGQL: AllAuthorsGQL,
    private affiliationsGQL: AffiliationsGQL,
    private createAuthorGQL: CreateAuthorGQL,
    private deleteAuthorGQL: DeleteAuthorGQL,
    private setCorrespondingAuthorGQL: SetCorrespondingAuthorGQL,
    private updateOrderGQL: UpdateOrderGQL,
    private auth: AuthService,
  ) {
    this.user$ = new BehaviorSubject(null);
    this.auth.user$.pipe(first()).subscribe({
      next: (u) => {
        this.user$.next(u);
      }
    })
  }

  getAuthors(reportID: string): Observable<Author[]> {
    return this.allAuthorsGQL.watch({
      id: reportID
    })
      .valueChanges
      .pipe(
        map((result: any) => {
          const authors = result.data.report.authors
          return authors
        })
      );
  }

  verifyOwnerAuthor(reportID: string): Observable<{}> {
    return this.allAuthorsGQL.fetch({
      id: reportID
    })
      .pipe(
        map((result: any) => {
          let match: boolean = false;
          const authors = result.data.report.authors
          const user = this.user$.getValue();

          authors.forEach(a => {
            if (a.careUserID === user.sub) {
              match = true;
              return;
            }
          });

          return {match: match, user: user};
        })
      );
  }

  getAffiliations(authorID: string): Observable<Affiliation[]> {
    return this.affiliationsGQL.watch({
      authorID: authorID
    })
      .valueChanges
      .pipe(
        map((result: any) => {
          const affiliations = result.data.affiliations
          return affiliations
        })
      );
  }

  createAuthor(author: NewAuthor, reportID: string): Observable<Author> {
    return this.createAuthorGQL
      .mutate({
        firstName: author.firstName,
        lastName: author.lastName,
        email: author.email,
        reportId: reportID,
        careUserID: author.careUserID,
      }, {
        refetchQueries: [{ query: AllAuthorsDocument, variables: { id: reportID }}]
      })
      .pipe(
        map((result: any) => {
          return result.data.createAuthor
        })
      );
  }

  deleteAuthor(authorID: string, reportID: string, order: number): Observable<Report> {
    return this.deleteAuthorGQL
      .mutate({
        id: authorID,
        reportId: reportID,
        order: order,
      }, {
        refetchQueries: [{ query: AllAuthorsDocument, variables: { id: reportID } }]
      })
      .pipe(
        map((result: any) => {
          return result.data.createAuthor
        })
      );
  }

  setCorrAuthor(authorID: string, reportID: string): Observable<Author> {
    return this.setCorrespondingAuthorGQL
      .mutate({
        authorId: authorID,
        reportId: reportID,
      })
      .pipe(
        map((result: any) => {
          return result.data.setCorrespondingAuthor.corrAuthor
        })
      );
  }

  updateAuthorOrder(authorID: string, reportID: string, newOrder: number, previousOrder: number): Observable<Report> {
    return this.updateOrderGQL
      .mutate({
        authorId: authorID,
        reportId: reportID,
        order: newOrder,
        previousOrder: previousOrder
      })
      .pipe(
        map((result: any) => {
          return result.data.updateOrder
        })
      );
  }

  hasCoAuthors(reportID: string): Observable<boolean> {
    const obs = this.allAuthorsGQL.fetch({
      id: reportID
    })
      .pipe(
        map((result: any) => {
          const authors = result.data.report.authors
          if (authors.length > 1) return true
          return false;
        })
      );
      return obs
  }
  
}
