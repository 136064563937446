import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { UpdateAttachmentGQL } from 'src/app/api/generated/graphql';
import { FormControl, Validators } from '@angular/forms';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil, debounceTime, tap } from 'rxjs/operators';

declare let gtag: Function;

@Component({
  selector: 'app-edit-attachment',
  templateUrl: './edit-attachment.component.html',
  styleUrls: ['./edit-attachment.component.css']
})
export class EditAttachmentComponent implements OnInit, OnDestroy {

  @Input() content: string = '';
  @Input() attachmentId: any;
  @Input() reportId: any;
  @Input() isMobile: boolean = false;

  descriptionControl: any;
  error: any;
  form: any;
  status$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private updateAttachmentGQL: UpdateAttachmentGQL,
  ) { }

  ngOnInit() {
    this.status$ = new BehaviorSubject("EMPTY");

    this.descriptionControl = new FormControl(this.content || "", { validators: [Validators.maxLength(500)] });
    this.onChanges();
  }

  ngOnDestroy() {
    // sends a terminate message to the takeUntil operators in onChanges()
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onBlur() {
    // terminate the subscription onBlur
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onFocus() {
    // reignite the subscription onFocus
    this.onChanges();
  }

  onChanges(): void {
    const observable = this.descriptionControl.valueChanges

    observable
      .pipe(
        takeUntil(this.unsubscribe$),
        tap((data: any) => {
          if (data.length <= 500) {
            this.status$.next("EDITING")
          } else {
            this.status$.next("EMPTY")
          }
        }),
        debounceTime(1000),
      )
      .subscribe({
        next: (data: string) => {
          if (data.length <= 500) this.save(data);
        },
        error: (err: any) => {
          this.error = err;
          console.log(err);
        },
      })

  }

  save(value: string) {
    this.status$.next("SAVING");
    this.updateAttachmentGQL
      .mutate({
        attachmentId: this.attachmentId,
        reportId: this.reportId,
        description: value.trim(),
      })
      .subscribe({
        error: err => {
          this.error = err;
          console.error(err);
        },
        complete: () => {
          this.status$.next("SAVED");
          setTimeout(() => { this.status$.next("EMPTY") }, 800);
          this.sendEvent();
        }
      });
  }

  getDescription() { return this.descriptionControl }

  sendEvent(): void {
    gtag('event', 'edit_attachment', {
      'event_category': 'db-update',
      'event_label': 'method'
    });
  }

}