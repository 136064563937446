import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.css']
})
export class ErrorComponent implements OnInit {
  @Input() error: any = "";

  constructor(private router: Router) { }

  ngOnInit(): void {
    if (this.error) {
      if (this.error === "Error: Login required") {
        this.router.navigate(['/login'])
      }
    }
  }

  reload(): void {
    window.location.reload();
  }

}
