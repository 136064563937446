<div class="container">
  <app-message-info *ngIf="!showMessage" content="{{'MESSAGES.PUBLIC REPORT' | translate}}"
    learnMoreLink="https://care-writer.com/terms-of-service" icon="announcement"></app-message-info>

  <div *ngIf="showMessage">
    <div style="text-align: center;" mat-dialog-title>{{ 'MESSAGES.PREP DOCUMENT' | translate }}</div>
    <p style="text-align: center;" class="mat-body">{{'MESSAGES.PLEASE WAIT' | translate}}</p>
  </div>
</div>
<div class="row">
  <button *ngIf="!showMessage" mat-stroked-button (click)="generatePDF()">{{'ACTIONS.AGREE CONTINUE' | translate}}</button>
  <button *ngIf="!showMessage" mat-button (click)="close()">{{'ACTIONS.CANCEL' | translate}}</button>
</div>