import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appBtnWhite]'
})
export class BtnWhiteDirective {

  constructor(private el: ElementRef) {
    this.el.nativeElement.style.color = '#eef2ff';
  }

}
