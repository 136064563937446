<app-error *ngIf="error"></app-error>
<ng-container *ngIf="loading">
  <div class="centered-spinner">
    <mat-spinner diameter="20"></mat-spinner>
  </div>
</ng-container>

<ng-container *ngIf="showLogin === true">
  <div class="message">
    <h3>Please login to view this content.</h3>
    <app-login-button [returnTo]="'/share/' + reportID"></app-login-button>
  </div>
</ng-container>

<ng-container *ngIf="isPublic === false">
  <div class="message">
    <h3>We're sorry, this link currently inactive.</h3>
    <button mat-stroked-button [routerLink]="'/dashboard'">
      {{'NAVIGATION.BACKTODASHBOARD' | translate}}
    </button>
  </div>
</ng-container>

<ng-container *ngIf="!loading && !showLogin && isPublic">
  <ng-container *ngIf="(casereport$ | async) as report">
    <div class="row">
      <div class="column view-width">
  
        <h1 class="mat-title title">{{report.title}}</h1>
        <app-group-members-read-only [reportID]="report.id" [authors]="report.authors"></app-group-members-read-only>
        <app-affiliations-expansion [reportID]="report.id" [authors]="report.authors"></app-affiliations-expansion>
        
        <div class="corr-author">
          <strong class="mat-small">{{'CASE SECTION.CORRESPONDING AUTHOR' | translate}}: </strong>
          <span *ngIf="report.corrAuthor.user.id; else userTmp" class="mat-small">{{report.corrAuthor.user.givenName}}
            {{report.corrAuthor.user.familyName}}, {{report.corrAuthor.email}}</span>
          <ng-template #userTmp>
            <span class="mat-small">{{report.corrAuthor.firstName}} {{report.corrAuthor.lastName}},
              {{report.corrAuthor.email}}</span>
          </ng-template>
        </div>
        
        <app-quill-share [reportID]="report.id" [sectionID]="report.sections[0].id" [isPreviewView]="true"></app-quill-share>
        <div class="keywords">
          <span class="mat-subheading-1">{{'CASE SECTION.KEYWORDS' | translate}}</span><br />
          <span *ngIf="report.keywords" class="mat-body">{{report.keywords}}</span>
        </div>
      
        <app-quill-share [reportID]="report.id" [sectionID]="report.sections[1].id" [isPreviewView]="true">
        </app-quill-share>

        <app-visits-preview [reportID]="report.id"></app-visits-preview>
      
        <app-quill-share [reportID]="report.id" [sectionID]="report.sections[2].id" [isPreviewView]="true">
        </app-quill-share>
      
        <app-view-diagnostics [reportID]="report.id"></app-view-diagnostics>
        
        <app-quill-share [reportID]="report.id" [sectionID]="report.sections[7].id" [isPreviewView]="true">
        </app-quill-share>

        <app-quill-share [reportID]="report.id" [sectionID]="report.sections[3].id" [isPreviewView]="true">
        </app-quill-share>
      
        <app-quill-share [reportID]="report.id" [sectionID]="report.sections[4].id" [isPreviewView]="true">
        </app-quill-share>
      
        <app-quill-share [reportID]="report.id" [sectionID]="report.sections[5].id" [isPreviewView]="true">
        </app-quill-share>
      
        <app-quill-share [reportID]="report.id" [sectionID]="report.sections[6].id" [isPreviewView]="true">
        </app-quill-share>
        
        <app-attachments-column-layout [reportID]="report.id"></app-attachments-column-layout>
      </div>
      <div>
        <span class="mat-small">{{'MESSAGES.UPDATED' | translate}}: {{lastUpdate | date:'yyyy-MM-dd, h:mm a'}}</span>
        <div>
          <ng-container>
            <button class="btn-action" mat-button (click)="toggleStar(report.id)">
              <mat-icon class="material-icons-outlined">{{!(isStarred$ | async) ? 'star_border' : 'star'}}</mat-icon>&nbsp;<span class="mat-body">{{(!(isStarred$ | async) ? 'ACTIONS.SAVE TO DASHBOARD' : 'ACTIONS.REMOVE FROM DASHBOARD') | translate}}</span>
            </button>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>