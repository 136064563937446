import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AllPublicVisitsGQL, AllVisitsGQL } from 'src/app/api/generated/graphql';
import { SortVisitsByDate, Visit } from '../visit';

@Component({
  selector: 'app-visits-preview',
  templateUrl: './visits-preview.component.html',
  styleUrls: ['./visits-preview.component.css']
})
export class VisitsPreviewComponent implements OnInit {

  @Input() reportID: string | null = null;
  visits$: Observable<Visit[]> | null = null;
  error: any;

  constructor(
    private allVisitsGQL: AllVisitsGQL,
    private allPublicVisitsGQL: AllPublicVisitsGQL,
    private router: Router,
  ) { }

  ngOnInit() {
    if (this.reportID) {
      const url = this.router.url;
      let segments: string[] = [];
      segments = url.split('/');
      segments = segments.filter(s => s != '');
      const isShareView = segments[0] === 'share' ? true : false;

      if (!isShareView) {
        this.fetchvisits(this.reportID);
      } else {
        this.fetchPublicVisits(this.reportID);
      }
    }
  }

  fetchvisits(id: string) {
    this.visits$ = this.allVisitsGQL.watch(
      { id: id }
    )
      .valueChanges
      .pipe(
        map((result: any) => {
          const visits = result.data.report.visits;
          return SortVisitsByDate(visits);
        })
      );
  }

  fetchPublicVisits(id: string) {
    this.visits$ = this.allPublicVisitsGQL.watch(
      { id: id }
    )
      .valueChanges
      .pipe(
        map((result: any) => {
          const visits = result.data.publicReport.visits;
          return SortVisitsByDate(visits);
        })
      );
  }

}
