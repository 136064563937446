<button (click)="toggleShow()" mat-button>
  <span class="mat-small">{{'CASE SECTION.AFFILIATIONS' | translate}}</span>
  <ng-container *ngIf="!show; else upArrow">
    <mat-icon inline="true">keyboard_arrow_down</mat-icon>
  </ng-container>
  <ng-template #upArrow>
    <mat-icon inline="true">keyboard_arrow_up</mat-icon>
  </ng-template>
</button>

<mat-expansion-panel id="affiliations-panel" [expanded]="show" class="mat-elevation-z0 aff-panel">
  <app-report-author-affiliations [reportID]="reportID" [authors]="authors"></app-report-author-affiliations>
</mat-expansion-panel>
