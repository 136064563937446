import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-message-wide',
  templateUrl: './message-wide.component.html',
  styleUrls: ['./message-wide.component.css']
})
export class MessageWideComponent implements OnInit {

  @Input() content: string = '';
  @Input() icon: string = 'lightbulb';
  @Input() iconColor: string = 'orange';
  @Output() addEntry: EventEmitter<any>;
  
  constructor() {
    this.addEntry = new EventEmitter<any>();
   }

  ngOnInit(): void {
  }

  addNewEntry(): void {
    this.addEntry.emit();
  }

}
