import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AllVisitsGQL } from 'src/app/api/generated/graphql';
import { ModalService } from 'src/app/services/modal.service';
import { Visit, SortVisitsByDate } from '../visit';

@Component({
  selector: 'app-timeline-placeholder',
  templateUrl: './timeline-placeholder.component.html',
  styleUrls: ['./timeline-placeholder.component.css']
})
export class TimelinePlaceholderComponent implements OnInit {

  @Input() reportID: string | null = null;
  visits$: Observable<Visit[]> | null = null;
  hasEntries: boolean = false;

  loading: boolean = true;
  error: any;

  constructor(
    private allVisitsGQL: AllVisitsGQL,
    private modalService: ModalService,
  ) { }

  ngOnInit() {
    if (this.reportID) {
      this.fetchvisits(this.reportID);
    }
  }

  fetchvisits(id: string) {
    this.visits$ = this.allVisitsGQL.watch(
      { id: id }
    )
      .valueChanges
      .pipe(
        map((result: any) => {
          const visits = result.data.report.visits;
          if (visits.length > 0) {
            this.hasEntries = true;
          }
          this.loading = false;
          return SortVisitsByDate(visits);
        })
      );
  }

  openEntryModal(name: string): void {
    if (!name) return;

    if (name === "timeline") {
      this.modalService.openNewTimeline(this.reportID);
      return;
    } else if (name === "diagnostic") {
      this.modalService.openNewDiagnostic(this.reportID);
      return;
    }
  }

}
