import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-attachments-wrapper',
  templateUrl: './attachments-wrapper.component.html',
  styleUrls: ['./attachments-wrapper.component.css']
})
export class AttachmentsWrapperComponent implements OnInit {

  @ViewChild('imageInput')
  imageInput: ElementRef;

  error: any;
  showAttachment: boolean = false;
  showPreview: boolean = false;
  fileToPreview: File | null = null;
  isAuthenticated$: Observable<boolean>;
  reportId: string | null = '';

  constructor(
    private translate: TranslateService,
    private auth: AuthService,
    private route: ActivatedRoute,
  ) {
    this.isAuthenticated$ = this.auth.isAuthenticated$;
  }

  ngOnInit() {
    this.route.paramMap
      .pipe(first())
      .subscribe(m => {
        this.reportId = m.get("id")
      })
  }

  togglePreview(file: any) {
    this.fileToPreview = file;

    if (this.fileToPreview !== null) {
      this.showPreview = true;
      this.showAttachment = true;
    } else {
      this.showPreview = false;
      this.showAttachment = false;
      this.imageInput.nativeElement.value = "";
    }
  }

  previewFile(files: FileList) {
    const file = files[0];
    // limit to 1Mb
    if (file.size > 1000000) {
      this.translate.get('MESSAGES.MAX FILE SIZE').pipe(first()).subscribe((res: string) => {
        alert(res);
      })
      this.imageInput.nativeElement.value = "";
      return null;
    }

    if (file.type == "image/png" || file.type == "image/jpeg") {
      this.togglePreview(file)
      return;
    } else {
      alert("Accepted file types are .png, .jpg");
      this.imageInput.nativeElement.value = "";
      return null;
    }

  }

  toggleNewAttachment() {
    this.showAttachment = !this.showAttachment;

    if (!this.showAttachment) {
      this.imageInput.nativeElement.value = "";
      this.showPreview = false;
      this.fileToPreview = null;
    }
  }

}
