import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { Report } from 'src/app/api/generated/graphql';
import { ReportService } from 'src/app/services/report/report.service';
import { emptyOps } from 'src/app/utils/consts';

@Component({
  selector: 'app-case-preview',
  templateUrl: './case-preview.component.html',
  styleUrls: ['./case-preview.component.css']
})
export class CasePreviewComponent implements OnInit {

  casereport$: Observable<Report> | null = null;
  contentEmpty: any;

  constructor(
    private caseReportService: ReportService,
    private route: ActivatedRoute,
  ) {
    this.contentEmpty = emptyOps;
  }

  ngOnInit(): void {
    if (this.route.parent) {
      this.route.parent.paramMap
        .pipe(first())
        .subscribe(m => {
          const id = m.get("id")
          if (id) {
            this.casereport$ = this.caseReportService.getReport(id);
          }
        })
    }
  }

}
