import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { PdfgenService } from 'src/app/services/pdf/pdfgen.service';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

interface DialogData {
  message: string,
  reportId: string,
}

@Component({
  selector: 'pdfgen-dialog',
  templateUrl: 'pdfgen-dialog.component.html',
  styleUrls: ['pdfgen-dialog.component.css'],
  providers: [PdfgenService]
})
export class PDFDialogComponent {
  showMessage: boolean = false;

  constructor(
    private pdfmake: PdfgenService,
    public dialogRef: MatDialogRef<PDFDialogComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}


  generatePDF() {
    this.showMessage = true;

    setTimeout(() => {
      this.pdfmake.initGen(this.data.reportId);
      this.closeDialog();
    }, 2000);
  }

  closeDialog(): void {
    this.dialog.closeAll()
  }

}