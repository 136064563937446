<ng-container *ngIf="(casereport$ | async) as report; else loading">
  <div class="column view-width">
    <app-full-preview [report]="report"></app-full-preview>
    <app-attachments-column-layout [reportID]="report.id"></app-attachments-column-layout>
  </div>
</ng-container>

<ng-template #loading>
  <div class="centered-spinner">
    <mat-spinner diameter="20"></mat-spinner>
  </div>
</ng-template>