import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';
import { AffiliationsService } from 'src/app/services/affiliations.service';

interface DialogData {
  affs: []
}

@Component({
  selector: 'app-update-my-affiliations',
  templateUrl: './update-my-affiliations.component.html',
  styleUrls: ['./update-my-affiliations.component.css']
})
export class UpdateMyAffiliationsComponent implements OnInit {

  error: any;
  formArray: FormArray;
  newGroup: FormGroup | null = null;
  editItem: string | null = null;

  constructor(
    private _snackBar: MatSnackBar,
    private translate: TranslateService,
    private affsService: AffiliationsService,
    public dialogRef: MatDialogRef<UpdateMyAffiliationsComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }


  ngOnInit(): void {
    this.formArray = new FormArray([]);

    if (this.data.affs && this.data.affs.length) {
      this.data.affs.map( aff => {
        const group = this.newFormGroup(aff);
        this.formArray.push(group)
      })
    }
  }

  onSuccess(message: string) {
    this.translate.get(['SNACKS.REPORT DELETED', 'SNACKS.DISMISS']).pipe(first()).subscribe((res: any) => {
      this._snackBar.open(message, res['SNACKS.DISMISS'], {
        duration: 3000,
      });
    });
  }
  
  newFormGroup(obj: any): FormGroup {
    let group: FormGroup;
    group = new FormGroup({
      name: new FormControl(obj['name'], [Validators.required]),
      position: new FormControl(obj['position'], [Validators.required]),
      id: new FormControl(obj['id']),
    }, { updateOn: 'change' })

    return group;
  }

  updateAffiliation(group) {
    if (group.status === "VALID") {
      const obs = this.affsService.updateMyAffiliation(group.value);
      obs.pipe(first()).subscribe({
        error: ((error: any) => {
          console.error(error);
        }),
        complete: () => {
          this.closeDialog();
          this.translate.get('SNACKS.AFFILIATION UPDATED').pipe(first()).subscribe((res: string) => {
            this.onSuccess(res);
          })
        }
      });
    }
  }

  saveNewAffiliation(group: FormGroup) {
    if (group.status === "VALID") {
      const obs = this.affsService.createUserAffiliation(group.value);

      obs.pipe(first()).subscribe({
        error: ((error: any) => {
          console.error(error);
        }),
        complete: () => {
          this.closeDialog();
          this.translate.get('SNACKS.AFFILIATION UPDATED').pipe(first()).subscribe((res: string) => {
            this.onSuccess(res);
          })

        }
      });
    }
  }

  deleteItem(group: FormGroup, itemIndex: number) {
    const id = group.get('id').value;
    const obs = this.affsService.deleteAffiliation(id);
    obs.pipe(first()).subscribe({
      complete: () => {

        this.translate.get('SNACKS.AFFILIATION DELETED').pipe(first()).subscribe((res: string) => {
          this.onSuccess(res);
        })
        this.formArray.removeAt(itemIndex);
      }
    })
  }

  cancelNewGroup() {
    this.newGroup = null;
  }

  addGroup() {
    const obj = {
      name: '',
      position: '',
    }

    const group = this.newFormGroup(obj);
    this.newGroup = group;
  }

  closeDialog(): void {
    this.dialog.closeAll()
  }

}
