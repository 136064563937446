import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Affiliation, Author, CreateAffiliationGQL, DeleteAffiliationGQL, MyAffiliationsDocument, MyAffiliationsGQL, ReportAuthorAffiliationsGQL, UpdateAffiliation, UpdateAffiliationGQL } from '../api/generated/graphql';

@Injectable({
  providedIn: 'root'
})
export class AffiliationsService {

  constructor(
    private myAffiliationsGQL: MyAffiliationsGQL,
    private reportAuthorAffsGQL: ReportAuthorAffiliationsGQL,
    private updateAffiliationGQL: UpdateAffiliationGQL,
    private createUserAffGQL: CreateAffiliationGQL,
    private deleteAffGQL: DeleteAffiliationGQL,
  ) {}

  public getMyAffiliations(): Observable<Affiliation[]> {
     return this.myAffiliationsGQL.watch()
      .valueChanges
      .pipe(map((result: any) => {
        return result.data.profile.myAffiliations
      }))
  }

  public reportAuthorAffiliations(reportID: string): Observable<Author[]> {
    return this.reportAuthorAffsGQL.fetch({
      reportID: reportID
    })
      .pipe(map((result: any) => {
        return result.data.report.authors
      }))
  }

  public updateMyAffiliation(affiliation: UpdateAffiliation): Observable<Affiliation> {
    return this.updateAffiliationGQL.mutate({
      id: affiliation.id,
      name: affiliation.name,
      position: affiliation.position,
    }, {
      refetchQueries: [{ query: MyAffiliationsDocument }]
    })
      .pipe(map((result: any) => {
        return result.data.updateAffiliation
      }))
  }

  public createUserAffiliation(aff: Affiliation): Observable<Affiliation> {
    return this.createUserAffGQL
      .mutate({
        name: aff.name,
        position: aff.position
      }, {
        refetchQueries: [{ query: MyAffiliationsDocument }]
      })
      .pipe(map((result: any) => {
        return result.data.createAffiliation
      }))
  
  }

  public deleteAffiliation(id: string): Observable<Affiliation> {
    return this.deleteAffGQL.mutate({ id }, {
      refetchQueries: [{ query: MyAffiliationsDocument }]
    })
      .pipe(map((result: any) => {
        return result.data.deleteAffiliation
      }))
  }
}
