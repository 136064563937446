import { Component, OnInit } from '@angular/core';
import { AirtableService } from '../../airtable.service';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-help-menu',
  templateUrl: './help-menu.component.html',
  styleUrls: ['./help-menu.component.css'],
  animations: [
    trigger('simpleFadeAnimation', [

      state('in', style({ opacity: 1 })),

      transition(':enter', [
        style({ opacity: 0 }),
        animate(500)
      ]),

      transition(':leave',
        animate(300, style({ opacity: 0, height: 0 })))
    ])
  ]
})
export class HelpMenuComponent implements OnInit {

  sections$: Observable<any> | null = null;
  selectedIndex: number = 0;

  constructor(
    private airtable: AirtableService
  ) {  }

  ngOnInit() {
    this.sections$ = this.airtable.getSections()
  }

}