import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { langPref, userMeta } from './utils/consts';
import { environment } from 'src/environments/environment';
import * as LogRocket from 'logrocket';

declare let gtag: Function;

if (environment.production) {
  LogRocket.init('8uvz0i/care-writer');
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {
  isAuthenticated$: Observable<boolean>;
  user$: Observable<import("@auth0/auth0-spa-js").User | null | undefined>;
  isSignUp: boolean = false;
  isLogin: boolean = false;

  loading: boolean = true;
  
  constructor(
    private router: Router,
    public auth: AuthService,
    public translate: TranslateService,
  ) {
    this.isAuthenticated$ = this.auth.isAuthenticated$;
    this.user$ = this.auth.user$;
    this.translate.addLangs(['en', 'es', 'ru', 'de']);
    this.translate.use('en');
    const navEndEvents = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    );
  
    navEndEvents.pipe(first()).subscribe((event: any) => {
      this.isAuthenticated$.pipe(first()).subscribe((isAuthed: boolean) => {
        if (isAuthed) {
          this.user$.pipe(first()).subscribe(u => {
            const lang = u[userMeta][langPref];
            this.translate.use(lang || 'en');
            if (environment.production) {
              LogRocket.identify(u?.sub);
              gtag('config', 'G-JCHJ1BQLDR', {
                'page_path': event.urlAfterRedirects,
                'user_id': u.id
              });
            }
          })
        }
        
        this.loading = false;
  
      })
    })
  }
}