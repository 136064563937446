<form *ngIf="visit" [formGroup]="cnForm" (ngSubmit)="onSubmit(cnForm)">

  <div class="input-container">
    <div class="date-input">
      <input id="date-input-edit-visit" autocomplete="off" formControlName="date" placeholder="{{'FORMS.CHOOSE DATE' | translate}}" [matDatepicker]="picker">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </div>
    <div class="input-error" *ngIf="date?.invalid && (date?.dirty || date?.touched)">
      <div *ngIf="date.errors.required">{{'FORMS.VALID DATE' | translate}}</div>
    </div>
  </div>
  
  <div class="input-container">
    <textarea required formControlName="summary" [value]="visit.summary" placeholder="{{'FORMS.ENTER SUMMARY' | translate}}"
      cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="2" cdkAutosizeMaxRows="5"></textarea>
    <ng-container *ngIf="summary?.invalid && (summary?.dirty || summary?.touched); else valid">
      <div class="mat-small" style="color: red;">{{summary?.value.length}}/255<span
          *ngIf="summary?.hasError('maxlength')" class="mat-small" style="color: red;">&nbsp;{{'FORMS.MAX LENGTH' | translate}}</span>
      </div>
    </ng-container>
    <ng-template #valid>
      <div *ngIf="summary.value" class="mat-small">{{summary.value.length}}/255</div>
    </ng-template>
  </div>

  <div class="btn-container">
    <div>
      <button mat-flat-button color="primary" type="submit">
        {{'ACTIONS.SAVE' | translate}}
      </button>
      <button mat-button type="button" (click)="closeEditor()">
        {{'ACTIONS.CANCEL' | translate}}
      </button>
    </div>
  </div>
  
</form>
