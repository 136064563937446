import { Injectable } from '@angular/core';
import { NativeDateAdapter } from '@angular/material/core';

@Injectable({
  providedIn: 'root'
})
export class CustomDateAdapterService extends NativeDateAdapter {

  override format(date: Date, displayFormat: String): any {
    if (displayFormat === 'input') {
      const day = date.getDate();
      const month = date.getMonth();
      const year = date.getFullYear();
      const hours = date.getHours();
      const minutes = date.getMinutes() - date.getTimezoneOffset();
      const seconds = date.getSeconds();
      // Return the format as per your requirement
      // return `${year}-${month}-${day}`;
      return new Date(year, month, day, hours, minutes, seconds);
    } else {
      return date.toDateString();
    }
  }

}

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: { month: 'short', year: 'numeric', day: 'numeric' }
  },
  display: {
    dateInput: 'input',
    monthYearLabel: { year: 'numeric', month: 'short' },
    dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
    monthYearA11yLabel: { year: 'numeric', month: 'long' },
  }
};