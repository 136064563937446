import { Component, OnInit, Input } from '@angular/core';
import { AirtableService } from '../../airtable.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-menu-example',
  templateUrl: './menu-example.component.html',
  styleUrls: ['./menu-example.component.css']
})
export class MenuExampleComponent implements OnInit {

  @Input() id: string;

  example$: Observable<any>;

  constructor(private airtable: AirtableService) { }

  ngOnInit() {
    if (this.id) {
      this.example$ = this.airtable.getExampleByID(this.id)
    }
  }

} 