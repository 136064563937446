<div mat-dialog-content>
  <p class="mat-body">
    {{"TRANSLATION.THIRD PARTY" | translate}}
  </p>
  <p class="mat-error" *ngIf="errorMessage">{{errorMessage}}</p>
</div>
<div mat-dialog-actions align="end">
  <button mat-stroked-button color="accent" (click)="requestTranslation()">{{"TRANSLATION.REQUEST" | translate}}</button>
  <button mat-button mat-dialog-close cdkFocusInitial>{{"ACTIONS.CANCEL" | translate}}</button>
</div>
<div id="translator" style="display: none"></div>