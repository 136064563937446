import { Component, Input, OnInit } from '@angular/core';
import { Report } from 'src/app/api/generated/graphql';

@Component({
  selector: 'app-content-preview',
  templateUrl: './content-preview.component.html',
  styleUrls: ['./content-preview.component.css']
})
export class ContentPreviewComponent implements OnInit {
  @Input() report?: Report;

  constructor() { }

  ngOnInit(): void {}
  
}
