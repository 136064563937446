import { Component, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';

declare let gtag: Function;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(
    public auth: AuthService,
  ) {
  }

  ngOnInit() {
  }

  signup() {
    this.auth.loginWithRedirect({
      screen_hint: "signup",
      appState: {
        target: `/profile?isSignup=true`
      },
    })
    gtag('event', 'sign_up');
  }

}