import { Injectable } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Report } from '../api/generated/graphql';
import { DeleteBottomSheetComponent } from '../sheets/delete-bottom-sheet/delete-bottom-sheet.component';
import { PdfBottomSheetComponent } from '../sheets/pdf-bottom-sheet/pdf-bottom-sheet.component';
import { ShareBottomSheetComponent } from '../sheets/share-bottom-sheet/share-bottom-sheet.component';

@Injectable({
  providedIn: 'root'
})
export class SheetService {

  constructor(private _bottomSheet: MatBottomSheet) { }

  openShareSheet(report: Report): void {
    this._bottomSheet.open(ShareBottomSheetComponent, { data: { report: report }, closeOnNavigation: true});
  }

  openPDFSheet(report: Report): void {
    this._bottomSheet.open(PdfBottomSheetComponent, { data: { report: report }, closeOnNavigation: true});
  }

  openDeleteSheet(report: Report): void {
    this._bottomSheet.open(DeleteBottomSheetComponent, { data: { report: report }, closeOnNavigation: true});
  }
}
