import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AllDiagnosticsGQL, AllPublicDiagnosticsGQL, GetTypesAndUnitsGQL } from '../api/generated/graphql';

@Injectable({
  providedIn: 'root'
})
export class DiagnosticsService {

  constructor(
    private allDiagnosticsGQL: AllDiagnosticsGQL,
    private allPublicDiagnosticsGQL: AllPublicDiagnosticsGQL,
    private getTypesAndUnitsGQL: GetTypesAndUnitsGQL,
  ) { }

  getDiagnostics(reportID: string): Observable<any[]> {
    return this.allDiagnosticsGQL.watch({
      id: reportID
    })
      .valueChanges
      .pipe(
        map(result => {
          return result.data.report.diagnostics;
        }),
        catchError((err: any) => {
          console.error(err);
          return of(null);
        }),
      );
  }

  getPublicDiagnostics(reportID: string): Observable<any[]> {
    return this.allPublicDiagnosticsGQL.watch({
      id: reportID
    })
      .valueChanges
      .pipe(
        map(result => {
          return result.data.publicReport.diagnostics;
        }),
        catchError((err: any) => {
          console.error(err);
          return of(null);
        }),
      );
  }

  getTypesAndUnits(): Observable<any> {
    return this.getTypesAndUnitsGQL.fetch().pipe(
      map(result => {
        const typesList = (<any>result).diagnosticTypes;
        const unitsList = (<any>result).diagnosticUnits;
        return {
          typesList: typesList,
          unitsList: unitsList,
        }
      }),
      catchError((err: any) => {
        console.error(err);
        return of(null);
      }),
    );
  }
}
