<mat-toolbar class="bottom-nav">
    <button mat-button routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" [routerLink]="['/reports', reportID]">
      <mat-icon>assignment</mat-icon>
    </button>

    <button mat-button routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" [routerLink]="['/reports', reportID, 'diagnostics']">
      <mat-icon>science</mat-icon>
    </button>
  
    <button mat-button routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" [routerLink]="['/reports', reportID, 'visits']">
      <mat-icon>attribution</mat-icon>
    </button>

    <button mat-button routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" [routerLink]="['/reports', reportID, 'preview']">
      <mat-icon class="material-icons-round">preview</mat-icon>
    </button>
</mat-toolbar>