<app-user-toolbar [isAuthenticated$]="isAuthenticated$" [isHandset$]="isXSmall$">
  <button class="snav-toggle" appBtnWhite mat-icon-button (click)="snav.toggle()">
    <mat-icon>menu</mat-icon>
  </button>
</app-user-toolbar>


<mat-sidenav-container [hasBackdrop]="false">
  <mat-sidenav #snav [mode]="!(closeMenu$ | async) ? 'side' : 'over'" [opened]="!(closeMenu$ | async)">
    <app-main-nav-list></app-main-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <app-error *ngIf="error" [error]="error"></app-error>
    <ng-container *ngIf="(isXSmall$ | async) && (casereport$ | async) as report">
      <app-case-bottom-nav [reportID]="report.id"></app-case-bottom-nav>
    </ng-container>
    <div class="app-width">
      <ng-container *ngIf="casereport$ | async as report">
        <ng-container *ngIf="!(isXSmall$ | async); else mobile">
          <app-case-navigation *ngIf="!isWideView" [class.full-width]="(activateMediumLayout$ | async)" [reportID]="report.id"></app-case-navigation>
        </ng-container>
        <ng-template #mobile>
          <mat-toolbar class="mobile-menu-toolbar">
            <button mat-icon-button (click)="helpMenu.open(); $event.stopPropagation();">
              <mat-icon class="material-icons-rounded">help_outline</mat-icon>
            </button>
            <div>
              <button mat-button aria-hidden="false" matTooltip="{{'ACTIONS.MORE' | translate}}" [matMenuTriggerFor]="moreMobile">
                <mat-icon class="material-icons-round">more_vert</mat-icon>
              </button>
              <mat-menu #moreMobile="matMenu" class="minHeight">
                <button mat-menu-item (click)="openSheet('share', report)">
                  <mat-icon>share</mat-icon>
                  <span>{{'ACTIONS.SHARE' | translate}}</span>
                </button>
                <button mat-menu-item (click)="openSheet('pdf', report)">
                  <mat-icon>download</mat-icon>
                  <span>{{'ACTIONS.DOWNLOAD PDF' | translate}}</span>
                </button>
                <button mat-menu-item aria-hidden="false" [routerLink]="['/reports', report.id, 'versions']">
                  <mat-icon class="material-icons-outlined">history</mat-icon>
                  <span>{{'NAVIGATION.VERSION HISTORY' | translate}}</span>
                </button>
                <button mat-menu-item aria-hidden="false" [routerLink]="['/reports', report.id, 'settings']">
                  <mat-icon class="material-icons-outlined">settings</mat-icon>
                  <span>{{'NAVIGATION.CASE SETTINGS' | translate}}</span>
                </button>
              </mat-menu>
            </div>
          </mat-toolbar>
        </ng-template>

        <div class="container" [class.container-mobile]="(isXSmall$ | async)">
          <router-outlet></router-outlet>
        </div>

        <div *ngIf="!(isXSmall$ | async) && !isWideView" class="flex-row fix-top-right">
          <div class="settings-container">
            <button appDefaultGrey mat-button aria-hidden="false" [matMenuTriggerFor]="shareMenu">
              {{'ACTIONS.SHARE' | translate}}
            </button>
            <mat-menu #shareMenu="matMenu" class="mWidth">
              <div>
                <app-share-toggle [report]="report"></app-share-toggle>
              </div>
            </mat-menu>
        
            <button appDefaultGrey mat-button aria-hidden="false" matTooltip="{{'ACTIONS.DOWNLOAD PDF' | translate}}"
              (click)="downloadPDF()">
              <mat-icon class="material-icons-round">download</mat-icon>
            </button>
            <button appDefaultGrey mat-button aria-hidden="false" matTooltip="{{'MISC.HELP' | translate}}"
              (click)="helpMenu.open(); $event.stopPropagation();">
              <mat-icon class="material-icons-outlined">help_outline</mat-icon>
            </button>
            <button appDefaultGrey mat-button aria-hidden="false" [matMenuTriggerFor]="revisionMenu">
              <mat-icon class="material-icons-outlined">history</mat-icon>
            </button>
            <mat-menu #revisionMenu="matMenu">
              <button mat-menu-item [routerLink]="['/reports', report.id, 'versions']">See version history</button>
              <button mat-menu-item (click)="openRevisionModal()">Save current version</button>
            </mat-menu>
            <button appDefaultGrey mat-button aria-hidden="false" [routerLink]="['/reports', report.id, 'settings']">
              <mat-icon class="material-icons-outlined">settings</mat-icon>
            </button>  
          </div>
        </div>      
      </ng-container>
    </div>
  </mat-sidenav-content>
  <mat-sidenav [autoFocus]="false" class="help-menu" #helpMenu position="end" mode="over" fixedTopGap="64"
    [fixedInViewport]="true">
    <button mat-icon-button appDefaultGrey (click)="helpMenu.close()">
      <mat-icon>close</mat-icon>
    </button>
    <mat-divider></mat-divider>
    <app-help-menu *ngIf="helpMenu.opened"></app-help-menu>
  </mat-sidenav>
</mat-sidenav-container>