import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User, UserProfileGQL } from 'src/app/api/generated/graphql';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(
    private profileGQL: UserProfileGQL,
  ) { }

  public getProfile(): Observable<User> {
    const obs = this.profileGQL.watch().valueChanges.pipe(map((result: any) => {
      return result.data.profile
    }))

    return obs;
  }
}