import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Invite, MyInvitesGQL } from '../api/generated/graphql';

@Injectable({
  providedIn: 'root'
})
export class InvitationsService {

  constructor(
    private myInvitesGQL: MyInvitesGQL
  ) { }

  getMyInvites(): Observable<Invite[]> {
    const obs = this.myInvitesGQL.watch().valueChanges.pipe(map((result: any) => {
      return result.data.myInvites
    }))

    return obs;
  }
}
