<div class="container">
  <div class="row">
    <button class="item" mat-button (click)="goBack()"><mat-icon class="back-icon">keyboard_backspace</mat-icon></button>
    <ng-container *ngIf="currentSelection.length">
      <div class="item">
        <span *ngIf="selectedIndex !== 0; else current">{{currentSelection[0].name}}</span>
        <ng-template #current>
          <div class="mat-body flex-row">
              <mat-icon color="accent" class="material-icons-outlined fiber-icon">fiber_manual_record</mat-icon>
              <span>Current version</span>
              <button mat-flat-button class="mat-body item" color="accent" (click)="saveRevision()">Name this version</button>
          </div>
        </ng-template>
      </div>
      <button *ngIf="selectedIndex !== 0" mat-flat-button class="item mat-body" color="accent" (click)="restoreRevision(currentSelection[0].id)">Restore this version</button>
    </ng-container>
  </div>
  <mat-drawer-container class="drawer-container" hasBackdrop="false">
    <mat-drawer #drawer mode="side" opened="true" position="end">
      <div class="page-title">
        <div>
          <span>{{'NAVIGATION.VERSION HISTORY' | translate}}&nbsp;<span class="beta mat-small">{{"MISC.BETA" | translate}}</span></span>
        </div>
      </div>
      <mat-selection-list role="list" [multiple]="false">
        <ng-container *ngIf="revisions?.length">
          <ng-container *ngFor="let rev of revisions; let i = index">
            <mat-list-option class="list-vertical" [class.current]="selectedIndex === i" (click)="compareItems(i)" role="listitem">
              <div mat-line class="mat-body">{{rev.name}}</div>
              <div mat-line class="mat-small">{{rev.createdAt | date:"MMMM d, h:mm a"}}</div>
              <div mat-line class="mat-small">{{rev.owner.name}}</div>
            </mat-list-option>
          </ng-container>
        </ng-container>
      </mat-selection-list>
    </mat-drawer>
    <mat-drawer-content>
      <ng-container *ngIf="revisions?.length > 1; else noRevisions">
        <h2 class="mat-title" [innerHtml]="generateDiffFromString(currentSelection[0].title, currentSelection[1].title)"></h2>
        <p class="mat-small" [innerHtml]="generateDiffFromString(currentSelection[0].keywords, currentSelection[1].keywords)"></p>
        <ng-container *ngFor="let s of currentSelection[0].sections; let i = index; let isLast = last">
          <ng-container *ngIf="i === 3">
            <h3 class="mat-title">{{currentSelection[0].sections[7].heading}}</h3>
            <app-quill-diff [deltaOne]="currentSelection[0].sections[7].content" [deltaTwo]="currentSelection[1].sections[7].content" [versionId]="currentSelection[0].sections[7].id">
            </app-quill-diff>
          </ng-container>
          <ng-container *ngIf="!isLast">
            <h3 class="mat-title">{{s.heading}}</h3>
            <app-quill-diff [deltaOne]="s.content" [deltaTwo]="currentSelection[1].sections[i].content" [versionId]="s.id"></app-quill-diff>
          </ng-container>
        </ng-container>
      </ng-container>      
    </mat-drawer-content>
  </mat-drawer-container>
  <ng-template #loading>
    ...loading
  </ng-template>
  <ng-template #noRevisions>
    <ng-container *ngIf="report">
      <h2 class="mat-title">{{report.title}}</h2>
      <p class="mat-small">{{report.keywords}}</p>
      <ng-container *ngFor="let s of report.sections">
        <app-quill-read-only [reportID]="report.id" [sectionID]="s.id" [isPreviewView]="true"></app-quill-read-only>
      </ng-container>
    </ng-container>
  </ng-template>
</div>