<div class="container">
  <button mat-button (click)="goBack()">
    <mat-icon>arrow_back</mat-icon>&nbsp;{{'NAVIGATION.BACK' | translate}}
  </button>

  <ng-container *ngIf="report">
    <h3 class="mat-title">{{"NAVIGATION.CASE SETTINGS" | translate}}</h3>
    <mat-divider></mat-divider>
    <div class="row">
      <app-translator [report]="report"></app-translator>
    </div>
    <mat-divider></mat-divider>
    <div class="row">
      <h4 class="mat-subtitle-2">{{"MESSAGES.DELETE REPORT" | translate}}</h4>
      <app-delete-case-report [reportID]="report.id" [title]="report.title"></app-delete-case-report>
    </div>
  </ng-container>
</div>