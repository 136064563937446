<ng-container *ngIf="(section$ | async) as section">
  <div class="content-section" (click)="confirmRecentlyEdited()" (clickOutside)="readOnly = true">
    <div class="content-header">
      <div clas="left">
        <span class="mat-title no-select">{{'CASE SECTION.'+section.heading | uppercase | translate}}</span>
        <span *ngIf="subtitle" class="mat-small">&nbsp;&nbsp;(any format accepted)</span>
      </div>
      <span class="mat-small">{{(lastUpdated$ | async) | trackUpdated}}</span>
    </div>

    <app-quill-editor [reportID]="reportID" [sectionID]="section.id" [readOnly]="readOnly"></app-quill-editor>

  </div>
</ng-container>