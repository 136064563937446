import { Component } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'update-language-dialog',
  templateUrl: 'update-language-dialog.component.html',
})
export class UpdateLanguageDialog {
  showMessage: boolean = false;

  constructor(
    private translate: TranslateService,
    public dialogRef: MatDialogRef<UpdateLanguageDialog>,
    public dialog: MatDialog,
  ) { }

  closeDialog(): void {
    this.dialog.closeAll()
  }

}