<ng-container #cdkorder>

  <mat-accordion *ngIf="attachments$" [cdkDropListData]="attachments$ | async" cdkDropList
    (cdkDropListDropped)="drop($event)">

    <mat-expansion-panel class="item border1" *ngFor="let a of attachments$ | async;trackBy: trackByFn" cdkDrag
      [cdkDragData]="a">
      <mat-expansion-panel-header>

        <mat-icon cdkDragHandle class="drag-indicator">drag_indicator</mat-icon>

        <div class="file-name">{{a.fileName}}</div>

      </mat-expansion-panel-header>

      <div class="flex-start" [class.flex-start-column]="isMobile">
        <div class="thumbnail-container">
          <img [src]="'data:image/png;base64,' + a.fileBlob">
        </div>

        <div class="description" [class.description-mobile]="isMobile">
          <app-edit-attachment [isMobile]="isMobile" [content]="a.description" [attachmentId]="a.id"
            [reportId]="reportID"></app-edit-attachment>
        </div>
      </div>

      <mat-action-row>
        <app-delete-attachment [reportId]="reportID" [attachmentId]="a.id" [order]="a.order"></app-delete-attachment>
      </mat-action-row>

    </mat-expansion-panel>
  </mat-accordion>

</ng-container>