import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { BehaviorSubject, from, Observable, of } from 'rxjs';
import { concatMap, delay, first } from 'rxjs/operators';
import { ModalService } from 'src/app/services/modal.service';
import { ReportService } from 'src/app/services/report/report.service';
import { completedProfile, userMeta } from 'src/app/utils/consts';

@Component({
  selector: 'app-invitation',
  templateUrl: './invitation.component.html',
  styleUrls: ['./invitation.component.css']
})
export class InvitationComponent implements OnInit {
  user$: Observable<any> | null = null;
  userEmail: string = '';
  isAuthenticated$: Observable<boolean>;
  invite$: BehaviorSubject<any> | null = null;
  loading: boolean = false;
  showMessage: boolean = false;
  timeToRedirect: number = 3;
  showWarning: boolean = false;
  error: any;

  constructor(
    private auth: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private reportService: ReportService,
    private modalService: ModalService,
  ) { 
    this.user$ = this.auth.user$;
    this.isAuthenticated$ = this.auth.isAuthenticated$;
    this.invite$ = new BehaviorSubject<any>(null);
  }

  ngOnInit(): void {
    this.isAuthenticated$.pipe(first()).subscribe({
      next: (isAuthed) => {
        if (isAuthed) {
          this.route.paramMap.pipe(first()).subscribe(m => {
            const inviteID = m.get("id");
            if (inviteID) {
              this.route.queryParams.pipe(first()).subscribe(params => {
                const isSignup = params["isSignup"]
                if (isSignup === 'true') {
                  this.modalService.openConfirmProfile(`/invitation/${inviteID}`);
                } else {
                  this.user$.pipe(first()).subscribe({
                    next: (data) => {
                      if (!data[userMeta] || !data[userMeta][completedProfile]) {
                        this.modalService.openConfirmProfile(`/invitation/${inviteID}`);
                      } else {
                        this.userEmail = data['email'];
                        this.reportService.getInvite(inviteID).pipe(first()).subscribe({
                          next: (data) => {
                            const isValid = this.validateEmail(this.userEmail, data.inviteeEmail);
                            if (isValid) {
                              this.invite$.next(data)
                            } else {
                              this.showWarning = true;
                            }
                          },
                          error: ((err: any) => {
                            let gqlError = err.graphQLErrors[0]?.message;
                            if (gqlError == "record not found") {
                              this.router.navigate(['/pagenotfound'])
                            } else {
                              this.error = err;
                              console.error(err);
                            }
                          })
                        });
                      }
                    }
                  })
                }
              })
            }
          })
        } else {
          //notify user will be redirected to login
          this.showMessage = true;
          this.route.paramMap.pipe(first()).subscribe(m => {
            const inviteID = m.get("id");
            if (inviteID) {
              from([3, 2, 1, 0]).pipe(
                concatMap(v => of(v).pipe(delay(1000))),
              ).subscribe({
                next: val => this.timeToRedirect = val,
                complete: () => this.auth.loginWithRedirect({ appState: { target: `/invitation/${inviteID}`}})
              })
            }
          })
        }
      }
    })
  }
 
  respondToInvite(reportID: string, email: string, response: string) {
    const obs = this.reportService.respondToInvite(reportID, email, response);

    obs.pipe(first()).subscribe({
      next: (data) => {
        this.loading = true;
        if (response === 'decline') {
          setTimeout(() => { this.loading = false; this.router.navigate(['/dashboard']) }, 1500);
        } else {
          setTimeout(() => { this.loading = false; this.router.navigate(['/reports', reportID]) }, 1500);
        }
      },
      error: (error: any) => {
        console.error(error);
      },
    })
  }

  validateEmail(userEmail, inviteEmail): boolean {
    return userEmail.toLowerCase() === inviteEmail.toLowerCase();
  }

}
