import { Injectable } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { TranslateService } from '@ngx-translate/core';
import { completedProfile, langPref, userMeta } from '../utils/consts';

@Injectable({
  providedIn: 'root'
})
export class ValidateUserService {

  constructor(
    public translate: TranslateService,
    private auth: AuthService
  ) { }

  validateProfile(user: any): boolean {
    if (user == null || user["sub"] == undefined) return false;

    const profileComplete = user[userMeta][completedProfile]

    if (!profileComplete) {
      return false
    } else {
      return true
    }
  }

  validateLangPreference(user: any): string {
    if (user == null || user["sub"] == undefined) return '';

    const languagePreference = user[userMeta][langPref]

    if (!languagePreference) {
      return 'en'
    } else {
      return languagePreference
    }
  }
}