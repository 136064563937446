import { Component, Input, OnInit } from '@angular/core';
import { Report } from 'src/app/api/generated/graphql';

@Component({
  selector: 'app-published-cases',
  templateUrl: './published-cases.component.html',
  styleUrls: ['./published-cases.component.css']
})
export class PublishedCasesComponent implements OnInit {
  @Input() reports: Report[] = [];
  
  constructor() { }

  ngOnInit(): void {
  }

}
